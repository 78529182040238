import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import {Portal} from 'evergreen-ui';

/**
 * Style the overlay wrapper to appear fixed over all other elements on the entire page
 */
const FullPageLoadingOverlay = styled(LoadingOverlay)`
    &.pss-wrapper--active {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
    }
`;

export default class PageLoadingOverlay extends Component {
    static propTypes = {
        text: PropTypes.string
    };

    static defaultProps = {
        text: 'Loading...'
    };

    render() {
        // Use the Portal to set the stacking context into a direct body descendant for proper full-page overlay
        return (
            <Portal>
                <FullPageLoadingOverlay
                    active={true}
                    spinner={true}
                    text={this.props.text}
                    classNamePrefix={'pss-'}
                />
            </Portal>
        )
    }
}
