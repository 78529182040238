import React from 'react';
import PropTypes from 'prop-types';
import {SideSheet} from 'evergreen-ui';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import ViewCart from 'src/client.webstore/Views/Cart/view';
import {withCart} from 'src/client.webstore/Views/Cart/cartRouter';
import {ScreenClassRender} from 'react-grid-system';
import {ConfigContext} from 'src/client.webstore/Context/ConfigProvider';
import _ from 'lodash';


class CartSideSheet extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        hashRouter: PropTypes.bool
    };

    static defaultProps = {
        hashRouter: false
    };

    state = {
        isOpen: true
    };

    render() {
        return (
            <ScreenClassRender
                render={sc => (
                    <ThemeContext.Consumer>
                        {({cs}) => (
                            <SideSheet
                                isShown={this.state.isOpen}
                                onCloseComplete={this.props.onClose}
                                preventBodyScrolling={true}
                                width={['xs', 'sm', 'md'].includes(sc) ? '85vw' : '45vw'}
                            >
                                <div className={cs('pss')} style={{paddingBottom: '150px'}}>
                                    <div className={cs('container-fluid', 'pss-cart-sidesheet')}>
                                        <ViewCart showTotals={false} showRecommended={false}/>
                                        <div className={cs('row')}>
                                            <div className={cs('col')}>
                                                <ConfigContext.Consumer>
                                                    {config => (
                                                        <a
                                                            href={`${_.isEmpty(config.cart.store_url) ? '/shop' : config.cart.store_url}${this.props.hashRouter ? '#' : ''}/cart`}
                                                            className={cs('btn', 'btn-lg', 'btn-primary', 'w-100')}
                                                            id={'pss-cart-sidesheet-checkout'}
                                                            /* Conditionally adding forceful clsoe for DealerSpike sites Ref: PSSD-1797, keeping existing functionality as it is */
                                                            {...(this.props.hashRouter && {onClick: this.props.onClose})}
                                                        >
                                                            Checkout Now
                                                        </a>
                                                    )}
                                                </ConfigContext.Consumer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SideSheet>
                        )}
                    </ThemeContext.Consumer>
                )}
            />
        );
    }
}

export default withCart(CartSideSheet);