import React from 'react';
import script from 'scriptjs';
import {
    injectStripe,
    StripeProvider,
    Elements,
    CardElement,
} from 'react-stripe-elements';
import {Helmet} from 'react-helmet';
import {Redirect} from 'react-router-dom';
import {cart} from 'src/client.webstore/utils/cart';
import {gateway} from 'src/client.webstore/utils/gateway';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import analytics from 'src/client.webstore/utils/analytics';
import _ from 'lodash';

const Analytics = analytics();

class StripeForm extends React.Component {
    state = {
        paymentProcessing: false,
        redirect: null
    };

    handleSubmit = e => {
        e.preventDefault();
        let {stripe, billing} = this.props;

        this.setState({paymentProcessing: true}, () => {
            stripe
                .createToken({
                    name: billing.name,
                    address_line1: billing.address_1,
                    address_line2: billing.address_2,
                    address_city: billing.city,
                    address_state: billing.state,
                    address_zip: billing.zip,
                    address_country: billing.country
                })
                .then(payload => cart.setPaymentToken(payload.token.id))
                .then(() => gateway.purchase('Stripe'))
                .then(res => cart.clear().then(() => this.setState({redirect: `/cart/summary/${res.data.orderId}`}, () => Analytics.convert(res.data.orderId))))
                .catch(err => this.setState({error: {message: err.message}, paymentProcessing: false}));
        });
    };

    handleChange = change => {
        this.setState({...change});
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <form onSubmit={this.handleSubmit}>
                        <div className={cs('row', 'my-5')}>
                            <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                                <div className={cs('row', 'mb-3')}>
                                    <div className={cs('col')}>
                                        <h3>Credit Card</h3>
                                        <p className={cs('lead')}>
                                            Your payment information is transmitted securely and never stored.
                                        </p>
                                    </div>
                                </div>
                                <div className={cs('row', 'mb-3')}>
                                    <div className={cs('col')}>
                                        <label htmlFor={'card-name'}>Name On Card</label>
                                        <input type='text' readOnly className={cs('form-control-plaintext')} id='card-name' value={this.props.billing.name}/>
                                    </div>
                                    <div className={cs('col')}>
                                        <label htmlFor={'card-zip'}>Postal Code</label>
                                        <input type='text' readOnly className={cs('form-control-plaintext')} id='card-zip' value={this.props.billing.zip}/>
                                    </div>
                                </div>
                                <div className={cs('row', 'mb-1')}>
                                    <div className={cs('col')}>
                                        <div className={cs('border', 'p-3', {'border-danger': _.has(this.state, 'error.message')})}>
                                            <CardElement
                                                style={{
                                                    base: {
                                                        fontSize: '18px',
                                                        color: '#424770',
                                                        letterSpacing: '0.025em',
                                                        fontFamily: 'Source Code Pro, monospace',
                                                        '::placeholder': {
                                                            color: '#aab7c4'
                                                        }
                                                    },
                                                    invalid: {
                                                        color: '#9e2146'
                                                    }
                                                }}
                                                onChange={this.handleChange}
                                                value={{
                                                    postalCode: this.props.billing.zip
                                                }}
                                                hidePostalCode={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {_.has(this.state, 'error.message') &&
                                <div className={cs('row')}>
                                    <div className={cs('col', 'text-danger')}>
                                        {this.state.error.message}
                                    </div>
                                </div>
                                }

                                <div className={cs('row', 'justify-content-end', 'my-3')}>
                                    <div className={cs('col-12', 'col-md-5', 'text-md-right')}>
                                        <button
                                            type={'submit'}
                                            className={cs('btn', 'btn-lg', 'btn-primary', 'text-uppercase', 'w-100')}
                                            disabled={!this.state.complete || this.state.paymentProcessing}
                                        >
                                            {!this.state.paymentProcessing && 'Complete Order'}
                                            {this.state.paymentProcessing && 'Please Wait'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </ThemeContext.Consumer>
        );
    }
}

StripeForm = injectStripe(StripeForm);

export default class Stripe extends React.Component {
    state = {
        stripe: null
    };

    componentDidMount() {
        let {stripe} = this.props.gatewayConfig;
        if (!window.Stripe) {
            script('https://js.stripe.com/v3/', () => this.setState({stripe: window.Stripe(stripe.stripe_public_token)}));
        } else {
            this.setState({stripe: window.Stripe(stripe.stripe_public_token)});
        }
    }

    render() {
        if (!this.state.stripe) {
            return <h1>Loading....</h1>;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <meta name='viewport' content='width=device-width'/>
                </Helmet>
                <StripeProvider stripe={this.state.stripe}>
                    <Elements>
                        <StripeForm billing={this.props.billing}/>
                    </Elements>
                </StripeProvider>
            </React.Fragment>
        );
    }
}
