import baseClient from 'src/client.webstore/api/base';
import {cart} from 'src/client.webstore/utils/cart';

/**
 * Namespace that represents the methods needed to facilitate a checkout.
 */
export const gateway = {
    /**
     * @param gatewayParams Any additional parameters this transaction might require
     * @return {Object}
     * @async
     */
    getPayload: async (gatewayParams = {}) => ({
        ...await cart.getPayload(),
        gatewayParams
    }),
    /**
     * @param provider
     * @param params
     * @return {Promise<*>}
     */
    purchase: async (provider, params = {}) => baseClient()
        .post('/webstore/webstore_cart/purchase', {provider}, await gateway.getPayload(params))
        .then(res => {
            if (!res.data.success) {
                throw new Error(res.data.message);
            }

            return res;
        }),
    /**
     * @param provider
     * @param params
     * @return {Promise<*>}
     */
    authorize: async (provider, params = {}) => baseClient()
        .post('/webstore/webstore_cart/authorize', {provider}, await gateway.getPayload(params))
        .then(res => {
            if (!res.data.success) {
                throw new Error(res.data.message);
            }

            return res;
        }),
    /**
     * @param provider
     * @param params
     * @return {Promise<*>}
     */
    completeAuthorizeCapture: async (provider, params = {}) => baseClient()
        .post('/webstore/webstore_cart/complete_authorize_capture', {provider}, await gateway.getPayload(params))
        .then(res => {
            if (!res.data.success) {
                throw new Error(res.data.message);
            }

            return res;
        }),
    /**
     * @param provider
     * @param params
     * @return {Promise<*>}
     */
    capture: async (provider, params = {}) => baseClient()
        .post('/webstore/webstore_cart/capture', {provider}, await gateway.getPayload(params))
        .then(res => {
            if (!res.data.success) {
                throw new Error(res.data.message);
            }

            return res;
        })
};
