import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import baseClient from 'src/client.webstore/api/base';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

class Leads {
    provider;

    constructor() {
        let ariSheets = _.chain(document.styleSheets).filter(v => /published-assets.ari-build.com/.test(v.href)).value();
        if (ariSheets.length > 0) {
            this.provider = 'ARI';
        } else {
            this.provider = 'PSS';
        }
    }


    submitPSS = formData => {
        // Format the product data to include with the form
        const productData = {
            product_id: _.get(formData, 'WebstoreProduct.id', '') || '',
            product_name: _.get(formData, 'ProductGroup.name', '') || '',
            product_manufacturer: _.get(formData, 'ProductGroup.ManufacturerGroup.name', '') || '',
            product_size: _.get(formData, 'Product.size', '') || '',
            product_color: _.get(formData, 'Product.color', '') || '',
            product_option: _.get(formData, 'Product.option', '') || '',
            product_quantity: _.get(formData, 'quantity', '') || ''
        };
        formData = _.pick(formData, ['name', 'email', 'phone', 'message']);

        return baseClient()
            .post('/webstore/webstore_pages/contact_us', {}, {
                source: 'Product Form',
                ...formData,
                ...productData
            });
    }

    submitARI = formData => {
        // Only include the basic form fields submitted by the user
        formData = _.pick(formData, ['name', 'email', 'phone', 'message']);
        formData = {
            leadType: 'contact',
            leadFormId: '2967',
            leadSystemFormId: '6',
            isPartial: 'false',
            autoReply: 'true',
            enableOptIn: 'false',
            replyEmails: '',
            DealerNotificationOverride: '',
            encryptionKeyId: '0',
            deliveryTarget: 'Ari',
            leadSource: '0',
            ...formData,
            referringUrl: window.location.href
        };


        return axios.post(
            '/LeadForm/1.0.0/Home/SubmitLead',
            qs.stringify(formData),
            {
                headers: {'content-type': 'application/x-www-form-urlencoded'}
            }
        );
    };

    submitLead = formData => new Promise((resolve, reject) => {
        Analytics.click({
            form_type: 'contact us',
            form_submission_email: formData.email,
            form_submission_phone_number: formData.phone,
            form_submission_first_name: formData.name,
            ecomm_part_detail_id: _.get(formData, 'WebstoreProduct.id', '') || '',
            ecomm_part_detail_group_id: _.get(formData, 'ProductGroup.id', '') || '',
            ecomm_part_detail_name: _.get(formData, 'ProductGroup.name', '') || '',
            ecomm_part_detail_brand: _.get(formData, 'ProductGroup.ManufacturerGroup.name', '') || '',
            ecomm_part_detail_dscrptn_char_count: (_.get(formData, 'ProductGroup.description', '') || '').length,
            ecomm_part_detail_quantity: formData.quantity,
            ecomm_part_detail_variant: _.trim(
                (_.get(formData, 'Product.size', '') || '') + ' ' +
                (_.get(formData, 'Product.color', '') || '') + ' ' +
                (_.get(formData, 'Product.option', '') || '')
            ),
            tealium_event: 'ecommerce_part_form_submit'
        });

        let promises = [
            this.submitPSS(formData)
        ];

        if (this.provider === 'ARI') {
            promises.push(this.submitARI(formData));
        }

        return Promise
            .all(promises)
            .finally(resolve)
    });
}

Leads = new Leads();

export default Leads;