import React from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';
import qs from 'query-string';
import dot from 'dot-object';
import ProductCard from 'src/client.webstore/Views/Elements/ProductCard';
import Filter from 'src/client.webstore/Views/Elements/Section';
import baseClient from 'src/client.webstore/api/base';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import GlobalFitment from 'src/client.webstore/Views/Elements/GlobalFitment';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import {Formik, Form} from 'formik';
import _ from 'lodash';
import Helmet from 'react-helmet';
import analytics from 'src/client.webstore/utils/analytics';
import FilterLink from 'src/client.webstore/Views/Categories/Elements/FilterLink';

const Analytics = analytics();

export default class Categories extends React.Component {
    static propTypes = {
        currency: PropTypes.string,
        activityTypes: PropTypes.array
    };

    static defaultProps = {
        currency: 'USD',
        activityTypes: []
    };

    state = {
        redirect: null,
        loading: false,
        error: false,
        children: [],
        colors: [],
        hits: 0,
        manufacturers: [],
        path: [],
        prices: {},
        products: [],
        sizes: [],
        attributes: [],
        webstoreCategory: {},
        showFilters: false,
        lockFilters: false,
        availableFilters: {},
        previousFilters: {},
        lastFilter: ''
    };

    componentDidMount() {
        this.search(0, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            // Replace the search filters when changing category, but DO NOT reset them when going back in history;
            // this.state.lockFilters is used to prevent the left-hand facets from being updated while the user
            // is selecting filters to apply to their current page view.
            let lockFilters = this.state.lockFilters;
            if (this.props.history.action !== 'POP' && this.props.location.pathname !== prevProps.location.pathname) {
                lockFilters = false;
            }

            this.setState({loading: true, products: [], lockFilters: lockFilters}, () => this.search(0, !lockFilters));
            document.getElementById('pss-breadcrumbs').scrollIntoView();
        }
    }

    search = (start = 0, initial = false) => {
        this.setState({loading: true}, () => {
            // Determine whether to search again on initial load with filters set so that we can retrieve all filter facets first
            const filterValues = this.getFilterValues();
            const reload = (initial && !_.isEmpty(filterValues));

            baseClient()
                .get(
                    this.props.location.pathname,
                    {
                        ...(reload ? {} : filterValues),
                        length: 48,
                        start: start
                    }
                )
                .then(res => {
                    const filterKeys = ['on_sale', 'manufacturers', 'colors', 'sizes', 'attributes'];
                    let availableFilters = _.pickBy(res.data, (v, k) => {
                        return _.indexOf(filterKeys, k) >= 0;
                    });

                    // Do not allow these filter facets to be updated if they are locked; refuse to include them in state
                    if (this.state.lockFilters) {
                        res.data = _.pickBy(res.data, (v, k) => {
                            return _.indexOf(filterKeys, k) < 0;
                        });
                    }

                    // Set the last filter used, particularly useful when loading the page with filters already set
                    let lastFilter = this.state.lastFilter;
                    // When no last filter exists, but we have filters, assume the first filter was the last selected
                    if (lastFilter === '') {
                        // The 'attributes' filter is an array that should be set as 'attributes.<name>'
                        lastFilter = _.head(_.keys(filterValues)) || '';
                        lastFilter = (lastFilter === 'attributes' ? 'attributes.' + (_.head(_.keys(filterValues['attributes'])) || '') : lastFilter);
                    }

                    this.setState({
                        ...res.data,
                        loading: reload,
                        lockFilters: true,
                        availableFilters: availableFilters,
                        previousFilters: _.isEmpty(this.state.previousFilters) ? availableFilters : this.state.previousFilters,
                        lastFilter: lastFilter,
                        // List products only if we're not going to reload them
                        products: (reload ? [] : (start > 0 ? this.state.products.concat(res.data.products) : res.data.products))
                    }, () => {
                        // Perform the search a second time to use the filter values we skipped the first time
                        if (reload) {
                            this.search(start, false);
                        } else {
                            // Only push analytics if we're not immediately reloading
                            Analytics.view({
                                ecomm_part_list_ids: this.state.products.map(v => `${v.WebstoreProduct.id}`),
                                ecomm_part_list_names: this.state.products.map(v => v.ProductGroup.name),
                                ecomm_part_list_original_prices: this.state.products.map(v => `${v.WebstoreProduct.min_price}`),
                                ecomm_part_list_currency_code: this.state.products.map(v => _.get(this.props, 'currency')),
                                ecomm_part_list_brands: this.state.manufacturers.map(v => v.name),
                                // ecomm_part_list_quantities: ['12', '0', '5'],
                                ecomm_part_list_categories: this.state.path.map(v => v.name),
                                // ecomm_part_list_models: ['217288A1', '122', '68-733'],
                                ecomm_part_list_group_ids: this.state.products.map(v => v.ProductGroup.id),
                                site_section: 'Ecommerce Part List Page',
                                tealium_event: 'ecommerce_part_list_view'
                            });
                        }
                    })
                })
                .catch(err => {
                    this.setState({loading: false, error: true});
                    throw err;
                });
        });
    };

    getFilterLink = (keyPath, keyValue = null) => {
        let query = this.getFilterValues();

        if (typeof keyPath === 'string') {
            if (keyValue) {
                query = _.set(query, keyPath, keyValue);
            } else {
                // Determine whether the given key represents an array
                let arrayPath = _.replace(keyPath, /\[[0-9]+\]$/, '');
                const isArray = arrayPath !== keyPath;

                if (isArray) {
                    // Remove the element from the array and overwrite the key
                    _.set(query, arrayPath, _.without(_.get(query, arrayPath, []) || [], _.get(query, keyPath, []) || []));
                    let values = _.get(query, arrayPath, []) || [];

                    if (_.isEmpty(values)) {
                        _.unset(query, arrayPath);
                    }
                } else {
                    _.unset(query, keyPath);
                }
            }
        } else {
            _.forEach(keyPath, (v, k) => {
                query = _.set(query, k, v);
            });
        }

        if (query.attributes && _.isEmpty(query.attributes)) {
            _.unset(query, 'attributes');
        }

        return {
            ...this.props.location,
            search: _.isEmpty(query) ? '' : `q=${encodeURIComponent(JSON.stringify(query))}`
        };
    };

    getFilterValues = () => {
        const query = qs.parse(this.props.location.search);
        const q = _.get(query, 'q', '') || '';

        return q === '' ? {} : JSON.parse(q);
    };

    render() {
        const isRoot = this.props.location.pathname === '/categories' || this.props.location.pathname === '/categories/Parts';

        // Do not attempt to render the filter sidebar until loading is complete -- it can be very slow to render otherwise
        const showLeft = !this.state.loading && (
            this.state.children.length > 0
            || this.state.manufacturers.length > 0
            || this.state.colors.length > 0
            || this.state.sizes.length > 0
            || this.state.attributes.length > 0
            || this.state.on_sale > 0
        );

        if (
            !this.state.loading
            && this.props.location.pathname === '/categories'
            && this.state.children.length === 1
            && (
                this.state.children[0].name === 'Parts'
                || this.state.children[0].name === 'Home Medical Equipment'
            )
        ) {
            return <Redirect to={this.state.children[0].url}/>
        }

        const filterValues = this.getFilterValues();
        const {availableFilters, previousFilters} = this.state;

        return (
            <ThemeContext.Consumer>
                {({cs, styles}) => (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                Shop
                                {' '}
                                {this.state.webstoreCategory.name || 'All Parts & Accessories'}
                            </title>
                        </Helmet>
                        <div className={cs('row')}>
                            {/*Breadcrumbs*/}
                            <div className={cs('col-12')} id={'pss-breadcrumbs'}>
                                <nav aria-label={'breadcrumb'}>
                                    <ol className={cs('breadcrumb')}>
                                        <li className={cs('breadcrumb-item')}>
                                            <a href='/'>Home</a>
                                        </li>
                                        <li className={cs('breadcrumb-item', {active: this.state.path.length === 0})}>
                                            <Link
                                                to='/categories'
                                                onClick={() => this.setState({loading: true})}
                                            >
                                                All Parts & Accessories
                                            </Link>
                                        </li>
                                        {this.state.path && this.state.path.map((v, k) => {
                                            return (
                                                <li key={k} className={cs('breadcrumb-item', {active: this.state.path.length - 1 === k})}>
                                                    {this.state.path.length - 1 > k &&
                                                    <Link
                                                        to={{...this.props.location, pathname: v.url}}
                                                        onClick={() => this.setState({loading: true})}
                                                    >
                                                        {v.name}
                                                    </Link>
                                                    }

                                                    {this.state.path.length - 1 === k && v.name}
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </nav>

                                <Filter filterTitle={'Search Products'}>
                                    <Formik
                                        onSubmit={values => this.setState({loading: true}, () => this.props.history.push(this.getFilterLink(values)))}
                                        initialValues={{
                                            keyword: ''
                                        }}
                                    >
                                        {({values, handleChange}) => {
                                            return (
                                                <Form>
                                                    <div className={cs('row')}>
                                                        <div className={cs('col-12', 'col-sm-9', 'mb-2', 'mb-lg-0')}>
                                                            <input
                                                                type={'text'}
                                                                name={'keyword'}
                                                                onChange={handleChange}
                                                                placeholder={'Keyword or Part Number'}
                                                                id={'part_number_filter'}
                                                                title={'Search By Keyword or Part Number'}
                                                                className={cs('form-control', 'form-control-lg')}
                                                            />
                                                        </div>
                                                        <div className={cs('col-12', 'col-sm-3')}>
                                                            <button
                                                                type={'submit'}
                                                                className={cs('btn', 'btn-primary', 'w-100', 'btn-lg')}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Filter>
                            </div>

                            {/*Left Column*/}
                            {showLeft &&
                            <React.Fragment>
                                <div className={cs('col', 'col-md-4', 'col-lg-3')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col-12')}>
                                            <Filter
                                                highlight={true}
                                                filterTitle={'Applied Filters'}
                                                chunkBoundary={100}
                                                filterLinks={_.chain(dot.dot(filterValues)).omitBy(v => v === '').map((v, k) => {
                                                    // Format the filter name into words
                                                    let filterName = _.split(k, '.');
                                                    filterName = _.join(
                                                        _.map(_.split(filterName[filterName.length - 1], '_'), v => _.capitalize(v)),
                                                        ' '
                                                    );

                                                    // Don't include the brackets when an array
                                                    filterName = _.replace(filterName, /\[[0-9]+\]$/, '');

                                                    // On Sale is a boolean field, so just show the filter name, not the value
                                                    const showValue = (filterName !== 'On Sale');

                                                    // Negate the boolean field's name based on value
                                                    if (!showValue && (!v || v === '0')) {
                                                        filterName = 'Not ' + filterName;
                                                    }

                                                    return (
                                                        <Link
                                                            to={this.getFilterLink(k)}
                                                            className={cs('stretched-link', 'd-flex', 'justify-content-between')}
                                                            onClick={() => this.setState({loading: true})}
                                                        >
                                                            {showValue &&
                                                            <div>
                                                                {v}<br/>
                                                                <small className={cs('text-muted')}>{filterName}</small>
                                                            </div>
                                                            }

                                                            {!showValue &&
                                                            <div>
                                                                {filterName}
                                                            </div>
                                                            }
                                                            <Icon icon={'times'} padding={3}/>
                                                        </Link>
                                                    );
                                                }).value()}
                                                footer={
                                                    <div className={cs('text-center')}>
                                                        <button
                                                            className={cs('btn', 'btn-secondary', 'w-100')}
                                                            onClick={() => this.setState({loading: true},
                                                                () => this.props.history.push({
                                                                    ...this.props.location,
                                                                    search: ''
                                                                })
                                                            )}
                                                        >
                                                            Clear All
                                                        </button>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className={cs('row')}>
                                        <div className={cs({'d-none': !this.state.showFilters}, 'd-md-block', 'col-12')}>
                                            {this.state.on_sale > 0 &&
                                            <Filter
                                                filterTitle={'Shop By Sale'}
                                                filterLinks={['on_sale'].map(v => {
                                                    const value = _.get(filterValues, v, 0) || 0;
                                                    const available = (_.get(availableFilters, 'on_sale', 0) || 0) > 0;
                                                    const lastFilteredThis = (!this.state.lastFilter || this.state.lastFilter === 'on_sale');
                                                    const disabled = value === 0 && !available && !lastFilteredThis;
                                                    let nextFilters = _.clone(availableFilters);
                                                    nextFilters.on_sale = _.get(previousFilters, 'on_sale', 0) || 0;

                                                    return (
                                                        <FilterLink
                                                            linkTo={disabled ? {} : this.getFilterLink(v, (value === 1 ? 0 : 1))}
                                                            onClick={() => this.setState({loading: true, previousFilters: nextFilters, lastFilter: 'on_sale'})}
                                                            disabled={disabled}
                                                            checked={value === 1}
                                                            label={'On Sale'}
                                                            name={v}
                                                        />
                                                    );
                                                })}
                                            />
                                            }

                                            <GlobalFitment
                                                onSubmit={values => this.setState({loading: true}, () => this.props.history.push(this.getFilterLink({...values})))}
                                                onReset={() => this.setState({loading: true}, () => this.props.history.push(this.getFilterLink({year: '', make: '', model: ''})))}
                                                webstoreCategoryId={this.state.webstoreCategory.id ? Number(this.state.webstoreCategory.id) : null}
                                            />

                                            <Filter
                                                filterTitle={'Shop By Subcategory'}
                                                filterLinks={this.state.children.map(v => (
                                                    <Link
                                                        to={{
                                                            ...this.props.location,
                                                            pathname: v.url
                                                        }}
                                                        onClick={() => this.setState({loading: true})}
                                                        className={cs('stretched-link')}
                                                    >
                                                        {v.name}
                                                    </Link>
                                                ))}
                                                chunkBoundary={100}
                                            />

                                            {(!isRoot || !_.isEmpty(filterValues)) &&
                                            <React.Fragment>
                                                <Filter
                                                    filterTitle={'Shop By Price'}
                                                >
                                                    <Formik
                                                        initialValues={{
                                                            min_price: filterValues.min_price || '',
                                                            max_price: filterValues.max_price || ''
                                                        }}
                                                        enableReinitialize={true}
                                                        onSubmit={values => this.setState({loading: true}, () => this.props.history.push(this.getFilterLink(values)))}
                                                        onReset={() => this.setState({loading: true}, () => this.props.history.push(this.getFilterLink({min_price: '', max_price: ''})))}
                                                    >
                                                        {({values, handleChange}) => {
                                                            return (
                                                                <Form>
                                                                    <div className={cs('row', 'form-group')}>
                                                                        <div className={cs('col')}>
                                                                            <input
                                                                                type={'number'}
                                                                                name={'min_price'}
                                                                                onChange={handleChange}
                                                                                className={cs('form-control')}
                                                                                placeholder={'Min. Price'}
                                                                                id={'min_price'}
                                                                                title={'Minimum Price Filter'}
                                                                                value={values.min_price}
                                                                            />
                                                                        </div>
                                                                        <div className={cs('col')}>
                                                                            <input
                                                                                type={'number'}
                                                                                name={'max_price'}
                                                                                onChange={handleChange}
                                                                                className={cs('form-control')}
                                                                                placeholder={'Max. Price'}
                                                                                id={'max-price'}
                                                                                title={'Maximum Price Filter'}
                                                                                value={values.max_price}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={cs('row')}>
                                                                        <div className={cs('col-6')}>
                                                                            <button className={cs('btn', 'btn-secondary', 'btn-lg', 'w-100')} type={'reset'}>
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                        <div className={cs('col-6')}>
                                                                            <button className={cs('btn', 'btn-primary', 'btn-lg', 'w-100')} type={'submit'}>
                                                                                Submit
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            )
                                                        }}
                                                    </Formik>
                                                </Filter>

                                                {this.state.manufacturers &&
                                                <Filter
                                                    filterTitle={'Shop By Brand'}
                                                    filterLinks={_.sortBy(this.state.manufacturers, 'name')
                                                        .map(v => {
                                                            let manufacturers = _.get(filterValues, 'manufacturer', []) || [];
                                                            const included = _.includes(manufacturers, v.name);
                                                            const available = undefined !== _.find(_.get(availableFilters, 'manufacturers', []) || [], {name: v.name});
                                                            const lastFilteredThis = (!this.state.lastFilter || this.state.lastFilter === 'manufacturer');
                                                            const disabled = !included && !available && !lastFilteredThis;

                                                            // Include/exclude the current value based on whether it's already filtered
                                                            manufacturers = included ? _.without(manufacturers, v.name) : [...manufacturers, v.name];

                                                            let nextFilters = _.clone(availableFilters);
                                                            nextFilters.manufacturers = _.get(previousFilters, 'manufacturers', {}) || {};

                                                            return (
                                                                <FilterLink
                                                                    linkTo={disabled ? {} : this.getFilterLink(_.isEmpty(manufacturers) ? 'manufacturer' : {manufacturer: manufacturers})}
                                                                    onClick={() => this.setState({loading: true, previousFilters: nextFilters, lastFilter: 'manufacturer'})}
                                                                    disabled={disabled}
                                                                    checked={included}
                                                                    label={v.name}
                                                                    name={`manufacturer.${v.name}`}
                                                                />
                                                            )
                                                        }
                                                    )}
                                                />
                                                }

                                                {this.state.colors &&
                                                <Filter
                                                    filterTitle={'Shop By Color'}
                                                    filterLinks={_.sortBy(this.state.colors, 'name')
                                                        .map(v => {
                                                            let colors = _.get(filterValues, 'color', []) || [];
                                                            const included = _.includes(colors, v.name);
                                                            const available = undefined !== _.find(_.get(availableFilters, 'colors', []) || [], {name: v.name});
                                                            const lastFilteredThis = (!this.state.lastFilter || this.state.lastFilter === 'color');
                                                            const disabled = !included && !available && !lastFilteredThis;

                                                            // Include/exclude the current value based on whether it's already filtered
                                                            colors = included ? _.without(colors, v.name) : [...colors, v.name];

                                                            let nextFilters = _.clone(availableFilters);
                                                            nextFilters.colors = _.get(previousFilters, 'colors', {}) || {};

                                                            return (
                                                                <FilterLink
                                                                    linkTo={disabled ? {} : this.getFilterLink(_.isEmpty(colors) ? 'color' : {color: colors})}
                                                                    onClick={() => this.setState({loading: true, previousFilters: nextFilters, lastFilter: 'color'})}
                                                                    disabled={disabled}
                                                                    checked={included}
                                                                    label={v.name}
                                                                    name={`color.${v.name}`}
                                                                />
                                                            )
                                                        }
                                                    )}
                                                />
                                                }

                                                {this.state.sizes &&
                                                <Filter
                                                    filterTitle={'Shop By Size'}
                                                    filterLinks={_.sortBy(this.state.sizes, 'name').map(v => {
                                                        let sizes = _.get(filterValues, 'size', []) || [];
                                                        const included = _.includes(sizes, v.name);
                                                        const available = undefined !== _.find(_.get(availableFilters, 'sizes', []) || [], {name: v.name});
                                                        const lastFilteredThis = (!this.state.lastFilter || this.state.lastFilter === 'size');
                                                        const disabled = !included && !available && !lastFilteredThis;

                                                        // Include/exclude the current value based on whether it's already filtered
                                                        sizes = included ? _.without(sizes, v.name) : [...sizes, v.name];

                                                        let nextFilters = _.clone(availableFilters);
                                                        nextFilters.colors = _.get(previousFilters, 'colors', {}) || {};

                                                        return (
                                                            <FilterLink
                                                                linkTo={disabled ? {} : this.getFilterLink(_.isEmpty(sizes) ? 'size' : {size: sizes})}
                                                                onClick={() => this.setState({loading: true, previousFilters: nextFilters, lastFilter: 'size'})}
                                                                disabled={disabled}
                                                                checked={included}
                                                                label={v.name}
                                                                name={`size.${v.name}`}
                                                            />
                                                        )
                                                    })}
                                                />
                                                }

                                                {this.state.attributes.map((key, k) => {
                                                    // eslint-disable-next-line default-case
                                                    switch (key.name) {
                                                        case '':
                                                        case 'Size':
                                                        case 'Color':
                                                        case 'Primary Color':
                                                        case 'Item Name':
                                                        case 'Distinct Name':
                                                        case 'Market Color':
                                                        case 'Marketing Color':
                                                        case 'Reference Number':
                                                            return null;
                                                        case 'Activity':
                                                            // Don't display any Activity filter if none are available
                                                            if (_.isEmpty(this.props.activityTypes)) {
                                                                return <></>;
                                                            }
                                                            break;
                                                    }

                                                    return (
                                                        <Filter
                                                            key={key.name}
                                                            filterTitle={`Shop By ${key.name}`}
                                                            filterLinks={_.sortBy(key.values, 'value')
                                                                .filter(value => {
                                                                    // Filter out only Activity options
                                                                    if (key.name !== 'Activity') {
                                                                        return true;
                                                                    }

                                                                    // The Activity type must be in our list to be filterable
                                                                    if (_.includes(this.props.activityTypes, value.value)) {
                                                                        return true;
                                                                    }

                                                                    return false;
                                                                })
                                                                .map(value => {
                                                                    const optionName = `attributes.${key.name}`;
                                                                    let values = _.get(filterValues, optionName, []) || [];
                                                                    const included = _.includes(values, value.value);
                                                                    const availableAttributes = _.find(_.get(availableFilters, 'attributes', []) || [], {name: key.name});
                                                                    const available = undefined !== _.find(_.get(availableAttributes, 'values', []) || [], {value: value.value});
                                                                    const lastFilteredThis = (!this.state.lastFilter || this.state.lastFilter === optionName);
                                                                    const disabled = !included && !available && !lastFilteredThis;

                                                                    // Include/exclude the current value based on whether it's already filtered
                                                                    values = included ? _.without(values, value.value) : [...values, value.value];

                                                                    let nextFilters = _.clone(availableFilters);
                                                                    nextFilters.colors = _.get(previousFilters, optionName, {}) || {};

                                                                    // Create an object to include the values with a variable property name
                                                                    let option = {};
                                                                    option[optionName] = values;

                                                                    return (
                                                                        <FilterLink
                                                                            linkTo={disabled ? {} : this.getFilterLink(_.isEmpty(values) ? optionName : option)}
                                                                            onClick={() => this.setState({loading: true, previousFilters: nextFilters, lastFilter: optionName})}
                                                                            disabled={disabled}
                                                                            checked={included}
                                                                            label={value.value}
                                                                            name={optionName}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    );
                                                })}
                                            </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className={cs('col-12', 'd-md-none', 'mb-3')}>
                                    <button
                                        className={cs('btn', 'btn-lg', 'btn-primary', 'w-100')}
                                        onClick={() => {
                                            if (this.state.showFilters) {
                                                document.getElementById('pss-breadcrumbs').scrollIntoView();
                                            }

                                            this.setState({showFilters: !this.state.showFilters})
                                        }}
                                    >
                                        {this.state.showFilters ? 'Hide Filters' : 'Filter & Search'}
                                    </button>
                                </div>
                            </React.Fragment>
                            }

                            {/*Right Column*/}
                            <div className={cs('col-md')}>
                                <React.Fragment>
                                    <div className={cs('row')} id={'pss-category-results'}>
                                        {this.state.products.map(v => (
                                            <ProductCard
                                                key={v.WebstoreProduct.id}
                                                webstoreProductId={v.WebstoreProduct.id}
                                                manufacturerGroupName={v.ProductGroup.ManufacturerGroup.name}
                                                productGroupName={v.ProductGroup.name}
                                                imageUrl={() => {
                                                    if (v.WebstoreProduct.image_url) {
                                                        return v.WebstoreProduct.image_url;
                                                    }

                                                    return baseClient().resolveUrl(`/webstore/webstore_images/resize_hero/${v.WebstoreProduct.id}/250/250`);
                                                }}
                                                minPrice={v.WebstoreProduct.min_price}
                                                products={v.ProductGroup.Product}
                                                featured={v.WebstoreProduct.featured}
                                                onSale={v.WebstoreProduct.on_sale}
                                            />
                                        ))}
                                    </div>

                                    {!this.state.loading && this.state.products.length === 0 &&
                                    <div className={cs('row', 'my-5', 'justify-content-center')}>
                                        <div className={cs('col-6')}>
                                            <div className={cs('row')}>
                                                <div className={cs('col-12', 'text-center')}>
                                                    <h2>No Results Found</h2>
                                                </div>
                                                <div className={cs('col-12', 'my-3')}>
                                                    <p className={cs('lead')}>
                                                        We couldn't find any products that exactly match what you were looking for.
                                                    </p>
                                                </div>
                                                <div className={cs('col-12')}>
                                                    <p className={cs('lead')}>
                                                        Please try removing some of your applied filters.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.loading &&
                                    <div className={cs('row', 'my-3')}>
                                        <div className={cs('col', 'text-center')}>
                                            <Spinner/>
                                        </div>
                                    </div>
                                    }

                                    {!this.state.loading && this.state.products.length > 0 &&
                                    <div className={cs('row', 'my-3')}>
                                        <div className={cs('col-9')}>
                                            <button
                                                type={'button'}
                                                className={cs('btn', 'btn-outline-primary', 'btn-block', 'btn-lg')}
                                                onClick={() => this.setState({loading: true}, () => this.search(this.state.products.length))}
                                                disabled={this.state.loading || this.state.products.length >= this.state.hits}
                                            >
                                                Show More
                                            </button>
                                        </div>
                                        <div className={cs('col')}>
                                            <button
                                                type={'button'}
                                                className={cs('btn', 'btn-outline-primary', 'btn-block', 'btn-lg')}
                                                onClick={() => window.scrollTo(0, 0)}
                                            >
                                                <span className={cs('d-none', 'd-md-inline')}>Back To</span> Top
                                            </button>
                                        </div>
                                    </div>
                                    }

                                </React.Fragment>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
