import React from 'react';
import PropTypes from 'prop-types';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import _ from 'lodash';

export default class ProductFilter extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string),
        value: PropTypes.string,
        setFieldValue: PropTypes.func,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        options: [],
        value: '',
        disabled: false
    };

    state = {
        options: []
    };

    componentDidMount() {
        this.setState({options: this.props.options});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== '' && this.props.options.indexOf(this.props.value) < 0) {
            this.props.setFieldValue(this.props.label, '');
            this.setState({options: this.props.options});
            return;
        }

        const optionsChanged = _.xor(this.state.options, this.props.options).length > 0;

        if (this.props.value === '' && optionsChanged) {
            this.setState({options: this.props.options}, () => {
                if (this.state.options.length === 1) {
                    this.props.setFieldValue(this.props.label, this.state.options[0]);
                }
            });
        } else if (optionsChanged) {
            // Options changed, make sure the field is updated, but leave the value alone
            this.setState({options: this.props.options});
        }
    }

    render() {
        if (this.props.options.length === 0) {
            return null;
        }

        //@link: https://www.w3schools.com/jsref/jsref_sort.asp
        const options = this.state.options.sort((a, b) => ('' + a).localeCompare('' + b));

        const inputId = `pss-product-filter-${_.snakeCase(_.lowerCase(this.props.label))}`;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row', 'mb-3')}>
                        <div className={cs('col')}>
                            <label htmlFor={inputId} className={cs('sr-only')}>{this.props.label}</label>
                            <select
                                className={cs('custom-select', 'custom-select-lg', 'w-100')}
                                value={this.props.value}
                                name={this.props.label}
                                onChange={e => this.props.setFieldValue(e.currentTarget.name, e.currentTarget.value)}
                                disabled={this.props.disabled}
                                id={inputId}
                            >
                                <option value={''}>Select {this.props.label}</option>
                                {options.map(v => <option key={v} value={v}>{v}</option>)}
                            </select>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}
