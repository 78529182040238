import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { ThemeContext } from 'src/client.webstore/Context/StyleProvider';
import { cart } from 'src/client.webstore/utils/cart';
import { gateway } from 'src/client.webstore/utils/gateway';
import _ from 'lodash';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

class SpikePayForm extends React.Component {
    state = {
        paymentProcessing: false,
        redirect: null,
        cardToken: '',
        error: null,
        complete: false
    };

    componentDidMount() {
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = event => {
        try {
            let tokenizeMessage = JSON.parse(event.data);

            if (tokenizeMessage.validationError) {
                this.setState({
                    error: "Invalid Account Details",
                    cardToken: '',
                    paymentProcessing: false
                });
            } else if (tokenizeMessage.message) {
                this.setState({
                    error: null,
                    cardToken: tokenizeMessage.message,
                    complete: true
                });
            } else {
            }
        } catch (e) {}
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({paymentProcessing: true}, () => {
            cart.setPaymentToken(this.state.cardToken)
                .then(() => gateway.purchase(
                    'SpikePay',
                    {
                        token: this.state.cardToken, 
                        cof: "C",
                        cofscheduled: "N",
                        receipt: "Y",
                        postal: this.props.billing.zip,
                        address: this.props.billing.address_1,
                        address2: this.props.billing.address_2,
                        city: this.props.billing.city,
                        region: this.props.billing.state,
                        country: this.props.billing.country,
                        phone: this.props.billing.phone,
                        email: this.props.billing.email,
                        name: this.props.billing.name
                    })
                )
                .then(
                    res => cart.clear().then(
                        () => this.setState({
                                redirect: `/cart/summary/${res.data.orderId}`
                            }, 
                            () => Analytics.convert(res.data.orderId)
                        )
                    )
                )
                .catch(err => this.setState({error: err.message, paymentProcessing: false}));
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <form onSubmit={this.handleSubmit}>
                        <div className={cs('row', 'my-5')}>
                            <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                                <div className={cs('row', 'mb-3')}>
                                    <div className={cs('col')}>
                                        <h3>Credit Card</h3>
                                        <p className={cs('lead')}>
                                            Your payment information is transmitted securely and never stored.
                                        </p>
                                    </div>
                                </div>
                                <div className={cs('row', 'mb-3')}>
                                    <div className={cs('col')}>
                                        <label htmlFor={'card-name'}>Name On Card</label>
                                        <input type='text' readOnly className={cs('form-control-plaintext')} id='card-name' value={this.props.billing.name}/>
                                    </div>
                                    <div className={cs('col')}>
                                        <label htmlFor={'card-zip'}>Postal Code</label>
                                        <input type='text' readOnly className={cs('form-control-plaintext')} id='card-zip' value={this.props.billing.zip}/>
                                    </div>
                                </div>
                                <div className={cs('row', 'mb-1')}>
                                    <div className={cs('col')}>
                                        <div className={cs('border', 'p-3')}>
                                            <iframe id="tokenFrame" 
                                                name="tokenFrame" 
                                                src="https://isv-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&invalidinputevent=true&css=.error%7Bcolor%3Ared%3Bborder-color%3Ared%3B%7D" 
                                                frameBorder="0"
                                                scrolling="no">
                                            </iframe>
                                            <input type="hidden" name="card_token" id="card_token" value={this.state.cardToken}/>
                                        </div>
                                    </div>
                                </div>

                                {_.has(this.state, 'error') &&
                                <div className={cs('row')}>
                                    <div className={cs('col', 'text-danger')}>
                                        {this.state.error}
                                    </div>
                                </div>
                                }

                                <div className={cs('row', 'justify-content-end', 'my-3')}>
                                    <div className={cs('col-12', 'col-md-5', 'text-md-right')}>
                                        <button
                                            type={'submit'}
                                            className={cs('btn', 'btn-lg', 'btn-primary', 'text-uppercase', 'w-100')}
                                            disabled={!this.state.complete || this.state.paymentProcessing}
                                        >
                                            {!this.state.paymentProcessing && 'Complete Order'}
                                            {this.state.paymentProcessing && 'Please Wait'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </ThemeContext.Consumer>
        );
    }
}

class SpikePay extends React.Component {
    state = {
        spike_pay: null
    };

    componentDidMount() {
        this.setState({spike_pay: this.props.gatewayConfig.spike_pay});
    }

    render() {
        if (!this.state.spike_pay) {
            return <h1>Loading....</h1>;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <meta name='viewport' content='width=device-width'/>
                </Helmet>
                <SpikePayForm billing={this.props.billing}/>
            </React.Fragment>
        );
    }
}

export default SpikePay;