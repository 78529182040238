import baseClient from './base';

export default function productsClient() {
    /**
     * @namespace Products
     */
    return {
        ...baseClient(),

        /**
         * @param {number} webstoreProductId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        getProduct: (webstoreProductId) => baseClient().get(`/webstore/webstore_products/view/${webstoreProductId}`),

        /**
         *
         * @param {number} catalogId
         * @param foreignKeyId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        getVariation: (catalogId, foreignKeyId) => baseClient().get(`/webstore/webstore_products/get_variation/${catalogId}/${foreignKeyId}`),

        /**
         * @param {number} webstoreProductId
         * @memberOf Products
         * @return {Promise}
         * @function
         */
        getGallery: (webstoreProductId) => baseClient().get(`/webstore/webstore_images/gallery/${webstoreProductId}`),

        /**
         *
         * @param {number} catalogId
         * @param {number} foreignKeyId
         * @param {number} maxWidth
         * @param {number} maxHeight
         * @param {string} encode
         * @return {string}
         * @memberOf Products
         * @function
         */
        imageUrl: (catalogId, foreignKeyId, maxWidth = 1024, maxHeight = 1024, encode = 'png') =>
            baseClient().resolveUrl(`/webstore/webstore_images/resize_sidekick/${catalogId}/${foreignKeyId}/${maxWidth}/${maxHeight}/${encode}`),

        /**
         *
         * @param {number} webstoreProductId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        heroImageUrl: (webstoreProductId) => baseClient().resolveUrl(`/webstore/webstore_images/resize_hero/${webstoreProductId}/250/250`),

        /**
         *
         * @param {number} catalogId
         * @param {number} foreignKeyId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        calculatePrice: (catalogId, foreignKeyId) => baseClient().get(`/webstore/webstore_pricing/calculate/${catalogId}/${foreignKeyId}`),

        /**
         * @param {number} catalogId
         * @param {number} foreignKeyId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        calculateStock: (catalogId, foreignKeyId) => baseClient().get(`/webstore/webstore_inventory/calculate/${catalogId}/${foreignKeyId}`),

        /**
         * @param {Number} webstoreProductId
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        getYears: (webstoreProductId) => baseClient().get(`/webstore/webstore_products/get_years/${webstoreProductId}`),

        /**
         * @param {Number} webstoreProductId
         * @param {Number|String} year
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        getMakes: (webstoreProductId, year) => baseClient().get(`/webstore/webstore_products/get_makes/${webstoreProductId}`, {year}),

        /**
         * @param {Number} webstoreProductId
         * @param {Number|String} year
         * @param {String} make
         * @return {Promise}
         * @memberOf Products
         * @function
         */
        getModels: (webstoreProductId, year, make) => baseClient().get(`/webstore/webstore_products/get_models/${webstoreProductId}`, {year, make})
    };
}
