import axios from 'axios';
import qs from 'qs';

let client;
let apiBase;
let pssCt;

export default function baseClient(endpoint = '', forHost = window.location.host) {
    if (!apiBase) {
        console.warn(`Base API endpoint has been changed from the default to ${endpoint}`);
        apiBase = endpoint;
    }

    if (!client) {
        client = {
            send: (request) => {
                if (!request.params) {
                    request.params = {};
                }

                request.params.for = forHost;

                if (pssCt && pssCt == 2) {
                    request.params.pss_ct = pssCt;
                }
                
                request = {
                    ...request,
                    url: `${apiBase}${request.url}`,
                    headers: {
                        Accept: 'application/json'
                    }
                };

                return axios(request);
            },
            post: (uri, query = {}, data = {}) => {
                let request = {
                    method: 'POST',
                    params: query,
                    url: uri,
                    data: data
                };

                return client.send(request);
            },
            get: (uri, query = {}) => {
                let request = {
                    method: 'GET',
                    params: query,
                    url: uri
                };

                return client.send(request);
            },
            getConfig: (force = false) => {
                return client.get('/webstore/webstore_app/config');
            },
            resolveUrl: (uri) => {
                let Url = new URL(uri, apiBase);
                let params = qs.parse(Url.search);
                params.for = forHost;
                
                if (pssCt && pssCt == 2) {
                    params.pss_ct = pssCt;
                }
                
                Url.search = qs.stringify(params);
                return Url.toString();
            },
            set: (pss_ct) => {
                pssCt = pss_ct;
            }
        };
    }

    return client;
}
