import React from 'react';
import baseClient from 'src/client.webstore/api/base';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import _ from 'lodash';
import {Redirect} from 'react-router-dom';
import CategoryImages from 'src/client.webstore/Views/Landing/Elements/CategoryImages';
import BrandLogos from 'src/client.webstore/Views/Landing/Elements/BrandLogos';
import {Rectangle} from 'src/client.webstore/Views/Landing/Elements/Rectangle';
import styled from 'styled-components';

const LandingTab = styled.div`
  font-size: 1.25rem;
  
  @media (min-width: 500px) {
    font-size: 1.95rem;
  }

  @media (max-width: 499px) {
    // Decrease padding on tabs at lower res
    > a {
      padding: 0.25rem 0.5rem !important;
    }
  }
`;

/**
 * Landing page for the Webstore
 */
export default class Landing extends React.Component {

    state = {
        loading: true,
        tab: '',
        tabs: [],
        categories: []
    }

    changeTab = (key) => {
        if (this.state.tab !== key) {
            this.setState({tab: key});
        }
    }

    componentDidMount() {
        baseClient().get('/webstore/webstore_landing').then((res) => {
            let categories = _.get(res.data, 'categories', []) || [];
            let tab = '';
            let tabs = [];

            _.forEach(categories, (v, k) => {
                if (tab === '') {
                    tab = k;
                }
                tabs.push({
                    id: k,
                    name: _.get(v, 'name', ''),
                    slug: _.get(v, 'slug', '')
                });
            });

            this.setState({
                loading: false,
                tab: tab,
                tabs: tabs,
                categories: categories
            })
        });
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    render() {
        const {tab, tabs, loading} = this.state;
        const categories = _.get(this.state.categories, `[${tab}].categories`, []) || [];
        const brands = _.get(this.state.categories, `[${tab}].brands`, []) || [];

        // No categories/brands, then redirect to the search page for all categories
        if (!loading && categories.length <= 0 && brands.length <= 0) {
            return <Redirect to={'/categories'}/>
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <>
                        {categories.length > 0 &&
                            <div className={cs('row', 'mb-5')}>
                                <div className={cs('col-12', 'mb-5')}>
                                    <div className={cs('pb-3')}>
                                        <Rectangle.separator className={cs('bg-primary')} width={'100px'}/>
                                    </div>
                                    <h2 className={cs('text-uppercase')}>Shop By Category</h2>
                                </div>

                                {tabs.length > 1 &&
                                    <ul className={cs('nav', 'nav-tabs', 'mb-3')} id={'pss-landing-tabs'} role={'tablist'}>
                                        {_.map(tabs, t => {
                                            const {id, name} = t;
                                            return (
                                                <li className={cs('nav-item')} key={`tabid-${id}`}>
                                                    <LandingTab>
                                                        <a
                                                            className={cs('nav-link', 'text-uppercase', id === tab ? 'active' : '')}
                                                            id={`tab-${id}`}
                                                            href={'#'}
                                                            role={'tab'}
                                                            aria-controls={id}
                                                            aria-selected={'true'}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.changeTab(id);

                                                                return false;
                                                            }}
                                                        >
                                                            {name}
                                                        </a>
                                                    </LandingTab>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }

                                <div className={cs('tab-content', 'col-12')}>
                                    {_.map(tabs, t => {
                                        const {id, name, slug} = t;
                                        const tabCategories = _.get(this.state.categories, `[${id}].categories`, []) || [];
                                        return (
                                            <div
                                                key={`tab-content-${id}`}
                                                id={`tab-pane-${id}`}
                                                role={'tabpanel'}
                                                aria-labelledby={name}
                                                aria-hidden={id !== tab}
                                                className={cs('tab-pane', 'fade', (id === tab ? ['show', 'active'] : []))}
                                            >
                                                <CategoryImages categories={tabCategories} categorySlug={slug}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        {brands.length > 0 &&
                            <div className={cs('row')}>
                                <div className={cs('col-12', 'mb-5')}>
                                    <div className={cs('pb-3')}>
                                        <Rectangle.separator className={cs('bg-primary')} width={'100px'}/>
                                    </div>
                                    <h2 className={cs('text-uppercase')}>Shop By Brand</h2>
                                </div>
                            </div>
                        }

                        <div className={cs('row')}>
                            <div className={cs('tab-content', 'col-12')}>
                                {_.map(tabs, t => {
                                    const {id, name, slug} = t;
                                    const tabBrands = _.get(this.state.categories, `[${tab}].brands`, []) || [];

                                    if (_.isEmpty(tabBrands)) {
                                        return <></>
                                    }

                                    return (
                                        <div
                                            key={`tab-brands-${id}`}
                                            id={`tab-brand-${id}`}
                                            role={'tabpanel'}
                                            aria-labelledby={name}
                                            aria-hidden={id !== tab}
                                            className={cs('tab-pane', 'fade', (id === tab ? ['show', 'active'] : []))}
                                        >
                                            <BrandLogos brands={tabBrands} categorySlug={slug}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
            </ThemeContext.Consumer>
        );
    }
}
