import React from 'react';
import expressImage from './static/express.png';
import {withRouter, Redirect} from 'react-router-dom';
import {gateway} from 'src/client.webstore/utils/gateway';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import qs from 'query-string';
import {cart} from 'src/client.webstore/utils/cart';
import analytics from 'src/client.webstore/utils/analytics';
import PageLoadingOverlay from 'src/client.webstore/Views/Elements/PageLoadingOverlay';
import _ from 'lodash';

const Analytics = analytics();

class PayPalExpress extends React.Component {
    state = {
        loading: false,
        redirect: null,
        showOverlay: false
    };

    componentDidMount() {
        let query = qs.parse(this.props.location.search);

        if (query.PayerID && query.token) {
            this.setState({showOverlay: true, loading: true}, () => {
                cart
                    .setPaymentToken(query.token)
                    .then(() => gateway.completeAuthorizeCapture('PayPal_Express', {...query}))
                    .then(res => cart.clear().then(
                            () => this.setState(
                                {
                                    redirect: `/cart/summary/${res.data.orderId}`,
                                    showOverlay: false
                                },
                                () => Analytics.convert(res.data.orderId)
                            )
                        )
                    )
                    .catch(e => this.setState({showOverlay: false, loading: false, error: {message: e.message}}));
            });
        }
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <React.Fragment>
                {this.state.showOverlay &&
                <PageLoadingOverlay text={'Processing...'}/>
                }

                <ThemeContext.Consumer>
                    {({cs}) => (
                        <div className={cs('row', 'my-5')}>
                            <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                                <div className={cs('row', 'mb-5')}>
                                    <div className={cs('col')}>
                                        <h3>PayPal</h3>
                                        <p className={cs('lead')}>
                                            You will be redirected to PayPal to complete your purchase securely.
                                        </p>

                                        {_.has(this.state, 'error.message') &&
                                        <div className={cs('row')}>
                                            <div className={cs('col', 'text-danger')}>
                                                {this.state.error.message}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className={cs('row', 'justify-content-end')}>
                                    <div className={cs('col-12', 'col-md-5', 'align-self-center')}>
                                        <input
                                            type={'image'}
                                            src={expressImage}
                                            className={cs('img-fluid')}
                                            alt={'Checkout with PayPal'}
                                            disabled={this.state.loading}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({loading: true}, () => {
                                                    gateway
                                                        .authorize('PayPal_Express', {returnUrl: window.location.href, cancelUrl: window.location.href})
                                                        .then(res => window.location = res.data.redirect)
                                                        .catch(e => this.setState({loading: false, error: {message: e.message}}));
                                                });
                                            }}
                                            style={{
                                                opacity: this.state.loading ? 0.65 : 1
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ThemeContext.Consumer>
            </React.Fragment>
        );
    }
}

export default withRouter(PayPalExpress);