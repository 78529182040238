import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import handleViewport from 'react-in-viewport';
import ProgressiveImage from 'react-progressive-image';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import FixedLabel from 'src/client.webstore/Views/Elements/FixedLabel';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

class ProductCardRefactor extends React.Component {
    static propTypes = {
        webstoreProductId: PropTypes.number.isRequired,
        manufacturerGroupName: PropTypes.string.isRequired,
        productGroupName: PropTypes.string.isRequired,
        imageUrl: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func
        ]).isRequired,
        minPrice: PropTypes.number.isRequired,
        products: PropTypes.array,
        featured: PropTypes.bool,
        onSale: PropTypes.bool
    };

    static defaultProps = {
        products: [],
        featured: false,
        onSale: false
    }

    state = {
        beenVisible: false
    };

    componentDidMount() {
        if (this.props.inViewport) {
            this.setState({beenVisible: true});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.inViewport && !this.state.beenVisible) {
            this.setState({beenVisible: true});
        }
    }

    render() {
        let {inViewport, imageUrl} = this.props;

        if (typeof imageUrl === 'function') {
            imageUrl = imageUrl();
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('col-6', 'col-md-4', 'col-lg-3', 'mb-4', 'd-flex', 'pss-product-card')}>
                        {this.props.onSale &&
                        <FixedLabel label={'ON SALE'} side={'left'} className={cs('bg-danger', 'text-white', 'p-2', 'shadow')} />
                        }

                        {this.props.featured &&
                        <FixedLabel label={'FEATURED'} side={'right'} className={cs('bg-primary', 'text-white', 'p-2', 'shadow')} />
                        }

                        <div
                            className={cs(
                                'border',
                                'rounded',
                                'radius-0',
                                'w-100',
                                'h-100',
                                'text-center',
                                'd-flex',
                                'flex-column',
                                'justify-content-between',
                                'bg-white'
                            )}
                        >
                            <div className={cs('d-flex', 'justify-content-center', 'flex-grow-1', 'mb-1', 'border-bottom', 'rounded')}>
                                {(inViewport || this.state.beenVisible) &&
                                <ProgressiveImage src={imageUrl} placeholder={''}>
                                    {(src, loading) => {
                                        if (loading) {
                                            return <Spinner/>;
                                        }

                                        return <img src={src} className={cs('img-fluid', 'my-auto')} alt={this.props.productGroupName} style={{maxHeight: '200px'}}/>;
                                    }}
                                </ProgressiveImage>
                                }
                            </div>

                            <div>
                                <p className={cs('text-muted', 'mt-auto', 'small')}>
                                    {this.props.manufacturerGroupName}
                                </p>

                                <p className={cs('font-weight-bold')}>
                                    <Link
                                        to={{
                                            ...this.props.location,
                                            pathname: `/products/view/${this.props.webstoreProductId}`
                                        }}
                                        className={cs('stretched-link')}
                                        onClick={() => Analytics.click({
                                            ecomm_part_detail_id: this.props.webstoreProductId,
                                            ecomm_part_detail_name: this.props.productGroupName,
                                            ecomm_part_detail_brand: this.props.manufacturerGroupName,
                                            tealium_event: 'ecommerce_part_click'
                                        })}
                                    >
                                        {this.props.productGroupName}
                                    </Link>
                                </p>

                                {this.props.minPrice > 0 &&
                                <p className={cs('small')}>
                                    <CartContext.Consumer>
                                        {({currency, gatewayConfig}) => {
                                            if (gatewayConfig.disable_pricing) {
                                                return null;
                                            }

                                            return (
                                                <React.Fragment>
                                                    From {currency(this.props.minPrice).format(true)}
                                                </React.Fragment>
                                            );
                                        }}
                                    </CartContext.Consumer>
                                </p>
                                }

                                {this.props.products.length > 1 &&
                                <p className={cs('text-muted', 'small')}>
                                    + Options Available
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

ProductCardRefactor = handleViewport(ProductCardRefactor);
ProductCardRefactor = withRouter(ProductCardRefactor);

export default ProductCardRefactor;
