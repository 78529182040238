import React from 'react';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import Icon from 'src/client.webstore/Views/Elements/Icon';

/**
 * Render a "Call Us" button if a telephone number is configured
 */
export default class CallUsButton extends React.Component {
    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {({gatewayConfig}) => (
                            <React.Fragment>
                                {gatewayConfig.customer_service_telephone &&
                                <div className={cs('col-12')}>
                                    <a
                                        href={`tel:${gatewayConfig.customer_service_telephone}`}
                                        className={cs('btn', 'btn-secondary', 'w-100', 'btn-lg')}
                                        id={'pss-click-to-call'}
                                    >
                                        <span className={cs('mr-3')}><Icon icon={'phone'}/></span>

                                        Call Us
                                    </a>
                                </div>
                                }
                            </React.Fragment>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        )
    }
}
