import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import baseClient from 'src/client.webstore/api/base';
import productsClient from 'src/client.webstore/api/product';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import _ from 'lodash';
import styled from 'styled-components';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderMainArrow from 'src/client.webstore/static/svg/main-arrow.svg';
import SliderThumbArrow from 'src/client.webstore/static/svg/thumb-arrow.svg';
import NotAvailableImage from 'src/client.webstore/static/png/not_available_default.png';
import {Modal, ModalBody} from 'reactstrap';
import MODAL from 'src/client.webstore/static/bootstrap.modal.module.scss';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';

// Wrapper styles for the gallery modal
const CloseButton = styled.div`
  .pss-close-modal {
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    width: 12px;
    font-size: 7px;
    padding-top: 0;
    
    @media (min-width: 992px) {
      height: 50px;
      width: 50px;
      font-size: 29px;
      padding-top: 4px;
    }
    
    @media (min-width: 360px) and (max-width: 991px) {
      height: 25px;
      width: 25px;
      font-size: 14px;
      padding-top: 2px;
    }
  }
`;

// Wrapper styles override the slider layout for the product carousel
const Wrapper = styled.div`
  .pss-slider .slick-next, 
  .pss-slider .slick-prev {
    z-index: 2;
    font-size: 0;
  }

  .pss-product-slider-main .pss-slider .slick-next,
  .pss-product-slider-main .pss-slider .slick-prev {
    height: auto;
    
    @media (min-width: 992px) {
      width: 59px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 41px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      width: 59px;
    }

    @media (min-width: 360px) and (max-width: 575px) {
      width: 41px;
    }

    @media (min-width: 200px) and (max-width: 359px) {
      width: 25px;
    }

    @media (min-width: 140px) and (max-width: 199px) {
      max-width: 12px;
    }

    @media (max-width: 139px) {
      max-width: 6px;
    }
  }
  
  .pss-product-slider-thumb .pss-slider .slick-next {
    margin-right: 1px;
  }
  .pss-product-slider-thumb .pss-slider .slick-prev {
    margin-left: 1px;
  }

  .pss-product-slider-thumb .pss-slider .slick-next,
  .pss-product-slider-thumb .pss-slider .slick-prev {
    height: auto;

    @media (min-width: 200px) {
      width: 15px;
    }

    @media (min-width: 140px) and (max-width: 199px) {
      max-width: 8px;
    }

    @media (max-width: 139px) {
      max-width: 6px;
    }
  }
  
  .pss-product-slider-main .pss-slider .slick-next img,
  .pss-product-slider-main .pss-slider .slick-prev img,
  .pss-product-slider-thumb .pss-slider .slick-next img,
  .pss-product-slider-thumb .pss-slider .slick-prev img {
    max-width: 100%;
    height: auto;
  }

  .pss-slider .slick-next:focus,
  .pss-slider .slick-prev:focus {
    outline: none;
  }
  
  .pss-slider .slick-next:before, 
  .pss-slider .slick-prev:before {
    content: '' !important;
    line-height: 0;
  }

  .pss-product-slider-thumb .pss-slider .slick-list img {
    opacity: 0.5;
    border: 2px solid rgba(204, 204, 204, 0.5) !important;
    border-radius: 3px;
  }
  
  .pss-product-slider-thumb .pss-slider .slick-current img {
    opacity: 1;
    border: 2px solid rgba(2, 167, 240, 1) !important;
    ${props => props.linkColor && `
      border: 2px solid ${props.linkColor} !important;
    `}
    }
  
  .pss-product-slider-thumb {
    background-color: rgba(242, 242, 242, 1);
  }
  
  .pss-product-slider-main .slick-slide,
  .pss-product-slider-thumb .slick-slide {
    z-index: 1;
    cursor: pointer;
  }

  .pss-product-slider-main .pss-gallery-container {
    position: absolute;
    top: 10px;
    right: 25px;
    z-index: 2;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    .pss-gallery-background {
      background: rgba(0, 0, 0, 0.53);
      border: none;
      box-shadow: none;
      text-align: left;
      color: #fff;
    }

    .pss-gallery-background > span {
      padding-left: 6px !important;
    }
    
    @media (min-width: 767px) {
      .pss-gallery-background {
        width: 163px;
        height: 51px;
        padding: 16px 0 0 0;
        border-radius: 8px;
      }
    }
    
    // The button to open the gallery should just be the icon at lower resolutions
    @media (max-width: 766px) {
      .pss-gallery-background {
        width: 30px;
        height: 30px;
        padding: 6px 0 0 0;
        border-radius: 50%;
      }
      
      .pss-gallery-background .pss-gallery-message {
        display: none;
      }
    }
  }
  
  // Show the gallery button animation for desktop and mobile users
  .pss-product-slider-main:hover .pss-gallery-container,
  .pss-product-slider-main:focus .pss-gallery-container,
  .pss-product-slider-main:active .pss-gallery-container {
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }

  .pss-product-slider-main:hover,
  .pss-product-slider-main:focus,
  .pss-product-slider-main:active {
    cursor: pointer;
  }
`;

/**
 * Renders a previous/next arrow for the slider
 *
 * @param props
 *  - arrow - The image src of the arrow
 *  - direction - The direction the arrow points (i.e, 'left', 'right'; default 'left')
 * @returns {JSX.Element}
 */
function ProductSliderArrow (props) {
    const {className, style, onClick, arrow} = props;
    const right = _.get(props, 'direction', 'left') === 'right';
    const styles = right ? {transform: 'translate(0%, -50%) scaleX(-1)'} : {};

    return (
        <div
            className={className}
            style={styles}
            onClick={onClick}
        >
            <img src={arrow} alt={right ? 'Right' : 'Left'} />
        </div>
    )
}

/**
 * Renders a set of sliders for a product using images derived from the given colors
 * If provided with variation images, renders a set of synced sliders for those product variation images
 */
export default class ProductSlider extends React.Component {
    static propTypes = {
        catalogId: PropTypes.number.isRequired,
        foreignKeyId: PropTypes.number.isRequired,
        selectedColor: PropTypes.string.isRequired,
        colors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string
        })),
        updateColor: PropTypes.func.isRequired,
        initialMainSlideIndex: PropTypes.number, // if variationImages are provided, this will override the selectedColor
        variationImages: PropTypes.array,
        showGallery: PropTypes.bool,
        showThumbnailSlider: PropTypes.bool,
        primaryImageSize: PropTypes.number,
        thumbImageSize: PropTypes.number,
        thumbResponsiveBreakpoints: PropTypes.array
    };

    static defaultProps = {
        variationImages: [],
        initialMainSlideIndex: 0,
        showGallery: true,
        showThumbnailSlider: true,
        primaryImageSize: 337,
        thumbImageSize: 100,
        thumbResponsiveBreakpoints: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 637,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 438,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 249,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    state = {
        isGalleryOpen: false,
        loading: true,
        variationImages: []
    }

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            currentSlideIndex: this.props.initialMainSlideIndex
        };
    }

    componentDidMount() {
        this.loadImages();
    }

    loadImages() {
        this.getVariationImages()
            .then(() => this.setState({
                nav1: this.slider1,
                nav2: this.slider2,
                initialMainSlide: this.getInitialMainSlide(),
                initialThumbSlide: this.getInitialThumbSlide(),
                loading: false
            }));
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    componentDidUpdate(prevProps) {
        // Change slides to the color that is now selected
        if (this.slider2 && prevProps.selectedColor !== this.props.selectedColor) {
            this.slider2.slickGoTo(this.getInitialThumbSlide());
        }

        // If the variation has changed, reload the images
        if (prevProps.foreignKeyId !== this.props.foreignKeyId) {
            this.setState({loading: true}, () => {
                this.loadImages();
            });
        }
    }

    getInitialMainSlide = () => {
        // If syncing variations, the initial slide is the first slide in the list or the currentSlideIndex
        if (this.props.variationImages.length > 0) {
            return (this.state.currentSlideIndex >= 0 ? this.state.currentSlideIndex : 0);
        }

        return 0;
    }

    getInitialThumbSlide = () => {
        // If syncing variations, the initial slide is the first slide in the list
        if (this.props.variationImages.length > 0) {
            return this.getInitialMainSlide();
        }

        // The initial slide is the color of the item clicked
        const index = _.findIndex(this.props.colors, o => o.color === this.props.selectedColor);

        return index < 0 ? 0 : index;
    }

    getVariationImages = () => {
        // If we weren't given variation images, fetch them
        if (this.props.variationImages.length <= 0) {
            return baseClient()
                .get(`/webstore/webstore_images/all/${this.props.catalogId}/${this.props.foreignKeyId}`)
                .then(res => {
                    // Set a blank image if none are available. It will be set to NotAvailableImage
                    let images = res.data;
                    if (_.isEmpty(images)) {
                        images = [''];
                    }
                    this.setState({variationImages: images});
                });
        } else {
            // Just return a valid promise if we already have the variation images
            return Promise.all([]);
        }
    }

    /**
     * Sets a placeholder image for <img /> that don't load
     * @param e The target event img
     */
    setPlaceholderImage = (e) => {
        e.target.src = NotAvailableImage;
    }

    render() {
        const {catalogId, foreignKeyId, colors, selectedColor, showGallery, showThumbnailSlider, primaryImageSize, thumbImageSize, thumbResponsiveBreakpoints} = this.props;
        const slideOverrideStyles = showGallery ? {} : {cursor: 'default'};
        const variationImages = this.props.variationImages.length > 0 ? this.props.variationImages : _.get(this.state, 'variationImages', []) || [];

        // If there is only 1 primary image, the "Click to view gallery" text should be removed in favor of just the icon
        let pssGalleryBackgroundStyle = {};
        let pssGalleryMessageStyle = {};
        if (variationImages.length === 1) {
            pssGalleryBackgroundStyle = {width: '30px', height: '30px', padding: '6px 0 0 0', borderRadius: '50%'};
            pssGalleryMessageStyle = {display: 'none'};
        }

        return (
            <ThemeContext.Consumer>
                {({cs, styles, vars}) => (
                    <Wrapper className={'pt-1'} linkColor={vars['link-color']}>
                        <div className={'pss-product-slider-main'}>
                            {showGallery &&
                                <div className={'pss-gallery-container'} aria-haspopup={'true'} onClick={() => this.setState({isGalleryOpen: true})}>
                                    <div className={'pss-gallery-background'} style={pssGalleryBackgroundStyle}>
                                        <Icon icon={'search-plus'} size={'lg'} padding={0} />
                                        <span className={'pss-gallery-message'} style={pssGalleryMessageStyle}>Click to view gallery</span>
                                    </div>
                                </div>
                            }

                            <Slider
                                className={'pss-slider'}
                                // Sync the sliders if there are variation images provided
                                asNavFor={this.props.variationImages.length > 0 ? this.state.nav2 : undefined}
                                ref={slider => (this.slider1 = slider)}
                                autoPlay={false}
                                initialSlide={this.state.initialMainSlide}
                                slidesToShow={1}
                                slidesToScroll={1}
                                swipe={true}
                                prevArrow={<ProductSliderArrow arrow={SliderMainArrow}/>}
                                nextArrow={<ProductSliderArrow arrow={SliderMainArrow} direction={'right'}/>}
                                touchMove={true}
                                afterChange={(index) => {
                                    this.setState({currentSlideIndex: index >= 0 ? index : 0})
                                }}
                            >
                                {this.state.loading &&
                                    <Spinner/>
                                }

                                {!this.state.loading &&
                                    _.map(variationImages, (image, index) => (
                                        <div
                                            key={`main-${index}`}
                                            onClick={() => showGallery ? this.setState({isGalleryOpen: true}) : null}
                                        >
                                            <div style={slideOverrideStyles}>
                                                <img
                                                    src={image === '' ? NotAvailableImage : image}
                                                    className={cs('img-fluid')}
                                                    style={{maxWidth: '75%', maxHeight: `${primaryImageSize}px`, margin: 'auto'}}
                                                    onError={this.setPlaceholderImage}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>

                        {/* When the Gallery is shown, whether to show the thumbnail slider */}
                        {showThumbnailSlider && this.props.variationImages.length > 0 &&
                            <div className={cs('pss-product-slider-thumb', 'pt-2')}>
                                <Slider
                                    className={'pss-slider'}
                                    asNavFor={this.state.nav1}
                                    // Sync the sliders if there are variation images provided
                                    ref={slider => (this.slider2 = slider)}
                                    autoPlay={false}
                                    initialSlide={this.state.initialThumbSlide}
                                    slidesToShow={Math.min(this.props.variationImages.length, 5)}
                                    slidesToScroll={1}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    swipe={true}
                                    touchMove={true}
                                    prevArrow={<ProductSliderArrow arrow={SliderThumbArrow}/>}
                                    nextArrow={<ProductSliderArrow arrow={SliderThumbArrow} direction={'right'}/>}
                                    responsive={thumbResponsiveBreakpoints}
                                >
                                    {this.state.loading &&
                                        <Spinner />
                                    }

                                    {!this.state.loading &&
                                        _.map(this.props.variationImages, (image, index) => (
                                            <div key={`thumb-${index}`} style={{width: '100%'}}>
                                                <img
                                                    src={image === '' ? NotAvailableImage : image}
                                                    className={cs('img-fluid', 'border', 'rounded', 'bg-white')}
                                                    style={{maxWidth: '90%', maxHeight: `${thumbImageSize}px`, margin: 'auto'}}
                                                    onError={this.setPlaceholderImage}
                                                />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        }

                        {/* When the Gallery is not shown, whether to show the thumbnail slider */}
                        {showThumbnailSlider && this.props.variationImages.length <= 0 && colors.length > 1 &&
                            <div className={cs('pss-product-slider-thumb', 'pt-2')}>
                                <Slider
                                    className={'pss-slider'}
                                    ref={slider => (this.slider2 = slider)}
                                    autoPlay={false}
                                    initialSlide={this.state.initialThumbSlide}
                                    slidesToShow={Math.min(colors.length, 5)}
                                    slidesToScroll={1}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    swipe={true}
                                    touchMove={true}
                                    prevArrow={<ProductSliderArrow arrow={SliderThumbArrow}/>}
                                    nextArrow={<ProductSliderArrow arrow={SliderThumbArrow} direction={'right'}/>}
                                    afterChange={(index) => {
                                        // Changing the slide should update the color
                                        const color = _.get(this.props, `colors[${index}].color`, '');

                                        // Reset the current main slide index if a thumbnail changed
                                        if (color.length) {
                                            this.setState({currentSlideIndex: 0}, () => this.props.updateColor(color));
                                        } else {
                                            this.setState({currentSlideIndex: 0})
                                        }
                                    }}
                                    responsive={thumbResponsiveBreakpoints}
                                >
                                    {_.map(colors, (image) => (
                                        <div key={`thumb-${image.id}`} style={{width: '100%'}}>
                                            <img
                                                src={productsClient().imageUrl(catalogId, image.id, thumbImageSize, thumbImageSize)}
                                                className={cs('img-fluid', 'border', 'rounded', 'bg-white')}
                                                style={{maxWidth: '90%', maxHeight: `${thumbImageSize}px`, margin: 'auto'}}
                                                onError={this.setPlaceholderImage}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        }

                        {showGallery &&
                        <Modal
                            isOpen={this.state.isGalleryOpen}
                            toggle={() => this.setState({isGalleryOpen: !this.state.isGalleryOpen})}
                            cssModule={MODAL}
                            fade={false}
                            wrapClassName={cs('pss')}
                            size={'xl'}
                        >
                            <ModalBody cssModule={styles}>
                                <CloseButton style={{position:'relative'}}>
                                    <button
                                        type={'button'}
                                        onClick={() => this.setState({isGalleryOpen: !this.state.isGalleryOpen})}
                                        className={'close'}
                                        aria-label={'Close'}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            zIndex: 1051
                                        }}
                                    >
                                        <span
                                            aria-hidden={'true'}
                                            className={'pss-close-modal'}
                                        >
                                            X
                                        </span>
                                    </button>
                                </CloseButton>
                                <ProductSlider
                                    variationImages={variationImages}
                                    catalogId={catalogId}
                                    foreignKeyId={foreignKeyId}
                                    selectedColor={selectedColor}
                                    initialMainSlideIndex={this.state.currentSlideIndex}
                                    colors={colors}
                                    updateColor={(color) => null}
                                    showGallery={false}
                                    showThumbnailSlider={variationImages.length > 1}
                                    primaryImageSize={700}
                                    thumbImageSize={250}
                                />
                            </ModalBody>
                        </Modal>
                        }
                    </Wrapper>
                )}
            </ThemeContext.Consumer>
        );
    }
}
