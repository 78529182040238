import React from 'react';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

export default class Prop65 extends React.Component {
    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row', 'my-3')}>
                        <div className={cs('col')}>
                            <p className={cs('small')}>
                                <strong>WARNING</strong>:
                                Products sold on this site may contain chemicals known in the State of California to cause cancer, birth defects, or other reproductive harm.
                                For more information, please visit <a href='https://www.P65Warnings.ca.gov' target={'_blank'} rel={'noopener noreferrer'}>www.P65Warnings.ca.gov</a>.
                            </p>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}