import React from 'react';
import styled from 'styled-components';

const CustomScrollbar = styled.div`
/* @link: https://css-tricks.com/almanac/properties/s/scrollbar-color/ */
/* @link: https://www.digitalocean.com/community/tutorials/css-scrollbars */

/* The emerging W3C standard that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: ${props => props.primary || `blue`} transparent;
}

/* Works on Chrome/Edge/Safari */

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: ${props => props.primary || `blue`};
  border-radius: 20px;
  border: 3px solid transparent;
}
`;

export default function (props) {
    return <CustomScrollbar {...props}/>
}