import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

export default class CartInput extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        bag: PropTypes.object.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })),
        checked: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        options: [],
        checked: false
    };

    render() {
        let {options, type, label, name, bag, onChange, ...props} = this.props;
        let inputId = `pss-cart-input-${_.snakeCase(_.lowerCase(name))}`;

        if (type === 'checkbox') {
            return (
                <ThemeContext.Consumer>
                    {({cs, styles: BS}) => (
                        <div className={cs('col')}>
                            <FormGroup check={true} inline={true} cssModule={BS}>
                                <Label check={true} cssModule={BS}>
                                    <Input
                                        {...props}
                                        type={type}
                                        onChange={typeof onChange === 'function' ? onChange : bag.handleChange}
                                        onBlur={bag.handleBlur}
                                        name={name}
                                        id={inputId}
                                        cssModule={BS}
                                    />
                                    {' '}
                                    {label}
                                </Label>
                            </FormGroup>
                        </div>
                    )}
                </ThemeContext.Consumer>
            );
        }

        if (type === 'select') {
            return (
                <ThemeContext.Consumer>
                    {({cs, styles: BS}) => (
                        <FormGroup cssModule={BS}>
                            <Label cssModule={BS} for={inputId}>{label}</Label>
                            <Input
                                {...props}
                                type={type}
                                onChange={typeof onChange === 'function' ? onChange : bag.handleChange}
                                onBlur={bag.handleBlur}
                                value={bag.values[name]}
                                name={name}
                                invalid={bag.errors[name] && bag.touched[name]}
                                id={inputId}
                                cssModule={BS}
                            >
                                {options.map(v => <option key={v.value} value={v.value}>{v.name}</option>)}
                            </Input>
                            {bag.errors[name] &&
                            <FormFeedback cssModule={BS}>{bag.errors[name]}</FormFeedback>
                            }
                        </FormGroup>
                    )}
                </ThemeContext.Consumer>
            );
        }

        return (
            <ThemeContext.Consumer>
                {({cs, styles: BS}) => (
                    <FormGroup cssModule={BS}>
                        <Label cssModule={BS} for={inputId}>{label}</Label>
                        <Input
                            {...props}
                            type={type}
                            onChange={typeof onChange === 'function' ? onChange : bag.handleChange}
                            onBlur={bag.handleBlur}
                            value={bag.values[name]}
                            name={name}
                            invalid={bag.errors[name] && bag.touched[name]}
                            id={inputId}
                            cssModule={BS}
                        />
                        {bag.errors[name] &&
                        <FormFeedback cssModule={BS}>{bag.errors[name]}</FormFeedback>
                        }
                    </FormGroup>
                )}
            </ThemeContext.Consumer>
        );
    }
}
