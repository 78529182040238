class MueConfigService {
    constructor() {
        this.config = {
            isMue: false
        };
    }
    setConfig(newConfig) {
        this.config = newConfig;
    }
    getConfig() {
        return this.config;
    }
}
const mueConfigService = new MueConfigService();
export default mueConfigService;