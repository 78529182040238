import baseClient from 'src/client.webstore/api/base';

export default function fitmentClient() {
    return {
        /**
         * @param year
         * @param make
         * @param model
         * @return {Promise<Object>}
         */
        search: (year = '', make = '', model = '') => {
            return baseClient()
                .get(
                    '/webstore/webstore_fitment/search',
                    {
                        year,
                        make,
                        model,
                        webstoreCategoryId: null,
                        limit: 0
                    }
                )
                .then(res => res.data);
        }
    }
}