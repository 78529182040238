import React from 'react';
import PropTypes from 'prop-types';
import productsClient from 'src/client.webstore/api/product';
import {product} from 'src/client.webstore/utils/cart';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {Modal, ModalBody} from 'reactstrap';
import MODAL from 'src/client.webstore/static/bootstrap.modal.module.scss';
import {Form, Formik} from 'formik';
import leads from 'src/client.webstore/utils/leads';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import {withCart} from 'src/client.webstore/Views/Cart/cartRouter';
import _ from 'lodash';
import styled from 'styled-components';

// Custom CSS that renders the cart actions
const CartActionsWrapper = styled.div`
  @media (min-width: 576px) {
    // Hide text input arrows on firefox
    #pss-cart-quantity {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      appearance: textfield;
    }

    // Hide text input arrows on webkit browsers
    #pss-cart-quantity::-webkit-outer-spin-button,
    #pss-cart-quantity::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  
  // Keep the quantity +/- button text centered
  .pss-cart-actions .pss-center-button {
    padding-left: 0;
    padding-right: 0;
  }

  // Updates the bootstrap 15px (30px total) gutter gap between cart action buttons down to 10px (20px total)
  .pss-closer-columns.row,
  .pss-closer-columns[class^="_row"],
  .pss-closer-columns[class^=" _row"] {
    margin-right: -10px;
    margin-left: -10px;
    align-items: center;
  }

  .pss-closer-columns > [class^="col-"],
  .pss-closer-columns > [class^=" col-"],
  .pss-closer-columns > [class^="_col-"],
  .pss-closer-columns > [class^=" _col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

/**
 * Renders the quantity and Add-to-Cart actions for a particular product
 */
class CartActionsRefactor extends React.Component {
    static propTypes = {
        filteredProducts: PropTypes.array.isRequired,
        webstoreProduct: PropTypes.object.isRequired,
        productGroup: PropTypes.object.isRequired,
        foreignKeyId: PropTypes.number
    };


    state = {
        selectedQuantity: 0,
        quantity: 0,
        loading: true,
        isContactOpen: false,
    };

    componentDidMount() {
        this.calculate();
    }

    componentDidUpdate(prevProps) {
        if (this.props.foreignKeyId !== prevProps.foreignKeyId) {
            this.calculate();
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    calculate = () => {
        const catalogId = Number(_.get(this.props, 'webstoreProduct.catalog_id', 0));

        this.setState({loading: true}, () => {
            Promise
                .all([
                    productsClient()
                        .calculateStock(catalogId, this.props.foreignKeyId)
                        .then(res => this.setState({...res.data})),
                    productsClient()
                        .calculatePrice(catalogId, this.props.foreignKeyId)
                        .then(res => this.setState({...res.data}))
                ])
                .then(() => {
                    if (this.state.quantity > 0) {
                        this.setState({selectedQuantity: 1});
                    }
                })
                .catch(err => this.setState({quantity: 0}))
                .then(() => this.setState({loading: false}));
        });
    };

    addToCart = () => {
        const item = product(Number(_.get(this.props, 'webstoreProduct.catalog_id', 0)), this.props.foreignKeyId);
        item.add(this.state.selectedQuantity)
            .then(() => item.settings(_.pick(this.props.webstoreProduct, ['pickup_in_store'])))
            .then(() => this.setState({selectedQuantity: 0}));
    }

    toggle = () => this.setState({isContactOpen: !this.state.isContactOpen});

    render() {
        const {purchasable} = this.props.webstoreProduct;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartActionsWrapper>
                        <div className={'pss-cart-actions'}>
                            {!this.props.gatewayConfig.disable_transactions && purchasable &&
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        return this.addToCart();
                                    }}
                                >
                                    <div className={cs('row', 'pss-closer-columns')}>
                                        <div className={cs('col-4')}>
                                            <div className={cs('row', 'no-gutters')}>
                                                <div className={cs('col-3', 'd-none', 'd-sm-block')}>
                                                    <button
                                                        type={'button'}
                                                        title={'Remove 1 Quantity'}
                                                        className={_.join(['pss-center-button', cs('btn', {'btn-primary': this.state.quantity > 0}, {'btn-secondary': this.state.quantity === 0}, 'btn-lg', 'w-100', 'rounded-left')], ' ')}
                                                        onClick={() => {
                                                            if (this.state.selectedQuantity > 0)
                                                                this.setState({selectedQuantity: this.state.selectedQuantity - 1})
                                                        }}
                                                        disabled={this.state.selectedQuantity === 1 || this.state.quantity === 0 || this.props.filteredProducts.length > 1}
                                                    >
                                                        -
                                                    </button>
                                                </div>

                                                <div className={cs('col')}>
                                                    <label
                                                        className={cs('sr-only')}
                                                        htmlFor={'pss-cart-quantity'}
                                                    >
                                                        Quantity
                                                    </label>
                                                    <input
                                                        type={'number'}
                                                        min={0}
                                                        max={this.state.quantity}
                                                        step={1}
                                                        className={cs('form-control', 'form-control-lg', 'text-center', 'w-100')}
                                                        placeholder={'Qty'}
                                                        id={'pss-cart-quantity'}
                                                        value={this.state.selectedQuantity}
                                                        onChange={e => this.setState({selectedQuantity: Number(e.currentTarget.value)})}
                                                        disabled={this.state.quantity === 0 || this.props.filteredProducts.length > 1}
                                                    />
                                                </div>

                                                <div className={cs('col-3', 'd-none', 'd-sm-block')}>
                                                    <button
                                                        type={'button'}
                                                        title={'Add 1 Quantity'}
                                                        className={_.join(['pss-center-button', cs('btn', {'btn-primary': this.state.quantity > 0}, {'btn-secondary': this.state.quantity === 0}, 'btn-lg', 'w-100', 'rounded-right')], ' ')}
                                                        onClick={() => {
                                                            if (this.state.selectedQuantity < this.state.quantity)
                                                                this.setState({selectedQuantity: this.state.selectedQuantity + 1})
                                                        }}
                                                        disabled={this.state.selectedQuantity === this.state.quantity || this.state.quantity === 0 || this.props.filteredProducts.length > 1}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={cs('col-8')}>
                                            <button
                                                className={cs('btn', {'btn-primary': this.state.quantity > 0}, {'btn-secondary': this.state.quantity === 0}, 'btn-lg', 'w-100', 'h-100', 'rounded')}
                                                disabled={!this.props.foreignKeyId || this.state.quantity === 0 || this.state.loading || this.state.selectedQuantity === 0 || this.props.filteredProducts.length > 1 || this.state.salePrice === 0}
                                                type={'submit'}
                                                id={'pss-add-to-cart'}
                                            >
                                                Add To Cart
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            }

                            <div className={cs('row', 'my-3', 'pss-closer-columns')}>
                                <div className={cs('col-' + (this.props.gatewayConfig.customer_service_telephone ? '6' : '12'))}>
                                    <button
                                        className={_.join(['pss-center-button', cs('btn', {'btn-outline-secondary': this.state.quantity > 0}, {'btn-primary': this.state.quantity === 0}, 'w-100', 'btn-lg', 'rounded')], ' ')}
                                        type={'button'}
                                        onClick={this.toggle}
                                        id={'pss-open-lead'}
                                    >
                                        <Icon icon={(this.state.quantity === 0 ? 'envelope' : ['far', 'envelope'])} size={'lg'} padding={0} />

                                        <span className={cs('pl-3')}>
                                            {(this.state.quantity > 0 || this.props.filteredProducts.length > 1) && 'Contact Us'}
                                            {this.state.quantity === 0 && this.props.filteredProducts.length === 1 && 'Notify Me'}
                                        </span>
                                    </button>
                                </div>

                                {this.props.gatewayConfig.customer_service_telephone &&
                                    <div className={cs('col-6')}>
                                        <a
                                            href={`tel:${this.props.gatewayConfig.customer_service_telephone}`}
                                            className={_.join(['pss-center-button', cs('btn', 'btn-outline-secondary', 'w-100', 'btn-lg', 'rounded')], ' ')}
                                            id={'pss-click-to-call'}
                                        >
                                            <Icon icon={'phone'} size={'lg'} className={'fa-flip-horizontal'} padding={0} />

                                            <span className={cs('pl-3')}>Call Us</span>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>

                        <Modal
                            isOpen={this.state.isContactOpen}
                            toggle={this.toggle}
                            cssModule={MODAL}
                            fade={false}
                            wrapClassName={cs('pss')}
                        >
                            <ModalBody cssModule={MODAL}>
                                <div className={cs('container-fluid')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col-12')}>
                                            <Formik
                                                initialValues={{
                                                    name: '',
                                                    phone: '',
                                                    email: '',
                                                    message: '',
                                                    quantity: this.state.selectedQuantity,
                                                    WebstoreProduct: {id: _.get(this.props, 'webstoreProduct.id', 0)},
                                                    Product: this.props.filteredProducts.length === 1 ? _.get(this.props, 'filteredProducts[0]', {}) || {} : {},
                                                    ProductGroup: _.get(this.props, 'productGroup', {}) || {}
                                                }}
                                                onSubmit={values => leads.submitLead(values).then(this.toggle)}
                                            >
                                                {({values, isSubmitting, handleChange}) => (
                                                    <Form>
                                                        <div className={cs('form-group')}>
                                                            <label htmlFor={'name'}>Name</label>
                                                            <input
                                                                type={'text'}
                                                                name={'name'}
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                id={'name'}
                                                                className={cs('form-control')}
                                                                required={true}
                                                            />
                                                        </div>

                                                        <div className={cs('form-group')}>
                                                            <label htmlFor={'phone'}>Phone</label>
                                                            <input
                                                                type={'text'}
                                                                name={'phone'}
                                                                value={values.phone}
                                                                onChange={handleChange}
                                                                id={'phone'}
                                                                className={cs('form-control')}
                                                            />
                                                        </div>

                                                        <div className={cs('form-group')}>
                                                            <label htmlFor={'email'}>Email</label>
                                                            <input
                                                                type={'email'}
                                                                name={'email'}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                id={'email'}
                                                                className={cs('form-control')}
                                                                required={true}
                                                            />
                                                        </div>

                                                        <div className={cs('form-group')}>
                                                            <label htmlFor={'message'}>Message</label>
                                                            <textarea
                                                                name={'message'}
                                                                value={values.message}
                                                                onChange={handleChange}
                                                                id={'message'}
                                                                className={cs('form-control')}
                                                            />
                                                        </div>

                                                        <div className={cs('row')}>
                                                            <div className={cs('col', 'text-right')}>
                                                                <button
                                                                    type={'button'}
                                                                    className={cs('btn', 'btn-secondary')}
                                                                    onClick={this.toggle}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                {' '}
                                                                <button
                                                                    type={'submit'}
                                                                    className={cs('btn', 'btn-primary')}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {!isSubmitting && 'Submit'}
                                                                    {isSubmitting && 'Please Wait'}
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </CartActionsWrapper>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default withCart(CartActionsRefactor);
