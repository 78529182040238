import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Button, Form} from 'reactstrap';
import _ from 'lodash';
import Filter from 'src/client.webstore/Views/Elements/Section';
import baseClient from 'src/client.webstore/api/base';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

export default class GlobalFitment extends React.Component {
    static propTypes = {
        webstoreCategoryId: PropTypes.number,
        stacked: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired
    };

    static defaultProps = {
        webstoreCategoryId: null,
        stacked: true
    };

    state = {
        loading: true,
        formState: {
            year: '',
            make: '',
            model: ''
        },
        results: {
            years: [],
            makes: [],
            models: [],
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.webstoreCategoryId !== this.props.webstoreCategoryId) {
            this.setState({
                formState: {
                    year: '',
                    make: '',
                    model: ''
                },
                loading: true
            }, this.search);
        }
    }

    componentDidMount() {
        this.search();
    }

    search = () => {
        this.setState({loading: true}, () => {
            baseClient()
                .get(
                    '/webstore/webstore_fitment/search',
                    {
                        ...this.state.formState,
                        webstoreCategoryId: this.props.webstoreCategoryId,
                        limit: 0
                    }
                )
                .then(res => this.setState({results: res.data, loading: false}));
        });
    };

    render() {
        let {years, makes, models} = this.state.results;

        if (years.length === 0) {
            return null;
        }

        let values = this.state.formState;

        return (
            <ThemeContext.Consumer>
                {({cs, styles}) => (
                    <Filter filterTitle={'Shop By Fitment'}>
                        <div className={cs('row')}>
                            <div className={cs('col')}>
                                <Form
                                    cssModule={styles}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        this.props.onSubmit(this.state.formState);
                                    }}
                                    onReset={() => {
                                        this.setState({
                                            formState: {
                                                year: '',
                                                make: '',
                                                model: ''
                                            }
                                        }, () => {
                                            this.search();
                                            this.props.onReset();
                                        });
                                    }}
                                >
                                    <FormGroup cssModule={styles}>
                                        <select
                                            className={cs('custom-select', 'w-100')}
                                            disabled={this.state.loading}
                                            name={'year'}
                                            onChange={e => this.setState({
                                                formState: {
                                                    year: e.currentTarget.value,
                                                    make: '',
                                                    model: ''
                                                }
                                            }, this.search)}
                                            value={values.year || ''}
                                        >
                                            <option value={''}>Year</option>
                                            {_.chain(years.sort()).reverse().value().map(v => <option key={v} value={v}>{v}</option>)}
                                        </select>
                                    </FormGroup>

                                    <FormGroup cssModule={styles}>
                                        <select
                                            className={cs('custom-select', 'w-100')}
                                            disabled={this.state.loading || values.year === ''}
                                            name={'make'}
                                            onChange={e => this.setState({
                                                formState: {
                                                    ...this.state.formState,
                                                    make: e.currentTarget.value,
                                                    model: ''
                                                }
                                            }, this.search)}
                                            value={values.make || ''}
                                        >
                                            <option value={''}>Make</option>
                                            {makes.sort().map(v => <option key={v} value={v}>{v}</option>)}
                                        </select>
                                    </FormGroup>

                                    <FormGroup cssModule={styles}>
                                        <select
                                            className={cs('custom-select', 'w-100')}
                                            disabled={this.state.loading || values.make === ''}
                                            name={'model'}
                                            onChange={e => this.setState({
                                                formState: {
                                                    ...this.state.formState,
                                                    model: e.currentTarget.value
                                                }
                                            }, this.search)}
                                            value={values.model || ''}
                                        >
                                            <option value={''}>Model</option>
                                            {models.sort().map(v => <option key={v} value={v}>{v}</option>)}
                                        </select>
                                    </FormGroup>

                                    <div className={cs('row')}>
                                        <div className={cs('col')}>
                                            <Button cssModule={styles} color={'secondary'} size={'lg'} type={'reset'} className={cs('w-100')}>
                                                Reset
                                            </Button>
                                        </div>
                                        <div className={cs('col')}>
                                            <Button cssModule={styles} color={'primary'} size={'lg'} type={'submit'} className={cs('w-100')}>
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Filter>
                )}
            </ThemeContext.Consumer>
        );
    }
}
