import React from 'react';
import {Link} from 'react-router-dom';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

const ButtonWrapper = (props) => (
    <ThemeContext.Consumer>
        {({cs}) => (
            <div className={cs('row', 'my-3')}>
                <div className={cs('col', 'text-center')}>
                    {props.children}
                </div>
            </div>
        )}
    </ThemeContext.Consumer>
);


export default class PaymentButtons extends React.Component {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    render() {
        if (this.state.hasError || this.props.loading) {
            return null;
        }

        let {spike_pay, stripe, paypal_rest, paypal_soap, authorize, mercury_pay} = this.props.gatewayConfig;

        let hasButtons = paypal_rest;

        let hasCC = spike_pay || stripe || paypal_soap || authorize || mercury_pay;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <React.Fragment>
                        {(hasCC || hasButtons) &&
                        <ButtonWrapper>
                            <Link
                                to={'/cart/billing'}
                                className={cs('btn', 'btn-lg', 'btn-primary', 'text-uppercase', 'w-100')}
                                id={'pss-proceed-to-checkout'}
                            >
                                Proceed To Checkout
                            </Link>
                        </ButtonWrapper>
                        }
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
