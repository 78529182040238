import React from 'react';
import {StyleProvider, ThemeContext} from 'src/client.webstore/Context/StyleProvider';

const NameValue = (props) => (
    <ThemeContext.Consumer>
        {({cs}) => (
            <div className={cs('row')}>
                <div className={cs('col')}>
                    <p className={cs('lead', props.nameClass)}>{props.name}</p>
                </div>

                <div className={cs('col', 'text-right')}>
                    {React.isValidElement(props.value) && props.value}
                    {!React.isValidElement(props.value) && <p className={cs('lead', props.valueClass)}>{props.value}</p>}
                </div>
            </div>
        )}
    </ThemeContext.Consumer>
);

export default NameValue;
