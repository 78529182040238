import {hot} from 'react-hot-loader/root';
import React from 'react';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import PropTypes from 'prop-types';
import {cart} from 'src/client.webstore/utils/cart';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import styled from 'styled-components';
import baseClient from 'src/client.webstore/api/base';
import _ from 'lodash';

const Container = styled.div`
 a {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  font-size: 3rem;
  position: fixed;
  right: 20px;
  bottom: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  background: ${props => props.primary};
  color: white;
  z-index: 1000;
 }
`;

class CartIcon extends React.Component {
    static propTypes = {
        hashRouter: PropTypes.bool
    }

    static defaultProps = {
        hashRouter: false
    }

    interval;

    state = {
        cartItems: [],
        loading: true,
        config: {}
    };

    componentDidMount() {
        this.interval = setInterval(() => cart.get().then(cartItems => this.setState({cartItems})), 500);
        baseClient()
            .getConfig()
            .then(res => this.setState({config: res.data, loading: false}));
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.state.cartItems.length === 0 || this.state.loading || _.isEmpty(this.state.config.store_url)) {
            return null;
        }


        return (
            <ThemeContext.Consumer>
                {({vars, cs}) => (
                    <Container {...vars}>
                        <a href={`${this.state.config.store_url}${this.props.hashRouter ? '#' : ''}/cart`} className={cs('stretched-link')}>
                            <span style={{marginRight: '4px'}}><Icon icon={'shopping-cart'} padding={0}/></span>
                            {this.state.cartItems.length}

                        </a>
                    </Container>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default hot(CartIcon);