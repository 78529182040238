import React from 'react';
import {BrowserRouter, HashRouter, Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import Landing from 'src/client.webstore/Views/Landing/view';
import Categories from 'src/client.webstore/Views/Categories';
import ViewProduct from 'src/client.webstore/Views/Products/view';
import ViewProductRefactor from 'src/client.webstore/Views/Products/viewRefactor';
import CartRouter from 'src/client.webstore/Views/Cart/cartRouter';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {withCart} from 'src/client.webstore/Views/Cart/cartRouter';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import _ from 'lodash';
import {ConfigContext} from 'src/client.webstore/Context/ConfigProvider';

import {ToggleClassFlagProvider} from 'src/toggles/ToggleClassFlagProvider';

class WebstoreRouter extends React.Component {
    static propTypes = {
        redirectHash: PropTypes.bool,
    };

    static defaultProps = {
        redirectHash: false,
    };

    state = {
        hasMounted: false,
    };

    componentDidMount() {
        // This needs to be kept in place to redirect old links from the HashRouter to the BrowserRouter
        if (this.props.redirectHash && this.props.location.hash) {
            this.props.history.replace(this.props.location.hash.replace('#', ''));
        }

        this.setState({hasMounted: true});
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            let breadcrumbs = document.getElementById('pss-breadcrumbs');
            if (breadcrumbs) {
                breadcrumbs.scrollIntoView();
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.hasMounted && (this.props.location !== nextProps.location)) {
            console.warn('Triggering window.stop()');
            window.stop();
        }

        return true;
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('pss', 'pss-webstore-component')} id={'pss-main'}>
                        <CartContext.Consumer>
                            {(context) => {
                                const comingSoon = _.get(context, 'gatewayConfig.coming_soon', false);
                                const currency = _.get(context, 'gatewayConfig.currency', 'USD');
                                const activityTypes = _.get(context, 'gatewayConfig.activity_types', []);
                                const disableLandingPage = _.get(context, 'gatewayConfig.disable_landing_page', false);

                                return (
                                    <div className={cs('container-fluid')}>
                                        {comingSoon &&
                                        <div className={cs('col-12', 'text-center')} id={'pss-coming-soon'}>
                                            <h1>Coming Soon</h1>
                                            <p>This store is currently under construction.</p>
                                        </div>
                                        }

                                        {!comingSoon &&
                                        <div className={cs('row', 'my-3')}>
                                            <div className={cs('col')}>
                                                <Switch>
                                                    <Route
                                                        path={'/cart'}
                                                        render={props => <CartRouter {...props}/>}
                                                    />
                                                    <Route
                                                        path={'/products/view/:id'}
                                                        render={props => (
                                                            <ToggleClassFlagProvider
                                                                flagName={'pdp_refactor'}
                                                                render={({isEnabled}) => {
                                                                    if (isEnabled()) {
                                                                        return <ViewProductRefactor {...props} key={props.match.params.id}/>
                                                                    }
                                                                    return <ViewProduct {...props} key={props.match.params.id}/>
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path={'/categories'}
                                                        render={props => <Categories {...props} currency={currency} activityTypes={activityTypes}/>}
                                                    />
                                                    <Route
                                                        path={'/'}
                                                        exact={true}
                                                        render={props => {
                                                            if (!disableLandingPage) {
                                                                return <Landing />
                                                            } else {
                                                                return <Redirect from={'/'} to={'/categories'}/>
                                                            }
                                                        }}
                                                    />
                                                    <Route>
                                                        <h1>Uh Oh!</h1>
                                                    </Route>
                                                </Switch>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                );
                            }}
                        </CartContext.Consumer>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

WebstoreRouter = withCart(WebstoreRouter);

export default class HashedWebstore extends React.Component {
    render() {
        return (
            <ConfigContext.Consumer>
                {(context) => {

                    // We have to change our basename depending on the release stage
                    let basename;
                    switch (VERSION) {
                        case 'develop':
                            basename = '/webstore';
                            break;
                        case 'staging':
                            basename = '/webstore/demo'; // See webpack.webstore.js:45
                            break;
                        default:
                            basename = context.cart.store_url || '/shop'; // PSS hosted sites don't set this
                    }

                    console.info('basename set to', basename, 'for', VERSION);

                    return (
                        <React.Fragment>
                            {this.props.hashRouter &&
                            <HashRouter>
                                <Route render={props => <WebstoreRouter {..._.merge(props, this.props)}/>}/>
                            </HashRouter>
                            }

                            {!this.props.hashRouter &&
                            <BrowserRouter basename={basename}>
                                <Route render={props => <WebstoreRouter {...props} redirectHash={true}/>}/>
                            </BrowserRouter>
                            }
                        </React.Fragment>
                    );
                }}
            </ConfigContext.Consumer>
        );
    }
}
