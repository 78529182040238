import ReactGA from 'react-ga';
import baseClient from 'src/client.webstore/api/base';
import _ from 'lodash';
import productsClient from 'src/client.webstore/api/product';

class Analytics {
    trackers = [];

    constructor() {
        this.getGoogleAnalytics = _.once(this.getGoogleAnalytics);
    }

    getGoogleAnalytics = () => {
        this.trackers.push({
            trackingId: process.env.NODE_ENV === 'production' ? 'UA-40544695-4' : 'UA-40544695-6',
            gaOptions: {
                name: 'PSS'
            }
        });

        return baseClient().get('/webstore/webstore_app/config')
            .then(res => {
                if (res.data.trackingId) {
                    this.trackers.unshift({
                        trackingId: res.data.trackingId,
                        gaOptions: {
                            name: 'viaConfig'
                        }
                    });
                }

                ReactGA.initialize(this.trackers, {
                    debug: false,
                    alwaysSendToDefaultTracker: false
                });

                return this.trackers;
            });

    }

    click = (clickData = {}) => {
        if (typeof window.utag !== 'undefined' && typeof window.utag_data === 'object') {
            window.utag.link({
                ...window.utag_data,
                ...clickData
            });
        }
    }

    view = (viewData = {}) => {
        if (typeof window.utag !== 'undefined' && typeof window.utag_data === 'object') {
            window.utag.view({
                ...window.utag_data,
                tealium_event: 'page_view',
                page_title: window.document.title,
                ...viewData
            })
        }

        this.getGoogleAnalytics().then(trackers => {
            let location = `${window.location.pathname}${window.location.hash}`;
            let host = _.replace(window.location.hostname, 'www.', '');

            ReactGA.pageview(location, trackers.filter(v => v.gaOptions.name !== 'PSS').map(v => v.gaOptions.name));
            ReactGA.pageview(`${host}${location}`, ['PSS']);
        });

        return Promise.resolve();
    }

    action = (actionData = {}) => {
        if (typeof window.utag !== 'undefined' && typeof window.utag_data === 'object') {
            window.utag.link({
                ...window.utag_data,
                ...actionData
            });
        }
    }

    convert = assignedOrderId => new Promise((resolve, reject) => {
        this.getGoogleAnalytics().then(trackers => {
            trackers.forEach(tracker => window.ga(`${tracker.gaOptions.name}.require`, 'ecommerce'));

            baseClient()
                .get(`/webstore/webstore_cart/get/${assignedOrderId}`)
                .then(res => {
                    trackers.forEach(tracker => window.ga(`${tracker.gaOptions.name}.ecommerce:addTransaction`, {
                        id: assignedOrderId,
                        revenue: res.data.order.order_total,
                        tax: res.data.order.tax_total,
                        shipping: res.data.order.shipping_total
                    }));

                    return res;
                })
                .then(res => Promise.all(res.data.orderItems.map(orderItem => new Promise((resolve, reject) => {
                    productsClient()
                        .getVariation(orderItem.catalog_id, orderItem.foreign_key_id)
                        .then(res => resolve({
                            ...orderItem,
                            ...res.data
                        }));
                }))))
                .then(orderItems => trackers.forEach(tracker => orderItems.forEach(orderItem => window.ga(`${tracker.gaOptions.name}.ecommerce:addItem`, {
                    id: assignedOrderId,
                    name: orderItem.ProductGroup.name,
                    sku: orderItem.manufacturer_part_number,
                    quantity: orderItem.qty
                }))))
                .then(() => trackers.forEach(tracker => window.ga(`${tracker.gaOptions.name}.ecommerce:send`)))
        })
    });
}

let Instance;
export default function () {
    if (typeof Instance === 'undefined') {
        Instance = new Analytics();
    }

    return Instance;
}