import React, {useContext} from 'react';
import {
    useFlag,
    useUnleashClient,
    useUnleashContext,
    useVariant,
    useFlagsStatus,
    FlagContext
} from '@unleash/proxy-client-react';

// In order to use the Unleash SDK (setup for functional components with hooks) from within class components, we must use a wrapper, e.g.
// <ToggleCLassFlagProvider flagName="flagname" render={({isEnabled, getClient}) => (<SomeClassComponent isEnabled={isEnabled} getClient={getClient} />)} />
export const ToggleClassFlagProvider = ({
                                             render,
                                             flagName
                                         }) => {
    // Replacing the enabled constant with a context check against it directly from React instead
    // as proposed as a work around to the bug @ https://github.com/Unleash/unleash-proxy-client-js/issues/152
    // @note this fix (for PSSD-1798) is reverted since it causes toggles in the Dealer UI to not refresh after context (e.g. hostname) is provided - PSSD-1878
    const enabled = useFlag(flagName);
    // const {isEnabled: checkEnabled} = useContext(FlagContext);
    // const enabled = checkEnabled(flagName);

    const variant = useVariant(flagName);
    const client = useUnleashClient();

    const updateContext = useUnleashContext();
    const { flagsReady, flagsError } = useFlagsStatus();

    const isEnabled = () => {
        return enabled;
    };

    const getVariant = () => {
        return variant;
    };

    const getClient = () => {
        return client;
    };

    const getUnleashContextSetter = () => {
        return updateContext;
    };

    const getFlagsStatus = () => {
        return { flagsReady, flagsError };
    };

    /**
     * Hacky way to determine whether any of multiple flags are enabled
     * Satisfies an "OR" condition
     *
     * @param flagNames An array of flag names to check
     * @returns {boolean} True if any of the flags are enabled, or false if all are disabled
     */
    const anyEnabled = (flagNames) => {
        // Must check all "useFlag" hooks or encounter "change in the order of Hooks" error in React
        // i.e. don't return in loop
        let enabled = false;
        for (let i = 0; i < flagNames.length; i++) {
            if (useFlag(flagNames[i])) {
                enabled = true;
            }
        }

        return enabled;
    }

    return (
        <>
            {render({
                isEnabled,
                getVariant,
                getClient,
                getUnleashContextSetter,
                getFlagsStatus,
                anyEnabled
            })}
        </>
    );
};
