import React from 'react';
import {cart} from 'src/client.webstore/utils/cart';
import {Link, Redirect} from 'react-router-dom';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import analytics from 'src/client.webstore/utils/analytics';
import {ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';

const Analytics = analytics();

/**
 * Lists shipping methods
 */
export default class ShippingMethods extends React.Component {
    static contextType = CartContext;

    state = {
        loading: true,
        disableSubmit: false,
        rates: [],
        shippingMethod: null,
        redirectTo: null
    };

    componentDidMount() {
        Promise.all([
            cart.shippingRates().then(rates => this.setState({rates})),
            cart.getShippingMethod().then(method => {
                if (!_.isNull(method)) {
                    // The shipping method must be re-selected each time shipping rates are retrieved because they will represent a different shipment package
                    if (!_.isNull(_.get(method, 'reference_id')) && !_.isNull(_.get(method, 'shipment_package_id'))) {
                        cart.setShippingMethod(null);
                    }
                }
            }),
        ]).then(() => this.setState({loading: false}, () => Analytics.view({
            ecomm_part_list_ids: this.context.cartItems.map(v => `${v.foreignKeyId}`),
            ecomm_part_list_quantities: this.context.cartItems.map(v => `${v.quantity}`),
            ecomm_cart_event: 'select_shipping_method',
            ecomm_cart_subtotal: `${this.context.subtotal}`,
            ecomm_cart_shipping: `${this.context.shipping}`,
            ecomm_cart_tax: `${this.context.tax}`,
            ecomm_cart_grand_total: `${this.context.total}`,
            tealium_event: 'ecommerce_part_cart_action'
        })));
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push={true}/>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs, styles}) => (
                    <CartContext.Consumer>
                        {context => (
                            <React.Fragment>
                                {context.gatewayConfig &&
                                <div className={cs('row', 'my-5')}>
                                    <div className={cs('col')}>
                                        <h2 className={cs('mb-3')}>Shipping Methods</h2>

                                        {this.state.loading &&
                                        <Spinner />
                                        }

                                        {!this.state.loading &&
                                        <Formik
                                            isInitialValid={!_.isNull(this.state.shippingMethod)}
                                            initialValues={{shippingMethod: this.state.shippingMethod}}
                                            onSubmit={values => {
                                                // Shipping method already set, just redirect
                                                this.setState({redirectTo: '/cart/payment'});
                                            }}
                                            validate={values => {
                                                let errors = {};

                                                if (_.isNull(this.state.shippingMethod)) {
                                                    _.set(errors, 'shippingMethod', 'Please select a shipping method.');
                                                }

                                                return errors;
                                            }}
                                        >
                                            {bag => {
                                                return (
                                                    <Form>
                                                        {_.isEmpty(this.state.rates) &&
                                                        <div className={cs('alert', 'alert-warning', 'text-center')} role={'alert'}>
                                                            <span>
                                                                No shipping methods are available.
                                                            </span>
                                                        </div>
                                                        }

                                                        {!_.isEmpty(this.state.rates) &&
                                                        <div className={cs('pss')} style={{paddingBottom: '20px'}}>
                                                            <p>Please select one of the following shipping methods:</p>

                                                            <ListGroup cssModule={styles}>
                                                                {_.map(this.state.rates, rate => {
                                                                    let method = _.pick(rate, ['reference_id', 'shipment_package_id']);
                                                                    let deliveryDays = _.get(rate, 'delivery_days');
                                                                    let name = _.get(rate, 'name', '');
                                                                    let formattedPrice = context.currency(_.get(rate, 'price', 0)).format(true);
                                                                    let active = (method.reference_id === _.get(this.state.shippingMethod, 'reference_id', ''))
                                                                        && (method.shipment_package_id === _.get(this.state.shippingMethod, 'shipment_package_id', ''));

                                                                    return (
                                                                        <ListGroupItem
                                                                            key={_.isNull(method.reference_id) ? 'flat' : method.reference_id}
                                                                            cssModule={styles}
                                                                            active={active}
                                                                            tag={'button'}
                                                                            type={'button'}
                                                                            title={`${name} | ${formattedPrice}`}
                                                                            disabled={this.state.disableSubmit}
                                                                            onClick={() => {
                                                                                // Nothing to do if this shipping method is already set
                                                                                if (active) {
                                                                                    return;
                                                                                }

                                                                                // Update the shipping method selected, but do not allow submission
                                                                                // until the context calculations have completed
                                                                                this.setState(
                                                                                    {
                                                                                        shippingMethod: method,
                                                                                        disableSubmit: true
                                                                                    },
                                                                                    () => {
                                                                                        cart.setShippingMethod(this.state.shippingMethod);
                                                                                        context.calculate().then(() => this.setState({disableSubmit: false}));
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div className={cs('row')}>
                                                                                {active &&
                                                                                <div className={cs('col-1', 'align-self-center')} style={{padding: 0}}>
                                                                                    <Icon icon={'check'}/>
                                                                                </div>
                                                                                }
                                                                                <div className={cs('col', 'text-left')}>
                                                                                    <ListGroupItemHeading style={{marginBottom: 0}}>
                                                                                        {name}
                                                                                    </ListGroupItemHeading>

                                                                                    {!_.isNull(deliveryDays) &&
                                                                                    <ListGroupItemText style={{marginBottom: 0}}>
                                                                                        {`est. ${deliveryDays} business day${_.parseInt(deliveryDays) === 1 ? '' : 's'}`}
                                                                                    </ListGroupItemText>
                                                                                    }
                                                                                </div>
                                                                                <div className={cs('col-3', 'align-self-center', 'text-right')}>
                                                                                    <ListGroupItemHeading style={{marginBottom: 0}}>
                                                                                        {formattedPrice}
                                                                                    </ListGroupItemHeading>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    );
                                                                })}
                                                            </ListGroup>
                                                        </div>
                                                        }

                                                        <div className={cs('row')}>
                                                            <div className={cs('col')}>
                                                                <Link
                                                                    to={'/cart/shipping'}
                                                                    className={cs('btn', 'btn-outline-secondary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    id={'pss-back-to-shipping'}
                                                                >
                                                                    Back To Shipping
                                                                </Link>
                                                            </div>
                                                            <div className={cs('col', 'text-right')}>
                                                                <button
                                                                    type={'submit'}
                                                                    className={cs('btn', 'btn-primary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    disabled={_.isNull(this.state.shippingMethod) || this.state.disableSubmit}
                                                                    id={'pss-continue-from-shipping-methods'}
                                                                >
                                                                    Continue To Payment
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                        }
                                    </div>
                                </div>
                                }
                            </React.Fragment>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}
