import React from 'react';
import PropTypes from 'prop-types';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import baseClient from 'src/client.webstore/api/base';
import CustomScrollbar from 'src/client.webstore/Views/Elements/CustomScrollbar';
import FixedLabel from 'src/client.webstore/Views/Elements/FixedLabel';
import _ from 'lodash';
import {withCart} from 'src/client.webstore/Views/Cart/cartRouter';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';

class FeatureGroups extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        showTitle: PropTypes.bool,
        hashRouter: PropTypes.bool
    }

    static defaultProps = {
        showTitle: true,
        hashRouter: false
    }

    state = {
        loading: true,
        error: false,
        WebstoreFeatureGroup: {},
        WebstoreFeatureGroupsWebstoreProduct: [],
        products: [],
        config: {}
    }

    componentDidMount() {
        Promise
            .all([
                baseClient().getConfig()
                    .then(res => this.setState({config: res.data})),
                baseClient()
                    .get(`/webstore/webstore_feature_groups/get`, {feature_group : this.props.name})
                    .then(res => {
                        this.setState({...res.data});
                        return res.data;
                    })
                    .then(data => baseClient()
                        .post('/categories', {start: 0, length: 48}, {WebstoreProduct: {id: data.WebstoreFeatureGroupsWebstoreProduct.map(v => v.webstore_product_id)}})
                        .then(res => this.setState({products: res.data.products})))

            ])
            .then(() => this.setState({loading: false}))
            .catch(() => this.setState({loading: false, error: true}));
    }

    render() {
        if (this.state.error || this.state.loading) {
            return null;
        }

        return (
            <ThemeContext.Consumer>
                {({cs, vars}) => (
                    <div className={cs('pss', 'pss-feature-group-component')} style={{overflowX: 'hidden'}}>
                        <div className={cs('container-fluid', 'my-5')}>
                            <div className={cs('row')}>
                                {this.props.showTitle &&
                                <div className={cs('col-12', 'text-center', 'mb-3')}>
                                    <h2 className={cs('text-primary')}>
                                        {this.props.name}
                                    </h2>
                                </div>
                                }

                                {!this.state.loading && (this.state.WebstoreFeatureGroupsWebstoreProduct.length === 0 || this.state.products.length === 0) &&
                                <div className={cs('col-12', 'text-center', 'my-5')}>
                                    <p className={cs('lead', 'font-weight-bold')}>
                                        {this.props.name} Feature Group does not seem to have any products currently associated with it.
                                    </p>
                                </div>
                                }
                            </div>

                            {_.isEmpty(this.state.config.store_url) &&
                            <div className={cs('row')}>
                                <div className={cs('col-12', 'text-center')}>
                                    <div className={cs('alert', 'alert-warning')}>
                                        Please configure the "Store URL" in your dashboard settings at <strong>Settings > Webstore</strong>, under
                                        the <strong>Category & Search Results Pages</strong> section.
                                    </div>
                                </div>
                            </div>
                            }

                            {!_.isEmpty(this.state.config.store_url) && this.state.WebstoreFeatureGroupsWebstoreProduct.length > 0 && this.state.products.length > 0 &&
                            <div className={cs('row')}>
                                <div className={cs('col-12')}>
                                    <CustomScrollbar {...vars}>
                                        <div className={cs('row', 'flex-nowrap', 'overflow-auto', 'pb-3', 'pt-2')} style={{maxWidth: '100%'}}>
                                            {!this.state.loading && this.state.products.map(v => (
                                                <div key={v.WebstoreProduct.id} className={cs('col-6', 'col-md-3', 'd-flex', 'flex-column', 'text-center')}>
                                                    {v.WebstoreProduct.on_sale &&
                                                    <FixedLabel label={'ON SALE'} side={'left'} className={cs('bg-danger', 'text-white', 'p-2', 'shadow')} />
                                                    }

                                                    <div className={cs('my-auto')}>
                                                        <img
                                                            src={v.WebstoreProduct.image_url || baseClient().resolveUrl(`/webstore/webstore_images/resize_hero/${v.WebstoreProduct.id}/250/250`)}
                                                            alt={`${v.ProductGroup.ManufacturerGroup.name} ${v.ProductGroup.name}`}
                                                            className={cs('img-fluid', 'my-auto')}
                                                        />
                                                    </div>

                                                    <p className={cs('small', 'text-muted', 'mt-auto')}>
                                                        {v.ProductGroup.ManufacturerGroup.name}
                                                    </p>
                                                    <p className={cs('font-weight-bold', 'text-upper-cate')}>
                                                        <a
                                                            href={`${this.state.config.store_url}${this.props.hashRouter ? '#' : ''}/products/view/${v.WebstoreProduct.id}`}
                                                            className={cs('stretched-link')}
                                                        >
                                                            {v.ProductGroup.name}
                                                        </a>
                                                    </p>

                                                    {v.WebstoreProduct.min_price > 0 &&
                                                    <p className={cs('small', 'font-weight-bold')}>
                                                        <CartContext.Consumer>
                                                            {({currency, gatewayConfig}) => {
                                                                if (gatewayConfig.disable_pricing) {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <React.Fragment>
                                                                        From {currency(v.WebstoreProduct.min_price).format(true)}
                                                                    </React.Fragment>
                                                                );
                                                            }}
                                                        </CartContext.Consumer>
                                                    </p>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </CustomScrollbar>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}

export default withCart(FeatureGroups);
