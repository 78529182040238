import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Card, CardFooter, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CustomScrollbar from 'src/client.webstore/Views/Elements/CustomScrollbar';
import invert from 'invert-color';

const Invert = props => {

    try {
        return <span style={{color: invert(props.primary, true)}}>{props.filterTitle}</span>;
    } catch (e) {
        return <span>{props.filterTitle}</span>;
    }
}

export default class Section extends React.Component {
    static propTypes = {
        filterTitle: PropTypes.string.isRequired,
        filterLinks: PropTypes.arrayOf(PropTypes.element),
        chunkBoundary: PropTypes.number,
        highlight: PropTypes.bool,
        footer: PropTypes.element,
        showSearch: PropTypes.bool
    };

    static defaultProps = {
        chunkBoundary: 5,
        filterLinks: [],
        highlight: false,
        showSearch: false
    };

    state = {
        open: false
    };

    toggle = () => this.setState({open: !this.state.open});

    render() {
        let visibleFilters = _.slice(this.props.filterLinks, 0, this.props.chunkBoundary);
        let hiddenFilters = _.slice(this.props.filterLinks, this.props.chunkBoundary, this.props.filterLinks.length);

        if (visibleFilters.length === 0 && !this.props.children) {
            return null;
        }

        return (
            <ThemeContext.Consumer>
                {({cs, styles: BS, vars}) => (
                    <Card
                        cssModule={BS}
                        className={cs('rounded-0', 'mb-4', {'border-primary': this.props.highlight}, 'pss-section')}
                        id={`pss-section-${_.snakeCase(_.lowerCase(this.props.filterTitle))}`}
                    >
                        <CardHeader cssModule={BS} className={cs('font-weight-bold', 'filter-title', {'bg-primary': this.props.highlight})}>
                            {!this.props.highlight && this.props.filterTitle}
                            {this.props.highlight && <Invert primary filterTitle={this.props.filterTitle}/>}
                        </CardHeader>

                        {visibleFilters.length > 0 &&
                        <React.Fragment>
                            <CustomScrollbar {...vars}>
                                <ListGroup cssModule={BS} className={cs('list-group-flush')} style={{maxHeight: this.state.open ? '250px' : null, overflow: 'auto'}}>
                                    {visibleFilters.map((v, k) => (
                                        <ListGroupItem cssModule={BS} key={k}>
                                            {v}
                                        </ListGroupItem>
                                    ))}

                                    {this.state.open && hiddenFilters.map((v, k) => (
                                        <ListGroupItem cssModule={BS} key={k}>
                                            {v}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </CustomScrollbar>

                            {this.props.footer &&
                            <CardFooter cssModule={BS}>
                                {this.props.footer}
                            </CardFooter>
                            }

                            {hiddenFilters.length > 0 &&
                            <CardFooter cssModule={BS}>
                                <button type={'button'} className={cs('btn', 'btn-link', 'p-0', 'm-0', 'font-weight-bold')} onClick={this.toggle}>
                                    {this.state.open ? 'View Less' : 'View More'}
                                </button>
                                {!this.state.open && <span className={cs('float-right')}>({hiddenFilters.length})</span>}
                            </CardFooter>
                            }
                        </React.Fragment>
                        }

                        {this.props.children &&
                        <div className={cs('p-2')}>
                            {this.props.children}
                        </div>
                        }
                    </Card>
                )}
            </ThemeContext.Consumer>
        );
    }
}
