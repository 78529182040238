import React, {useState, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import ViewCart from 'src/client.webstore/Views/Cart/view';
import {cart} from 'src/client.webstore/utils/cart';
import Billing from 'src/client.webstore/Views/Cart/billing';
import baseClient from 'src/client.webstore/api/base';
import Shipping from 'src/client.webstore/Views/Cart/shipping';
import ShippingMethods from 'src/client.webstore/Views/Cart/ShippingMethods';
import Payment from 'src/client.webstore/Views/Cart/Payment';
import NameValue from 'src/client.webstore/Views/Cart/Elements/NameValue';
import currency from 'currency.js';
import productsClient from 'src/client.webstore/api/product';
import {ConfigContext} from 'src/client.webstore/Context/ConfigProvider';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import Summary from './summary';
import Prop65 from 'src/client.webstore/Views/Elements/Prop65';
import _ from 'lodash';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';

const MiniProduct = (props) => {
    const [product, setProduct] = useState(false);
    useEffect(() => {
        if (!product) {
            productsClient().getVariation(props.catalogId, props.foreignKeyId).then(res => setProduct(res.data));
        }
    });

    if (!product) {
        return null;
    }

    return (
        <ThemeContext.Consumer>
            {({cs}) => (
                <div className={cs('row', 'mb-3')}>
                    <div className={cs('col-3', 'd-flex')}>
                        <img
                            src={productsClient().imageUrl(props.catalogId, props.foreignKeyId, 100, 100)}
                            alt={product.ProductGroup.name}
                            className={cs('img-fluid', 'align-self-center')}
                        />
                    </div>
                    <div className={cs('col-9')}>
                        <p>{product.ProductGroup.name}</p>
                        <p className={cs('small')}>Part #: {product.manufacturer_part_number}</p>
                        {product.size && <p className={cs('small')}>Size: {product.size}</p>}
                        {product.color && <p className={cs('small')}>Color: {product.color}</p>}
                        {product.option && <p className={cs('small')}>Option: {product.option}</p>}
                        {props.quantity && <p className={cs('small')}>Qty: {props.quantity}</p>}
                    </div>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};

class CartRouter extends React.Component {
    state = {
        billing: {},
        shipping: {},
        loading: true
    }

    componentDidMount() {
        this.hydrate()
            .then(() => this.setState({loading: false}));
    }

    hydrate = () => Promise.all([
        cart.getBilling().then(billing => this.setState({billing})),
        cart.getShipping().then(shipping => this.setState({shipping}))
    ]);

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.hydrate();
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        let {
            subtotal = null,
            shipping = null,
            discount = null,
            tax = null,
            total = null
        } = this.props;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row')}>
                        <div className={cs('col')}>
                            <Switch>
                                <Route
                                    exact={true}
                                    path={'/cart'}
                                    render={props => <ViewCart {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path={'/cart/summary/:assignedOrderId'}
                                    render={props => <Summary {...props}/>}
                                />
                                <Route>
                                    <div className={cs('row', 'py-2')}>
                                        <div className={cs('col-md-4', 'order-md-last', 'mb-3')}>
                                            <div className={cs('bg-white', 'border', 'shadow-sm', 'p-3')}>
                                                {this.props.cartItems.map((v, k) => <MiniProduct key={k} {...v}/>)}

                                                <hr/>

                                                {subtotal !== null && <NameValue name={'Subtotal'} value={currency(subtotal).format(true)}/>}

                                                {shipping !== null && <NameValue name={'Shipping'} value={currency(shipping).format(true)}/>}

                                                {discount !== null && <NameValue name={'Discount'} value={currency(discount).format(true)} valueClass={cs('text-primary')}/>}

                                                {tax !== null && <NameValue name={'Tax'} value={currency(tax).format(true)}/>}

                                                {total !== null && <NameValue name={'Grand Total'} value={currency(total).format(true)} nameClass={'font-weight-bold'} valueClass={'font-weight-bold'}/>}
                                            </div>

                                            {(_.get(this.state, 'billing.state', null) === 'CA' || _.get(this.state, 'shipping.state') === 'CA') && <Prop65/>}
                                        </div>
                                        <div className={cs('col-md')}>
                                            <div className={cs('bg-white', 'border', 'shadow-sm', 'p-3')}>
                                                <Switch>
                                                    <Route
                                                        exact={true}
                                                        path={'/cart/billing'}
                                                        render={props => <Billing {...props}/>}
                                                    />

                                                    <Route
                                                        exact={true}
                                                        path={'/cart/shipping'}
                                                        render={props => <Shipping {...props}/>}
                                                    />

                                                    <Route
                                                        exact={true}
                                                        path={'/cart/methods'}
                                                        render={props => <ShippingMethods {...props}/>}
                                                    />

                                                    <Route
                                                        exact={true}
                                                        path={'/cart/payment'}
                                                        render={props => <Payment {...props}/>}
                                                    />
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export function withCart(WrappedComponent) {
    return class extends React.Component {
        state = {
            loading: true,
            cartItems: []
        }

        componentDidMount() {
            this.calculate()
                .then(() => this.setState({loading: false}));
        }

        componentWillUnmount() {
            // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
            this.setState = () => null;
        }

        calculate = () => Promise.all([
            cart.get().then(cartItems => this.setState({cartItems})),
            cart.calculate().then(totals => this.setState({...totals})),
            cart.estimateShipping().then(cost => this.setState({estimatedShipping: cost}))
        ]);

        render() {
            if (this.state.loading) {
                return null;
            }

            return (
                <ConfigContext.Consumer>
                    {config => {
                        const {spike_pay, stripe, paypal_rest, paypal_soap, authorize, mercury_pay, disable_pricing} = config.cart;
                        const hasButtons = paypal_rest;
                        const hasCC = spike_pay || stripe || paypal_soap || authorize || mercury_pay;
                        const requiresInStorePickupOnly = !!_.get(config, 'cart.pickup.inStorePickupOnly', false)
                            || _.filter(this.state.cartItems, v => {
                                // Any OEM products are shippable even if set to pickup_in_store when set to allow OEM shipments
                                if (_.get(v, 'catalogId') === 2 && _.get(config, 'cart.pickup.allowOemShipments', false)) {
                                    return false;
                                }

                                return _.get(v, 'settings.pickup_in_store', false);
                            }).length > 0;
                        const gatewayConfig = _.assign(config.cart, {
                            disable_transactions: config.cart.disable_transactions || (!hasButtons && !hasCC) || disable_pricing,
                            require_in_store_pickup_only: requiresInStorePickupOnly
                        });

                        return <CartContext.Provider
                            value={{
                                calculate: this.calculate,
                                currency: value => {
                                    // Format the currency for display
                                    if (_.get(gatewayConfig, 'currency')) {
                                        const currencyCode = gatewayConfig.currency;
                                        const patternSuffix = currencyCode !== 'USD' ? ` ${currencyCode}` : '';

                                        // Assumes only USD/CAD currencies will be available
                                        return currency(value, {
                                            pattern: `!#${patternSuffix}`,
                                            negativePattern: `-!#${patternSuffix}`,
                                            precision: 2,
                                            symbol: '$'
                                        });
                                    }

                                    return currency(value);
                                },
                                gatewayConfig: gatewayConfig,
                                ...this.state
                            }}
                        >
                            <WrappedComponent gatewayConfig={gatewayConfig} {...this.state} {...this.props}/>
                        </CartContext.Provider>
                    }}
                </ConfigContext.Consumer>
            )
        }
    }
}

export default withCart(CartRouter);
