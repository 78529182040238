import React from 'react';
import Stripe from './Forms/Stripe';
import {cart} from 'src/client.webstore/utils/cart';
import {Link} from 'react-router-dom';
import PayPalExpress from './Forms/PayPalExpress';
import PayPalRESTful from 'src/client.webstore/Views/Cart/Elements/PaymentButtons/PayPal';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import Authorize from 'src/client.webstore/Views/Cart/Payment/Forms/Authorize';
import MercuryPay from 'src/client.webstore/Views/Cart/Payment/Forms/MercuryPay';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import CallUsButton from 'src/client.webstore/Views/Elements/CallUsButton';
import _ from 'lodash';
import SpikePay from './Forms/SpikePay';

const Divider = () => (
    <ThemeContext.Consumer>
        {({cs}) => (
            <div className={cs('row', 'my-5')}>
                <div className={cs('col')}>
                    <hr/>
                </div>
            </div>
        )}
    </ThemeContext.Consumer>
);

class PaymentPage extends React.Component {
    state = {
        loading: true
    };

    componentDidMount() {
        Promise.all([
            cart.getBilling().then(billing => this.setState({billing})),
            cart.getShipping().then(shipping => this.setState({shipping}))
        ]).then(() => this.setState({loading: false}));
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        let {billing, shipping} = this.state;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {({gatewayConfig}) => (
                            <React.Fragment>
                                <div className={cs('row', 'mb-5', 'mt-3')}>
                                    <div className={cs('col')}>
                                        <h2>Payment & Review</h2>
                                        <p className={cs('lead')}>
                                            Please review the following information for accuracy.
                                        </p>
                                    </div>
                                </div>

                                <div className={cs('row')}>
                                    <div className={cs('col')}>
                                        <h3 className={cs('text-uppercase')}>
                                            Billing Details
                                            {' '}
                                            <small className={cs('h6')}>
                                                <Link to={'/cart/billing'}>Edit</Link>
                                            </small>
                                        </h3>
                                        <address id={'pss-cart-billing-address'}>
                                            {billing.name}<br/>
                                            {billing.email}<br/>
                                            {billing.phone}<br/>
                                            {billing.address_1}<br/>
                                            {billing.address_2 !== '' &&
                                            <React.Fragment>
                                                {billing.address_2}<br/>
                                            </React.Fragment>
                                            }
                                            {billing.city}, {billing.state} {billing.zip}
                                        </address>
                                    </div>
                                </div>

                                <Divider/>

                                <div className={cs('row')}>
                                    <div className={cs('col')}>
                                        <h3 className={cs('text-uppercase')}>
                                            Shipping Details
                                            {' '}
                                            {!shipping.pickup &&
                                            <small className={cs('h6')}>
                                                <Link to={'/cart/shipping'}>Edit</Link>
                                            </small>
                                            }
                                        </h3>

                                        <address id={'pss-cart-shipping-address'}>
                                            {shipping.name}<br/>
                                            {shipping.email}<br/>
                                            {shipping.phone}<br/>
                                            {shipping.address_1}<br/>
                                            {shipping.address_2 !== '' &&
                                            <React.Fragment>
                                                {shipping.address_2}<br/>
                                            </React.Fragment>
                                            }
                                            {shipping.city}, {shipping.state} {shipping.zip}
                                        </address>

                                        {shipping.pickup &&
                                        <p><strong>Please Note:</strong> We will notify you via email when your order is ready for pickup.</p>
                                        }
                                    </div>
                                </div>

                                <Divider/>

                                <div className={cs('row')}>
                                    <div className={cs('col')}>
                                        <h3 className={cs('text-uppercase')}>Payment Details</h3>
                                            <div className={cs('row', 'justify-content-center')}>
                                                {gatewayConfig.disable_transactions &&
                                                <div className={cs('col-12', 'mb-3')}>
                                                    <div className={cs('alert', 'alert-info')} role={'alert'}>
                                                        Online payments are not being accepted at this time.
                                                    </div>

                                                    <div className={cs('row', 'justify-content-center')}>
                                                        <div className={cs('col-4', 'align-self-center', 'text-center')}>
                                                            <CallUsButton />
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                {!gatewayConfig.disable_transactions &&
                                                <div className={cs('col-10')}>
                                                    {this.props.gatewayConfig.spike_pay &&
                                                    <SpikePay {...this.props} {...this.state}/>
                                                    }
                                                    {this.props.gatewayConfig.stripe &&
                                                    <Stripe {...this.props} {...this.state}/>
                                                    }

                                                    {this.props.gatewayConfig.authorize &&
                                                    <Authorize {...this.props} {...this.state}/>
                                                    }

                                                    {this.props.gatewayConfig.mercury_pay &&
                                                    <MercuryPay {...this.props} {...this.state}/>
                                                    }

                                                    {this.props.gatewayConfig.paypal_soap && !this.props.gatewayConfig.paypal_rest &&
                                                    <PayPalExpress {...this.props} {...this.state}/>
                                                    }

                                                    {this.props.gatewayConfig.paypal_rest &&
                                                    <PayPalRESTful
                                                        {...this.props}
                                                        shippingAddress={_.get(this.state, 'shipping', {})}
                                                        billingAddress={_.get(this.state, 'billing', {})}
                                                    />
                                                    }
                                                </div>
                                                }
                                            </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default class PaymentWrapper extends React.Component {
    render() {
        return (
            <CartContext.Consumer>
                {context => {
                    if (context.loading) {
                        return null;
                    }

                    return <PaymentPage {...context}/>;
                }}
            </CartContext.Consumer>
        );
    }
}
