import React from 'react';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import baseClient from 'src/client.webstore/api/base';
import OrderItem from 'src/client.webstore/Views/Cart/Elements/OrderItem';
import NameValue from 'src/client.webstore/Views/Cart/Elements/NameValue';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

export default class Summary extends React.Component {
    state = {
        loading: true
    };

    componentDidMount() {
        baseClient()
            .get(`/webstore/webstore_cart/get/${this.props.match.params.assignedOrderId}`)
            .then(res => this.setState({loading: false, ...res.data}, () => Analytics.view({
                ecomm_part_list_ids: this.state.orderItems.map(v => `${v.foreign_key_id}`),
                ecomm_part_list_quantities: this.state.orderItems.map(v => `${v.qty}`),
                ecomm_cart_event: 'receipt',
                ecomm_cart_subtotal: `${this.state.order.order_subtotal}`,
                ecomm_cart_shipping: `${this.state.order.shipping_total}`,
                ecomm_cart_tax: `${this.state.order.tax_total}`,
                ecomm_cart_grand_total: `${this.state.order.order_total}`,
                tealium_event: 'ecommerce_part_cart_action'
            })));
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        let {billing, shipping, order, orderItems} = this.state;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row')}>
                        <div className={cs('col-12', 'text-center', 'my-3', 'mb-5')}>
                            <h2>Thank You!</h2>
                            <h3>Your order has been placed.</h3>
                            <h4>Order Number: {this.props.match.params.assignedOrderId}</h4>
                        </div>
                        <div className={cs('col')}>
                            <h3 className={cs('mb-3', 'text-primary')}>Your Order Summary</h3>

                            <div className={cs('row', 'mb-5')}>
                                <div className={cs('col-md')}>
                                    <h3 className={cs('text-uppercase', 'mb-2')}>
                                        Billing Details
                                    </h3>

                                    <address className={cs('mb-5')}>
                                        {billing.name}<br/>
                                        {billing.email}<br/>
                                        {billing.phone}<br/>
                                        {billing.address_1}<br/>
                                        {billing.address_2 !== '' &&
                                        <>
                                            {billing.address_2}<br/>
                                        </>
                                        }
                                        {billing.city}, {billing.state} {billing.zip}
                                    </address>
                                </div>
                                <div className={cs('col-md')}>
                                    <h3 className={cs('text-uppercase', 'mb-2')}>
                                        Shipping Details
                                    </h3>

                                    <address className={cs('mb-3')}>
                                        {shipping.name}<br/>
                                        {shipping.address_1}<br/>
                                        {shipping.address_2 !== '' &&
                                        <React.Fragment>
                                            {shipping.address_2}<br/>
                                        </React.Fragment>
                                        }
                                        {shipping.city}, {shipping.state} {shipping.zip}
                                    </address>

                                    <CartContext.Consumer>
                                        {context => (
                                            <React.Fragment>
                                                {context.gatewayConfig.pickup.inStorePickup > 0 && context.gatewayConfig.pickup.locations.length > 0 &&
                                                <div className={cs('row')}>
                                                    <div className={cs('col-12', 'mb-3')}>
                                                        <div className={cs('alert', 'alert-info')} role={'alert'}>
                                                            If you have selected IN-STORE PICKUP, please await an email stating that your order is ready.
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </React.Fragment>
                                        )}
                                    </CartContext.Consumer>
                                </div>

                                <div className={cs('col-12', 'my-5')}>
                                    <hr/>
                                </div>

                                <div className={cs('col-12')}>
                                    <h2 className={cs('mb-3')}>Order Details</h2>

                                    <div className={cs('row')}>
                                        <div className={cs('col-lg-9')}>
                                            {orderItems.map((v, k) => (
                                                <OrderItem key={`item-${k}`} {...v} />
                                            ))}
                                        </div>

                                        <div className={cs('col-lg-3')}>
                                            <h3 className={cs('text-uppercase', 'mb-2')}>
                                                Order Totals
                                            </h3>

                                            <CartContext.Consumer>
                                                {({currency}) => (
                                                    <React.Fragment>
                                                        <NameValue name={'Subtotal'} value={currency(order.order_subtotal).format(true)}/>
                                                        <NameValue name={'Shipping'} value={currency(order.shipping_total).format(true)}/>
                                                        {order.discount_total > 0 && <NameValue name={'Discount'} value={currency(-order.discount_total).format(true)} valueClass={cs('text-primary')}/>}
                                                        <NameValue name={'Tax'} value={currency(order.tax_total).format(true)}/>

                                                        <hr className={cs('my-3')}/>

                                                        <NameValue name={'Total'} value={currency(order.order_total).format(true)} nameClass={'font-weight-bold'} valueClass={'font-weight-bold'}/>
                                                    </React.Fragment>
                                                )}
                                            </CartContext.Consumer>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}