import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import _ from 'lodash';
import styled from 'styled-components';

// Custom CSS that renders the shipping availability box for this component
const ShippingAvailabilityWrapper = styled.div`
    .pss-shipping-availability {
      background-color: #f2f2f2; 
      color: #333; 
      display: flex;
      align-items: center;
      height: 100%;
    }
    
    .pss-shipping-availability > .pss-shipping-content {
      padding: 14px 10px 14px 0;
    }

    .pss-shipping-availability > .pss-shipping-content > span:first-of-type {
      font-weight: bold;
    }

    .pss-shipping-availability > .pss-shipping-content > span:last-of-type {
      font-size: 12px;
    }
`;

/**
 * Renders Shipping or In-Store Pickup shipping availability boxes
 */
export default class ShippingAvailability extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['pickup', 'ship']).isRequired,
        leadTime: PropTypes.number.isRequired,
        isShelf: PropTypes.bool.isRequired,
        cs: PropTypes.func.isRequired
    };

    render() {
        const {cs, type, leadTime, isShelf} = this.props;
        const icon = type === 'pickup' ? 'store' : 'truck';
        const iconClass = type === 'pickup' ? '' : 'fa-flip-horizontal';

        return (
            <ShippingAvailabilityWrapper style={{height: '100%'}}>
                <div className={_.join([cs('rounded'), 'pss-shipping-availability'], ' ')}>
                    <Icon icon={icon} size={'2x'} padding={3} className={iconClass} />

                    <div className={'pss-shipping-content'}>
                        <span>
                            {type === 'ship' &&
                                <>Ship</>
                            }

                            {type === 'pickup' &&
                                <>In-Store Pickup</>
                            }
                        </span>
                        <br/>
                        <span>
                            {type === 'ship' &&
                                <>Ships within {leadTime} day{leadTime === 1 ? '' : 's'}!</>
                            }

                            {type === 'pickup' &&
                                <>
                                    {isShelf &&
                                        <>Available Today!</>
                                    }

                                    {!isShelf &&
                                        <>Available within {leadTime} day{leadTime === 1 ? '' : 's'}!</>
                                    }
                                </>
                            }
                        </span>
                    </div>
                </div>
            </ShippingAvailabilityWrapper>
        );
    }
}
