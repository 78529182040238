import {hot} from 'react-hot-loader/root';
import React from 'react';
import {Link} from 'react-router-dom';
import {Checkbox} from 'evergreen-ui';
import PropTypes from 'prop-types';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

/**
 * An individual link representing a filter on the webstore sidebar
 */
class FilterLink extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        linkTo: PropTypes.object.isRequired,
        disabled: PropTypes.bool.isRequired,
        checked: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {label, name, disabled, checked, onClick, linkTo} = this.props;

        // @todo Checkbox component differs from the use of other checkboxes via CartInput
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <Link
                        to={linkTo}
                        className={cs((disabled ? 'text-muted' : 'stretched-link'), 'd-flex')}
                        onClick={onClick}
                        style={(disabled ? {pointerEvents: 'none'} : {})}
                    >
                        <Checkbox
                            label={''}
                            name={name}
                            checked={checked}
                            className={cs('m-0')}
                            disabled={disabled}
                        />
                        <span className={cs('ml-2')}>{label}</span>
                    </Link>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default hot(FilterLink);
