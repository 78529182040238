import React from 'react';
import {cart} from 'src/client.webstore/utils/cart';
import {Link, Redirect} from 'react-router-dom';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import CartInput from 'src/client.webstore/Views/Cart/Elements/CartInput';
import iso3166 from 'iso-3166-2';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

export default class Shipping extends React.Component {
    static contextType = CartContext;

    state = {
        loading: true,
        cartZip: null,
        redirectTo: null
    };

    componentDidMount() {
        Promise.all([
            cart.getZip().then(cartZip => this.setState({cartZip})),
            cart.getBilling().then(billing => this.setState({billing})),
            cart.getShipping().then(shipping => this.setState({shipping}))
        ]).then(() => this.setState({loading: false}, () => Analytics.view({
            ecomm_part_list_ids: this.context.cartItems.map(v => `${v.foreignKeyId}`),
            ecomm_part_list_quantities: this.context.cartItems.map(v => `${v.quantity}`),
            ecomm_cart_event: 'collect_shipping_info',
            ecomm_cart_subtotal: `${this.context.subtotal}`,
            ecomm_cart_shipping: `${this.context.shipping}`,
            ecomm_cart_tax: `${this.context.tax}`,
            ecomm_cart_grand_total: `${this.context.total}`,
            tealium_event: 'ecommerce_part_cart_action'
        })));
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push={true}/>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {context => (
                            <React.Fragment>
                                {context.gatewayConfig &&
                                <div className={cs('row', 'my-5')}>
                                    <div className={cs('col')}>
                                        <h2 className={cs('mb-3')}>Recipient Details</h2>
                                        <Formik
                                            isInitialValid={this.state.shipping !== null}
                                            initialValues={this.state.shipping || {
                                                name: '',
                                                email: '',
                                                phone: '',
                                                address_1: '',
                                                address_2: '',
                                                city: '',
                                                state: (context.gatewayConfig.disable_out_of_state_orders ? context.gatewayConfig.origin.originState : ''),
                                                country: context.gatewayConfig.origin.originCountry,
                                                cc: false,
                                                zip: this.state.cartZip || '',
                                            }}
                                            onSubmit={values => {
                                                // Only include the defined shipping properties
                                                values = _.pick(
                                                    values,
                                                    ['name', 'email', 'phone', 'address_1', 'address_2', 'city', 'state', 'country', 'cc', 'zip']
                                                );

                                                cart.setShipping(values).then(() => {
                                                    Promise.all([
                                                        // Override the billing address' ship-to/pickup settings such that we use the given shipping address now
                                                        cart.setBilling(_.assign(
                                                            this.state.billing,
                                                            {ship_to_billing: false, pickup: false, webstore_location_id: null}
                                                        )),
                                                        cart.setZip(values.zip),
                                                        context.calculate()
                                                    ]).then(() => this.setState({redirectTo: '/cart/methods'}))
                                                });
                                            }}
                                            validate={values => {
                                                let errors = {};

                                                if (!values.name) {
                                                    _.set(errors, 'name', 'Please enter a valid recipient name.');
                                                }

                                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                                    _.set(errors, 'email', 'Please enter a valid recipient email address.');
                                                }

                                                if (!values.phone) {
                                                    _.set(errors, 'phone', 'Please enter a valid recipient phone number.');
                                                }

                                                if (!values.address_1) {
                                                    _.set(errors, 'address_1', 'Please enter a valid recipient street address.');
                                                }

                                                if (!values.city) {
                                                    _.set(errors, 'city', 'Please enter a valid recipient city.');
                                                }

                                                if (!values.country) {
                                                    _.set(errors, 'country', 'Please select a valid recipient country.');
                                                } else if (_.isEmpty(_.filter(iso3166.data, (v, k) => k === values.country))) {
                                                    _.set(errors, 'country', 'Please select a valid recipient country.');
                                                }

                                                if (!values.state) {
                                                    _.set(errors, 'state', 'Please enter a valid recipient state.');
                                                } else if (context.gatewayConfig.disable_out_of_state_orders && values.state !== context.gatewayConfig.origin.originState) {
                                                    let stateName = _.chain(iso3166.country(values.country).sub)
                                                        .filter((v) => (v.regionCode === context.gatewayConfig.origin.originState))
                                                        .value()
                                                        .reduce((p, c) => (c.name), context.gatewayConfig.origin.originState);

                                                    _.set(errors, 'state', `Only orders from ${stateName} addresses may be accepted.`);
                                                }

                                                if (!values.zip) {
                                                    _.set(errors, 'zip', 'Please enter a valid recipient zip.');
                                                }

                                                return errors;
                                            }}
                                        >
                                            {bag => {
                                                let {values, isValid} = bag;
                                                let countries = _.get(context, 'gatewayConfig.countries', []) || [];

                                                return (
                                                    <Form>
                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput bag={bag} label={'Full Name'} name={'name'} type={'text'}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col-md')}>
                                                                <CartInput type={'email'} label={'E-Mail'} name={'email'} bag={bag}/>
                                                            </div>
                                                            <div className={cs('col-md')}>
                                                                <CartInput type={'text'} label={'Phone'} name={'phone'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Address'} name={'address_1'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Address'} name={'address_2'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput
                                                                    type={'select'}
                                                                    label={'Country'}
                                                                    name={'country'}
                                                                    bag={bag}
                                                                    disabled={context.gatewayConfig.disable_out_of_state_orders}
                                                                    options={context.gatewayConfig.disable_out_of_state_orders || _.isEmpty(countries)
                                                                        // Only the origin country may be used when configured to disable out-of-state orders
                                                                        ? [{
                                                                            name: iso3166.country(context.gatewayConfig.origin.originCountry).name,
                                                                            value: context.gatewayConfig.origin.originCountry
                                                                        }]
                                                                        // List all shippable countries
                                                                        : _.map(
                                                                            countries,
                                                                            v => ({name: iso3166.country(v).name, value: v})
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'City'} name={'city'} bag={bag}/>
                                                            </div>
                                                            <div className={cs('col')}>
                                                                <CartInput
                                                                    type={'select'}
                                                                    label={values.country === 'US' ? 'State' : 'State/Region/Province'}
                                                                    name={'state'}
                                                                    bag={bag}
                                                                    disabled={context.gatewayConfig.disable_out_of_state_orders}
                                                                    options={
                                                                        _.concat(
                                                                            [{name: '- Please Select -', value: ''}],
                                                                            _.chain(iso3166.country(values.country).sub)
                                                                                .map((v, k) => ({name: v.name, value: iso3166.subdivision(k).regionCode}))
                                                                                .filter(v => {
                                                                                    // Only include the store's state
                                                                                    if (context.gatewayConfig.disable_out_of_state_orders) {
                                                                                        return v.value === context.gatewayConfig.origin.originState;
                                                                                    }

                                                                                    return true;
                                                                                })
                                                                                .sortBy(o => o.name)
                                                                                .value()
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Zip'} name={'zip'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('row')}>
                                                            <div className={cs('col')}>
                                                                <Link
                                                                    to={'/cart/billing'}
                                                                    className={cs('btn', 'btn-outline-secondary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    id={'pss-back-to-billing'}
                                                                >
                                                                    Back To Billing
                                                                </Link>
                                                            </div>
                                                            <div className={cs('col', 'text-right')}>
                                                                <button
                                                                    type={'submit'}
                                                                    className={cs('btn', 'btn-primary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    disabled={!isValid}
                                                                    id={'pss-continue-from-shipping'}
                                                                >
                                                                    Continue To Shipping Methods
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                                }
                            </React.Fragment>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}