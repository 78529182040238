import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

import {
    faEdit,
    faShoppingCart,
    faTimes,
    faEnvelope,
    faPhone,
    faCheck,
    faSearchPlus,
    faTruck,
    faStore
} from '@fortawesome/free-solid-svg-icons';

import {
    faEdit as faEditRegular,
    faEnvelope as faEnvelopeRegular
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faEdit,
    faShoppingCart,
    faTimes,
    faEnvelope,
    faPhone,
    faCheck,
    faSearchPlus,
    faTruck,
    faStore
);

library.add(
    faEditRegular,
    faEnvelopeRegular
);


export default class Icon extends Component {
    static propTypes = {
        icon: PropTypes.any.isRequired,
        className: PropTypes.string,
        color: PropTypes.string,
        spin: PropTypes.bool,
        before: PropTypes.any,
        after: PropTypes.any,
        size: PropTypes.oneOf(['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']),
        padding: PropTypes.number,
        inverse: PropTypes.bool
    };

    static defaultProps = {
        spin: false,
        icon: 'times',
        padding: 1,
        inverse: false
    };

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <span className={cs(`px-${this.props.padding}`)}>
                        <FontAwesomeIcon size={this.props.size} icon={this.props.icon} className={this.props.className} spin={this.props.spin} inverse={this.props.inverse}/>
                        <span className={'sr-only'}>a {this.props.icon} icon</span>
                    </span>
                )}
            </ThemeContext.Consumer>
        )
    }
}
