import React, {Component} from 'react';
import {ClipLoader} from 'react-spinners';
import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;

const Col = styled.div`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    text-align: center;
`;

export default class Spinner extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <ClipLoader/>
                </Col>
            </Row>
        )
    }
}
