import React from 'react';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';

export default class InStoreMessage extends React.Component {
    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {({gatewayConfig}) => {
                            const {inStorePickupOnly} = gatewayConfig.pickup;
                            const {require_in_store_pickup_only} = gatewayConfig;

                            if (!require_in_store_pickup_only) {
                                return <></>;
                            }

                            return (
                                <div className={cs('alert', 'alert-warning')} role={'alert'}>
                                    One or more of the item(s) in your cart is only available for In-Store Pickup.

                                    {!inStorePickupOnly &&
                                    <>
                                        {} If you wish to ship an item that is not In-Store Pickup Only, please order
                                        that in a separate purchase.
                                    </>
                                    }
                                </div>
                            );
                        }}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}
