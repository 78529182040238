import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import styled from 'styled-components';
import {Rectangle} from 'src/client.webstore/Views/Landing/Elements/Rectangle';

// Set a container for use by @container queries so we can display categories well in BOTH fluid and fixed width sizes
const CategoryContainer = styled.div`
  container: categoryContainer / inline-size;
`;

const CardContainer = styled.div`
  // Keep 2 columns side-by-side for mobile by using smaller sizes
  height: 100px;
  width: 130px;
  overflow: hidden;
  position: relative;
  
  > a {
    display: inline-block;
  }
`;

const ImageContainer = styled.div`
  // Keep 2 columns side-by-side for mobile by using smaller sizes
  height: 100px;
  width: 130px;
  overflow: hidden;
  position: relative;
  
  > div.pss-img-blank {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }

  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  /* Hide any broken image icons the browser might overlay on bad/error images */
  > img[alt]::after {
    content: attr(alt);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  > div.pss-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(121, 121, 121, 1);

    > div.pss-cat-name {
      position: absolute;
      top: 51px;
      left: 0px;
      padding: 0 10px 0 10px;
      width: 110px;
      overflow: hidden;
      
      > div.pss-pad-sep {
        padding-bottom: 0.5rem;
      }

      h4 {
        font-size: 1.25rem;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  > div.pss-img-overlay:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export default class CategoryImages extends React.Component {

    static propTypes = {
        categories: PropTypes.array.isRequired,
        categorySlug: PropTypes.string
    };

    static defaultProps = {
        categorySlug: ''
    }

    state = {
        displayCount: 11
    }

    render() {
        let categories = _.slice(this.props.categories, 0, this.state.displayCount);
        let showAll = this.state.displayCount >= this.props.categories.length;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CategoryContainer>
                        <div className={cs('d-flex', 'flex-wrap')}>
                            {_.map(categories, category => {
                                const imageUrl = !_.isEmpty(category.image_url_override)
                                    ? category.image_url_override
                                    : (!_.isEmpty(category.image_url) ? category.image_url : '');

                                return (
                                <CardContainer className={cs('pss-card-container', 'col-6', 'col-md-4', 'col-lg-3', 'col-xl-2', 'mb-3', 'd-flex', 'justify-content-center')} key={`category-${category.id}`}>
                                    <Link to={`/categories${category.category_uri}`}>
                                        <ImageContainer className={'pss-image-container'}>
                                            {!_.isEmpty(imageUrl) &&
                                                <img
                                                    src={imageUrl}
                                                    alt={''}
                                                    onError={e => {
                                                        e.onerror = null;
                                                        e.target.style = 'background-color: rgba(0, 0, 0, 0.9)';
                                                    }}
                                                />
                                            }
                                            {_.isEmpty(imageUrl) &&
                                                <div className={'pss-img-blank'}></div>
                                            }
                                            <div className={'pss-img-overlay'}>
                                                <div className={cs('text-uppercase', 'pss-cat-name')}>
                                                    <div className={'pss-pad-sep'}>
                                                        <Rectangle.separator className={cs('bg-primary')} />
                                                    </div>
                                                    <h4>{category.name}</h4>
                                                </div>
                                            </div>
                                        </ImageContainer>
                                    </Link>
                                </CardContainer>
                            )})}

                            <CardContainer className={cs('pss-card-container', 'col-6', 'col-md-4', 'col-lg-3', 'col-xl-2', 'mb-3', 'd-flex', 'justify-content-center')}>
                                <Link to={`/categories/${this.props.categorySlug}`}>
                                    <ImageContainer className={'pss-image-container'}>
                                        <div className={'pss-img-blank'}></div>
                                        <div className={'pss-img-overlay'}>
                                            <div className={cs('text-uppercase', 'pss-cat-name')}>
                                                <div className={'pss-pad-sep'}>
                                                    <Rectangle.separator className={cs('bg-primary')} />
                                                </div>
                                                <h4>View All Categories</h4>
                                            </div>
                                        </div>
                                    </ImageContainer>
                                </Link>
                            </CardContainer>
                        </div>

                        {!showAll &&
                            <div className={cs('col-10', 'offset-1', 'mt-4')}>
                                <button
                                    type={'button'}
                                    title={'View More'}
                                    className={cs('btn', 'btn-secondary', 'w-100', 'text-center', 'rounded')}
                                    onClick={() => this.setState({displayCount: this.state.displayCount + 12})}
                                >
                                    View More
                                </button>
                            </div>
                        }

                        {/* The styled components (styled.div) do not support "@container" queries until version 6+, so they are being set as styles here */}
                        <style type={'text/css'}>
                            {`
                              @container categoryContainer (max-width: 159px) {
                                .pss-card-container {
                                    height: 30px;
                                    width: 39px;
                                    
                                    .pss-image-container {
                                      height: 30px;
                                      width: 39px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 15px;
                                          margin: 0;
                                          padding: 0 2px 0 2px;
                                          height: 30px;
                                          width: 39px;
                                          
                                          > div.pss-pad-sep {
                                            display: none;
                                          }
                                
                                          > h4 {
                                            font-size: 0.5rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                              
                              @container categoryContainer (min-width: 160px) and (max-width: 214px) {
                                .pss-card-container {
                                    height: 60px;
                                    width: 78px;
                                    
                                    .pss-image-container {
                                      height: 60px;
                                      width: 78px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 22px;
                                          margin: 0;
                                          padding: 0 5px 0 5px;
                                          height: 60px;
                                          width: 78px;
                                
                                          > h4 {
                                            font-size: 0.75rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                              
                              @container categoryContainer (min-width: 215px) and (max-width: 399px) {
                                .pss-card-container {
                                    height: 80px;
                                    width: 104px;
                                    
                                    .pss-image-container {
                                      height: 80px;
                                      width: 104px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 36px;
                                          margin: 0;
                                          padding: 0 5px 0 5px;
                                          height: 80px;
                                          width: 104px;
                                
                                          > h4 {
                                            font-size: 1rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                              
                              @container categoryContainer (min-width: 400px) and (max-width: 1099px) {
                                .pss-card-container {
                                    height: 135px;
                                    width: 175px;
                                    
                                    .pss-image-container {
                                      height: 135px;
                                      width: 175px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 76px;
                                          margin: 0;
                                          padding: 0 15px 0 15px;
                                          height: 135px;
                                          width: 175px;
                                
                                          > h4 {
                                            font-size: 1.5rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                            
                            /*
                              @container categoryContainer (min-width: 1140px) and (max-width: 1199px) {
                                .pss-card-container {
                                    height: 200px;
                                    width: 260px;
                                    
                                    .pss-image-container {
                                      height: 200px;
                                      width: 260px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 122px;
                                          padding: 0 20px 0 20px;
                                          height: 200px;
                                          width: 260px;
                                
                                          > div.pss-pad-sep {
                                            padding-bottom: 1rem;
                                          }
                                
                                          > h4 {
                                            font-size: 1.75rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                              */
                            
                              @container categoryContainer (min-width: 1100px) and (max-width: 1274px) {
                                .pss-card-container {
                                    height: 140px;
                                    width: 182px;
                                    
                                    .pss-image-container {
                                      height: 140px;
                                      width: 182px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 72px;
                                          padding: 0 12px 0 12px;
                                          height: 140px;
                                          width: 182px;
                                
                                          > h4 {
                                            font-size: 1.5rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                            
                              @container categoryContainer (min-width: 1275px) and (max-width: 1699px) {
                                .pss-card-container {
                                    height: 150px;
                                    width: 195px;
                                    
                                    .pss-image-container {
                                      height: 150px;
                                      width: 195px;
                                
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 82px;
                                          padding: 0 15px 0 15px;
                                          height: 150px;
                                          width: 195px;
                                
                                          > h4 {
                                            font-size: 1.75rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                            
                              @container categoryContainer (min-width: 1700px) and (max-width: 2449px) {
                                .pss-card-container {
                                    height: 200px;
                                    width: 260px;
                                    
                                    .pss-image-container {
                                      height: 200px;
                                      width: 260px;
                                    
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 122px;
                                          padding: 0 20px 0 20px;
                                          height: 200px;
                                          width: 260px;
                                    
                                          > div.pss-pad-sep {
                                            padding-bottom: 1rem;
                                          }
                                    
                                          > h4 {
                                            font-size: 1.95rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                            
                              @container categoryContainer (min-width: 2450px) {
                                .pss-card-container {
                                    height: 300px;
                                    width: 390px;
                                    
                                    .pss-image-container {
                                      height: 300px;
                                      width: 390px;
                                    
                                      > div.pss-img-overlay {
                                        > div.pss-cat-name {
                                          top: 200px;
                                          padding: 0 30px 0 30px;
                                          height: 300px;
                                          width: 390px;
                                
                                          > div.pss-pad-sep {
                                            padding-bottom: 1.5rem
                                          }
                                
                                          > h4 {
                                            font-size: 2.2rem;
                                          }
                                        }
                                      }
                                    }
                                }
                              }
                            `}
                        </style>
                    </CategoryContainer>
                )}
            </ThemeContext.Consumer>
        );
    }
}

