import React from 'react';
import qs from 'querystring';
import {cart} from 'src/client.webstore/utils/cart';
import {gateway} from 'src/client.webstore/utils/gateway';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {Redirect} from 'react-router-dom';
import script from 'scriptjs';
import analytics from 'src/client.webstore/utils/analytics';
import PageLoadingOverlay from 'src/client.webstore/Views/Elements/PageLoadingOverlay';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Analytics = analytics();

export default class PayPalRESTful extends React.Component {
    static propTypes = {
        shippingAddress: PropTypes.object.isRequired
    };

    state = {
        hasRendered: false,
        redirect: null,
        showOverlay: false
    };

    componentDidMount() {
        if (!window.paypal) {
            let {gatewayConfig} = this.props;

            let payPalParams = {
                debug: 'false',
                commit: 'true', //@link https://developer.paypal.com/docs/checkout/integration-features/confirmation-page/
                intent: 'authorize', //@link https://developer.paypal.com/docs/checkout/reference/customize-sdk/#intent
                'client-id': gatewayConfig.paypal_rest.client_id,
                currency: gatewayConfig.currency //@link https://developer.paypal.com/docs/checkout/reference/customize-sdk/#currency
            };

            if (gatewayConfig.stripe || gatewayConfig.authorize) {
                payPalParams['disable-funding'] = 'card';
            }


            script(`https://www.paypal.com/sdk/js?${qs.stringify(payPalParams)}`, this.inject);
        } else {
            this.inject();
        }
    }

    //@link: https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
    getBreakdown = totals => {
        let {gatewayConfig} = this.props;
        let currencyCode = gatewayConfig.currency;

        return {
            item_total: {
                currency_code: currencyCode,
                value: totals.subtotal
            },
            shipping: {
                currency_code: currencyCode,
                value: totals.shipping || 0
            },
            tax_total: {
                currency_code: currencyCode,
                value: totals.tax || 0
            },
            discount: {
                currency_code: currencyCode,
                value: -1 * totals.discount || 0 //PayPal expects discounts to be positive, cart.calculate().discount is always negative float if a discount is applied
            }
        };
    };

    inject = () => {
        this.setState({hasRendered: true}, () => {
            let {gatewayConfig} = this.props;

            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    // @see https://developer.paypal.com/docs/api/orders/v2/#orders_create
                    let billingName = _.words(_.get(this.props, 'billingAddress.name', ''));
                    let purchaseUnits = {
                        purchase_units: [
                            {
                                amount: {
                                    value: this.props.total || this.props.subtotal + this.props.discount,
                                    currency_code: gatewayConfig.currency,
                                    breakdown: this.getBreakdown(this.props)
                                },
                                shipping: {
                                    name: {
                                        full_name: _.get(this.props, 'shippingAddress.name', '')
                                    },
                                    address: {
                                        address_line_1: _.get(this.props, 'shippingAddress.address_1', ''),
                                        address_line_2: _.get(this.props, 'shippingAddress.address_2', ''),
                                        admin_area_2: _.get(this.props, 'shippingAddress.city', ''),
                                        admin_area_1: _.get(this.props, 'shippingAddress.state', ''),
                                        postal_code: _.get(this.props, 'shippingAddress.zip', ''),
                                        country_code: _.get(this.props, 'shippingAddress.country', '')
                                    }
                                }
                            }
                        ],
                        // Do not allow the user to change the shipping address from what we provide
                        application_context: {
                            shipping_preference: 'SET_PROVIDED_ADDRESS'
                        },
                        // Billing address
                        payer: {
                            email_address: _.get(this.props, 'billingAddress.email', ''),
                            name: {
                                given_name: _.first(billingName),
                                surname: _.join(_.slice(billingName, 1), ' ')
                            },
                            address: {
                                address_line_1: _.get(this.props, 'billingAddress.address_1', ''),
                                address_line_2: _.get(this.props, 'billingAddress.address_2', ''),
                                admin_area_2: _.get(this.props, 'billingAddress.city', ''),
                                admin_area_1: _.get(this.props, 'billingAddress.state', ''),
                                postal_code: _.get(this.props, 'billingAddress.zip', ''),
                                country_code: _.get(this.props, 'billingAddress.country', '')
                            }
                        }
                    };

                    return actions.order.create(purchaseUnits);
                },
                onApprove: (data, actions) => {
                    this.setState({showOverlay: true});

                    return actions.order.authorize()
                        .then(order => {
                            Promise
                                .all([
                                    cart.setPaymentToken(order.purchase_units[0].payments.authorizations[0].id)
                                ])
                                .then(() => gateway.capture('PayPal_Rest'))
                                .then(res => cart.clear().then(
                                        () => this.setState(
                                            {
                                                redirect: `/cart/summary/${res.data.orderId}`,
                                                showOverlay: false
                                            },
                                            () => Analytics.convert(res.data.orderId)
                                        )
                                    )
                                )
                                .catch(e => this.setState({showOverlay: false, error: {message: e.message}}))
                        })
                        .catch(e => {
                            this.setState({showOverlay: false});
                            throw e;
                        });
                },
                onShippingChange: (data, actions) => {
                    // The shipping address cannot be changed; the shipping rate chosen at checkout depends on the address given
                    actions.reject();
                }
            }).render('#paypal-button-container');
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }


        return (
            <React.Fragment>
                {this.state.showOverlay &&
                <PageLoadingOverlay text={'Processing...'}/>
                }

                <ThemeContext.Consumer>
                    {({cs}) => (
                        <div className={cs('row', 'my-5')}>
                            <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                                <div className={cs('row', 'mb-5')}>
                                    <div className={cs('col')}>
                                        <h3>PayPal</h3>
                                        <p className={cs('lead')}>
                                            You will be redirected to PayPal to complete your purchase securely.
                                        </p>

                                        {_.has(this.state, 'error.message') &&
                                        <div className={cs('row')}>
                                            <div className={cs('col', 'text-danger')}>
                                                {this.state.error.message}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className={cs('row', 'justify-content-end')}>
                                    <div className={cs('col-12', 'col-md-5', 'align-self-center')}>
                                        <div id={'paypal-button-container'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ThemeContext.Consumer>
            </React.Fragment>
        );
    }
}
