import {product} from 'src/client.webstore/utils/cart';
import baseClient from 'src/client.webstore/api/base';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {object, string} from 'yup';
import mueConfigService from 'src/client.webstore/MueConfigService';

const inputSchema = object({
    productToken: string().required()
});

const loadRecaptcha = () => new Promise((res, rej) => {
    let script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Lc47XAUAAAAADbD1mlIVVH728vaaTs3VpGuMP0V';
    script.type = 'text/javascript';

    // Resolve one load & ready are good
    script.addEventListener('load', () => window.grecaptcha.ready(res));
    script.addEventListener('error', rej);

    document.head.appendChild(script);
});

/**
 * @memberOf utils
 * @param productData
 * @returns {Promise<unknown>} An instance of Product to be used with cart utils with JWT
 */
const createSecureProduct = async (productData = {
    productToken : null //required
}) => new Promise(async (resolve, reject) => {
    try {
        await inputSchema.validate(product);

        // Check for grecaptcha and load if missing
        if (typeof window.grecaptcha === 'undefined') {
            await loadRecaptcha();
        }

        // Execute a grecaptcha verification
        // Don't catch this error and let it fail loudly
        // @todo Log failures and rethrow?
        await window.grecaptcha.execute('6Lc47XAUAAAAADbD1mlIVVH728vaaTs3VpGuMP0V', {action: 'createMuProduct'});

        let productResponse = await baseClient().post('/webstore/webstore_catalog/create_secure_product', {}, productData);
        const productInstance = product(productResponse.data.catalogId, productResponse.data.foreignKeyId);
        productInstance.add(productResponse.data.quantity);
        resolve(productInstance);
        const appConfig = {
            isMue: true
        };
        mueConfigService.setConfig(appConfig);
    } catch (error) {
        toast.error('An error occurred while adding the product to cart. Please try again.');
        reject(error); // Reject the promise with the error
    }
});

export {createSecureProduct};