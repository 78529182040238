import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

export default class BrandLogos extends React.Component {

    static propTypes = {
        brands: PropTypes.array.isRequired,
        categorySlug: PropTypes.string
    };

    static defaultProps = {
        categorySlug: ''
    }

    state = {
        displayCount: 20
    }

    render() {
        const brands = _.slice(this.props.brands, 0, this.state.displayCount);

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('d-flex', 'flex-wrap')}>
                        {_.map(brands, (brand, id) => {
                            const query = encodeURIComponent(JSON.stringify({manufacturer: [brand]}));

                            return (
                                <div key={`brand-link-${id}`} className={cs('col-6', 'col-sm-4', 'col-md-3', 'mb-3')}>
                                    <Link to={`/categories/${this.props.categorySlug}?q=${query}`}>
                                        <h5>{brand}</h5>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}
