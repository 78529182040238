import {product} from 'src/client.webstore/utils/cart';
import baseClient from 'src/client.webstore/api/base';
import {object, string, number} from 'yup';

const inputSchema = object({
    manufacturer: string().required(),
    name: string().required(),
    manufacturerPartNumber: string().required(),
    salePrice: number().required().positive(),
    imageUrl: string().url().nullable(),
    msrp: number().positive().nullable(),
    size: string().nullable(),
    color: string().nullable(),
    option: string().nullable()
});

const loadRecaptcha = () => new Promise((res, rej) => {
    let script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Lc47XAUAAAAADbD1mlIVVH728vaaTs3VpGuMP0V';
    script.type = 'text/javascript';

    // Resolve one load & ready are good
    script.addEventListener('load', () => window.grecaptcha.ready(res));
    script.addEventListener('error', rej);

    document.head.appendChild(script);
});

/**
 * @memberOf utils
 * @param productData
 * @returns {Promise<unknown>} An instance of Product to be used with cart utils
 */
const createProduct = async (productData = {
    manufacturer: null, //required
    name: null, //required
    manufacturerPartNumber: null, //required
    salePrice: null, //required
    imageUrl: null, //optional
    msrp: null, //optional
    size: null, //optional
    color: null, //optional
    option: null //optional
}) => new Promise(async (resolve, reject) => {
    try {
        await inputSchema.validate(product);
    } catch (e) {
        reject(e);
    }

    // Check for grecaptcha and load if missing
    if (typeof window.grecaptcha === 'undefined') {
        await loadRecaptcha();
    }

    // Execute a grecaptcha verification
    // Don't catch this error and let it fail loudly
    // @todo Log failures and rethrow?
    await window.grecaptcha.execute('6Lc47XAUAAAAADbD1mlIVVH728vaaTs3VpGuMP0V', {action: 'createMuProduct'});

    let productResponse = await baseClient().post('/webstore/webstore_catalog/create', {}, productData);
    resolve(product(productResponse.data.catalogId, productResponse.data.foreignKeyId));
});


export {createProduct};