import React from 'react';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {gateway} from 'src/client.webstore/utils/gateway';
import {withRouter} from 'react-router-dom';
import qs from 'query-string';
import {cart} from 'src/client.webstore/utils/cart';
import Bugsnag from '@bugsnag/js';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

class MercuryPay extends React.Component {
    state = {
        loading: true,
        error: false,
        redirect: null
    }

    componentDidMount() {
        let query = qs.parse(this.props.location.search);

        if (query.ReturnCode) {
            if (query.ReturnCode === '0') {
                cart
                    .setPaymentToken(query.PaymentID)
                    .then(() => gateway.capture('MercuryPay', {paymentId: query.PaymentID}))
                    .then(res => {
                        Analytics.convert(res.data.orderId);
                        return res;
                    })
                    .then(res => cart.clear().then(() => this.props.history.push(`/cart/summary/${res.data.orderId}`)))
                    .catch(err => this.setState(
                        {
                            error: true,
                            loading: false,
                            message: err.message
                        },
                        () => Bugsnag.notify(new Error('MercuryPay capture request failed.'))
                    ));
            } else {
                this.setState({
                    error: true,
                    loading: false,
                    message: 'Something went wrong while attempting to process your payment. Please contact us to complete your purchase.'
                }, () => Bugsnag.notify(new Error('MercuryPay return code was non-zero')));
            }
        } else {
            gateway
                .authorize('MercuryPay', {processCompleteUrl: window.location.href, returnUrl: window.location.href})
                .then(res => this.setState({
                    ...res.data,
                    loading: false
                }))
                .catch(err => this.setState(
                    {
                        error: true,
                        loading: false,
                        message: err.message
                    },
                    () => Bugsnag.notify(new Error('MercuryPay authorize request failed.'))
                ));
        }
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => {
                    return (
                        <div className={cs('row', 'my-5')}>
                            <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                                <h3>Credit/Debit Card</h3>

                                {!this.state.error &&
                                <p className={cs('lead')}>
                                    Securely checkout using your credit/debit card online. Clicking <strong>Checkout Now</strong> below
                                    will securely redirect your web browser to our payment processor. Once your payment
                                    has completed, you will be sent back to your secure website for order confirmation.
                                </p>
                                }

                                {this.state.error &&
                                <div className={cs('alert', 'alert-warning', 'my-3')} role={'alert'}>
                                    <p className={cs('lead')}>
                                        {this.state.message}
                                    </p>
                                </div>
                                }

                                <div className={cs('row', 'justify-content-end')}>
                                    <div className={cs('col', 'col-md-4', 'text-right')}>

                                        {this.state.loading &&
                                        <button
                                            type={'button'}
                                            className={cs('btn', 'btn-lg', 'btn-primary', 'w-100')}
                                            disabled={true}
                                        >
                                            Please Wait
                                        </button>
                                        }

                                        {!this.state.loading &&
                                        <form action={this.state.redirect} method={'POST'}>
                                            <input type={'hidden'} name={'PaymentID'} value={this.state.transactionReference}/>
                                            <input type='hidden' name='ReturnMethod' value='GET'/>

                                            <button
                                                type={'submit'}
                                                className={cs('btn', 'btn-lg', 'btn-primary', 'w-100')}
                                                disabled={this.state.error}
                                            >
                                                Checkout Now
                                            </button>
                                        </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </ThemeContext.Consumer>
        )
    }
}

export default withRouter(MercuryPay);