import React from 'react';
import PropTypes from 'prop-types';
import productsClient from 'src/client.webstore/api/product';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';

/**
 * Displays a box containing details on an order item
 */
export default class OrderItem extends React.Component {
    static propTypes = {
        catalog_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        foreign_key_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        qty: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        price_paid: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        size: PropTypes.string,
        color: PropTypes.string,
        option: PropTypes.string,
        name: PropTypes.string.isRequired,
        manufacturer_part_number: PropTypes.string.isRequired
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {({currency}) => (
                            <div className={cs('row', 'mb-5')}>
                                <div className={cs('col')}>
                                    <div className={cs('bg-white', 'border', 'p-2', 'shadow-sm')}>
                                        <div className={cs('row', 'p-1')}>
                                            <div className={cs('col-3', 'align-self-center')}>
                                                <img
                                                    src={productsClient().imageUrl(this.props.catalog_id, this.props.foreign_key_id, 250, 250)}
                                                    alt={this.props.name}
                                                    className={cs('img-fluid')}
                                                />
                                            </div>
                                            <div className={cs('col-9', 'col-md')}>
                                                <h5 className={cs('mb-3')}>{this.props.name}</h5>
                                                <p className={cs('lead')}>Part #: {this.props.manufacturer_part_number}</p>
                                                {this.props.size && <p className={cs('lead')}>Size: {this.props.size}</p>}
                                                {this.props.color && <p className={cs('lead')}>Color: {this.props.color}</p>}
                                                {this.props.option && <p className={cs('lead')}>Option: {this.props.option}</p>}
                                                {this.props.qty && <p className={cs('lead')}>Qty: {this.props.qty}</p>}
                                            </div>
                                            <div className={cs('col-3', 'align-self-center', 'text-center', 'my-3', 'my-md-0')}>
                                                <p className={cs('lead')}>
                                                    {currency(this.props.price_paid).format(true)}{' '}
                                                    <small className={cs('text-muted')}>ea.</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}
