import React, {Component} from 'react';
import {FacebookShareButton, TwitterShareButton, EmailShareButton} from 'react-share';
import {FacebookIcon, TwitterIcon, EmailIcon} from 'react-share';
import {StyleProvider, ThemeContext} from 'src/client.webstore/Context/StyleProvider';

export default class ShareBox extends Component {
    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row')}>
                        <div className={cs('col', 'd-flex', 'justify-content-center')}>
                            <FacebookShareButton url={window.location.href}>
                                <FacebookIcon size={32}/>
                            </FacebookShareButton>
                        </div>
                        <div className={cs('col', 'd-flex', 'justify-content-center')}>
                            <TwitterShareButton url={window.location.href}>
                                <TwitterIcon size={32}/>
                            </TwitterShareButton>
                        </div>
                        <div className={cs('col', 'd-flex', 'justify-content-center')}>
                            <EmailShareButton url={window.location.href}>
                                <EmailIcon size={32}/>
                            </EmailShareButton>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}
