import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import CartItem from 'src/client.webstore/Views/Cart/Elements/CartItem';
import InStoreMessage from 'src/client.webstore/Views/Cart/Elements/InStoreMessage';
import OrderTotalsSummary from './Elements/OrderTotalsSummary';
import baseClient from 'src/client.webstore/api/base';
import ProductCard from 'src/client.webstore/Views/Elements/ProductCard';
import productsClient from 'src/client.webstore/api/product';
import PaymentButtons from 'src/client.webstore/Views/Cart/Elements/PaymentButtons';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import analytics from 'src/client.webstore/utils/analytics';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import EstimateShipping from 'src/client.webstore/Views/Cart/Components/EstimateShipping';
import _ from 'lodash';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import MODAL from 'src/client.webstore/static/bootstrap.modal.module.scss';

// WYSIWYG bells and whistles
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/code_view.css';
import 'froala-editor/css/plugins/colors.css';
import 'froala-editor/css/plugins/special_characters.css';
import 'froala-editor/css/plugins/table.css';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const Analytics = analytics();

export default class ViewCart extends React.Component {
    static contextType = CartContext;

    static propTypes = {
        showTotals: PropTypes.bool,
        showRecommended: PropTypes.bool
    }

    static defaultProps = {
        showTotals: true,
        showRecommended: true
    }

    state = {
        relatedProducts: [],
        isReturnPolicyOpen: false,
        isShippingPolicyOpen: false
    };

    componentDidMount() {
        baseClient()
            .get('/categories', {length: 4})
            .then(res => this.setState({relatedProducts: res.data.products}, () => Analytics.view({
                ecomm_part_list_ids: this.context.cartItems.map(v => `${v.foreignKeyId}`),
                ecomm_part_list_quantities: this.context.cartItems.map(v => `${v.quantity}`),
                ecomm_cart_event: 'review_cart',
                ecomm_cart_subtotal: `${this.context.subtotal}`,
                ecomm_cart_shipping: `${this.context.shipping}`,
                ecomm_cart_tax: `${this.context.tax}`,
                ecomm_cart_grand_total: `${this.context.total}`,
                tealium_event: 'ecommerce_part_cart_action'
            })))
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <React.Fragment>
                        <CartContext.Consumer>
                            {context => {
                                let {cartItems, gatewayConfig, shipping} = context;
                                let {policies} = gatewayConfig;
                                const returnPolicy = _.get(policies, 'return', '');
                                const shippingPolicy = _.get(policies, 'shipping', '');

                                return (
                                    <div className={cs('row')}>
                                        <div className={cs('col-lg')}>
                                            <h1 className={cs('text-uppercase', 'd-inline-block')}>
                                                My Cart{' '}
                                                {cartItems.length > 0 &&
                                                <small className={cs('text-muted')} style={{fontSize: '50%'}}>
                                                    {cartItems.length === 1 && '1 Item'}
                                                    {cartItems.length > 1 && `${cartItems.length} Items`}
                                                </small>
                                                }
                                            </h1>

                                            <div className={cs('row')}>
                                                <div className={cs('col')}>

                                                    {cartItems.length === 0 &&
                                                    <p className={cs('lead', 'my-5')}>
                                                        Your cart is currently empty.

                                                        {this.props.showRecommended && this.state.relatedProducts.length > 0 &&
                                                        <React.Fragment>
                                                            {' '}<Link to={'/categories'}>Continue Shopping.</Link>
                                                        </React.Fragment>
                                                        }
                                                    </p>
                                                    }

                                                    {cartItems.length > 0 && <InStoreMessage />}

                                                    {cartItems.map((v, k) => (<CartItem key={v['_id']} {...v}/>))}
                                                </div>
                                            </div>
                                        </div>

                                        {this.props.showTotals && cartItems.length > 0 &&
                                        <div className={cs('col-lg-3')}>
                                            <OrderTotalsSummary {...context}/>

                                            <PaymentButtons {...context}/>

                                            {!_.get(gatewayConfig, 'disable_transactions', false)
                                                && !(_.get(gatewayConfig, 'require_in_store_pickup_only', false) || false)
                                                && _.isNull(shipping)
                                                &&
                                                <EstimateShipping {...context}/>
                                            }

                                            <div className={cs('mt-5', 'col-12', 'text-center', (returnPolicy && shippingPolicy ? ['d-flex', 'justify-content-between'] : ''))}>
                                                {returnPolicy &&
                                                <React.Fragment>
                                                    <a onClick={() => this.setState({isReturnPolicyOpen: true})} role={'button'}>Return Policy</a>

                                                    <Modal
                                                        isOpen={this.state.isReturnPolicyOpen}
                                                        toggle={() => this.setState({isReturnPolicyOpen: !this.state.isReturnPolicyOpen})}
                                                        fade={false}
                                                        cssModule={MODAL}
                                                        wrapClassName={cs('pss')}
                                                        size={'lg'}
                                                    >
                                                        <ModalHeader
                                                            tag={'span'}
                                                            toggle={() => this.setState({isReturnPolicyOpen: !this.state.isReturnPolicyOpen})}
                                                        >
                                                            Return Policy
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <FroalaEditorView model={returnPolicy} />
                                                        </ModalBody>
                                                    </Modal>
                                                </React.Fragment>
                                                }

                                                {returnPolicy && shippingPolicy &&
                                                <React.Fragment>{' | '}</React.Fragment>
                                                }

                                                {shippingPolicy &&
                                                <React.Fragment>
                                                    <a onClick={() => this.setState({isShippingPolicyOpen: true})} role={'button'}>Shipping Policy</a>

                                                    <Modal
                                                        isOpen={this.state.isShippingPolicyOpen}
                                                        toggle={() => this.setState({isShippingPolicyOpen: !this.state.isShippingPolicyOpen})}
                                                        fade={false}
                                                        cssModule={MODAL}
                                                        wrapClassName={cs('pss')}
                                                        size={'lg'}
                                                    >
                                                        <ModalHeader
                                                            tag={'span'}
                                                            toggle={() => this.setState({isShippingPolicyOpen: !this.state.isShippingPolicyOpen})}
                                                        >
                                                            Shipping Policy
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <FroalaEditorView model={shippingPolicy} />
                                                        </ModalBody>
                                                    </Modal>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                );
                            }}
                        </CartContext.Consumer>

                        {this.props.showRecommended && this.state.relatedProducts.length > 0 &&
                        <div className={cs('row', 'mt-5', 'pss-cart-related-products')}>
                            <div className={cs('col')}>
                                <h2 className={cs('text-uppercase')}>Customers Also Bought</h2>
                                <div className={cs('row')}>
                                    {this.state.relatedProducts.map((v, k) => (
                                        <ProductCard
                                            key={k}
                                            webstoreProductId={v.WebstoreProduct.id}
                                            manufacturerGroupName={v.ProductGroup.ManufacturerGroup.name}
                                            productGroupName={v.ProductGroup.name}
                                            imageUrl={productsClient().heroImageUrl(v.WebstoreProduct.id)}
                                            minPrice={v.WebstoreProduct.min_price}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        }
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}