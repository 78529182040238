import React from 'react';
import PropTypes from 'prop-types';
import baseClient from 'src/client.webstore/api/base';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {Modal, ModalBody} from 'reactstrap';
import MODAL from 'src/client.webstore/static/bootstrap.modal.module.scss';

class SubImage extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }

    state = {
        isOpen: false
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs, styles}) => (
                    <div className={cs('col-4')}>
                        <button className={cs('btn', 'btn-link')} onClick={() => this.setState({isOpen: !this.state.isOpen})}>
                            <img src={this.props.src} alt={this.props.alt} className={cs('img-fluid')}/>
                        </button>

                        <Modal
                            isOpen={this.state.isOpen}
                            toggle={() => this.setState({isOpen: !this.state.isOpen})}
                            cssModule={MODAL}
                            fade={false}
                            wrapClassName={cs('pss')}
                            size={'lg'}
                        >
                            <ModalBody cssModule={styles}>
                                <div className={cs('container-fluid', 'p-3')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col', 'text-center')}>
                                            <img src={this.props.src} alt={this.props.alt} className={cs('img-fluid')}/>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}

export default class SubImages extends React.Component {
    static propTypes = {
        catalogId: PropTypes.number.isRequired,
        foreignKeyId: PropTypes.number.isRequired
    };

    state = {
        images: []
    };

    componentDidMount() {
        this.getImages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.foreignKeyId !== this.props.foreignKeyId) {
            this.getImages();
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    getImages = () => {
        return baseClient()
            .get(`/webstore/webstore_images/images/${this.props.catalogId}/${this.props.foreignKeyId}`)
            .then(res => this.setState({images: res.data}));
    };

    render() {
        if (this.state.images.length < 2) {
            return null;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row', 'my-3', 'justify-content-center')}>
                        {this.state.images.map((v, k) => {
                            return (<SubImage src={v} alt={`Mini Image ${k + 1}`} key={k}/>);
                        })}
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}
