import React from 'react';
import {Formik, Form} from 'formik';
import {cart} from 'src/client.webstore/utils/cart';
import _ from 'lodash';
import iso3166 from 'iso-3166-2';
import {Link, Redirect} from 'react-router-dom';
import CartInput from './Elements/CartInput';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

export default class Billing extends React.Component {
    static contextType = CartContext;

    state = {
        loading: true,
        cartZip: null,
        redirectTo: null
    };

    componentDidMount() {
        Promise.all([
            cart.getZip().then(cartZip => this.setState({cartZip})),
            cart.getBilling().then(billing => this.setState({billing}))
        ]).then(() => this.setState({loading: false}, () => Analytics.view({
            ecomm_part_list_ids: this.context.cartItems.map(v => `${v.foreignKeyId}`),
            ecomm_part_list_quantities: this.context.cartItems.map(v => `${v.quantity}`),
            ecomm_cart_event: 'collect_billing_info',
            ecomm_cart_subtotal: `${this.context.subtotal}`,
            ecomm_cart_shipping: `${this.context.shipping}`,
            ecomm_cart_tax: `${this.context.tax}`,
            ecomm_cart_grand_total: `${this.context.total}`,
            tealium_event: 'ecommerce_part_cart_action'
        })));
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push={true}/>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {context => {
                            // Must pickup in store if the setting is enabled for it or ANY item in the cart is configured to be picked up in store
                            const inStorePickupOnly = _.get(context, 'gatewayConfig.require_in_store_pickup_only', false) || false;

                            return (
                            <React.Fragment>
                                {context.gatewayConfig &&
                                <div className={cs('row', 'my-5')}>
                                    <div className={cs('col')}>
                                        <h2 className={cs('mb-3')}>Billing Details</h2>
                                        <Formik
                                            isInitialValid={this.state.billing !== null}
                                            initialValues={_.merge(
                                                this.state.billing || {
                                                    name: '',
                                                    email: '',
                                                    phone: '',
                                                    address_1: '',
                                                    address_2: '',
                                                    city: '',
                                                    state: (context.gatewayConfig.disable_out_of_state_orders ? context.gatewayConfig.origin.originState : ''),
                                                    country: context.gatewayConfig.origin.originCountry,
                                                    cc: false,
                                                    zip: this.state.cartZip || '',
                                                    webstore_location_id: null
                                                },
                                                // Always override the in-store-pickup value. This handles cases where
                                                // billing info may be set when no items require in-store-pickup, but later
                                                // the customer adds a product that is in-store-pickup only and we must now enforce it
                                                {
                                                    pickup: inStorePickupOnly,
                                                    ship_to_billing: inStorePickupOnly ? false : (_.get(this.state.billing, 'ship_to_billing', false) || false)}
                                            )}
                                            onSubmit={values => {
                                                cart
                                                    .setBilling(values)
                                                    .then(() => {
                                                        if (values.ship_to_billing) {
                                                            return Promise.all([
                                                                cart.setShipping(values),
                                                                cart.setZip(values.zip),
                                                                context.calculate()
                                                            ]).then(() => this.setState({redirectTo: '/cart/methods'}));
                                                        }

                                                        if (values.pickup) {
                                                            let {locations} = context.gatewayConfig.pickup;
                                                            let location = _.filter(locations, v => v.id === values.webstore_location_id)[0];

                                                            return Promise.all([
                                                                cart.setShipping({
                                                                    ...values,
                                                                    name: 'In-Store Pickup',
                                                                    address_1: location.name,
                                                                    address_2: location.address,
                                                                    city: location.city,
                                                                    state: location.state,
                                                                    zip: location.zip,
                                                                    pickup: true
                                                                }),
                                                                cart.setZip(location.zip),
                                                                context.calculate()
                                                            ]).then(() => this.setState({redirectTo: '/cart/payment'}));
                                                        }

                                                        return Promise.all([
                                                            cart.setZip(null),
                                                            context.calculate()
                                                        ]).then(() => this.setState({redirectTo: '/cart/shipping'}));
                                                    });
                                            }}
                                            validate={values => {
                                                let errors = {};

                                                if (!values.name) {
                                                    _.set(errors, 'name', 'Please enter your name.');
                                                }

                                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                                    _.set(errors, 'email', 'Please enter a valid email address.');
                                                }

                                                if (!values.phone) {
                                                    _.set(errors, 'phone', 'Please enter a valid phone number.');
                                                }

                                                if (!values.address_1) {
                                                    _.set(errors, 'address_1', 'Please enter a valid billing street address.');
                                                }

                                                if (!values.city) {
                                                    _.set(errors, 'city', 'Please enter a valid billing city.');
                                                }

                                                if (!values.country) {
                                                    _.set(errors, 'country', 'Please select a valid billing country.');
                                                } else if (_.isEmpty(_.filter(iso3166.data, (v, k) => k === values.country))) {
                                                    _.set(errors, 'country', 'Please select a valid billing country.');
                                                }

                                                if (!values.state) {
                                                    _.set(errors, 'state', 'Please enter a valid billing state.');
                                                } else if (context.gatewayConfig.disable_out_of_state_orders && values.state !== context.gatewayConfig.origin.originState) {
                                                    let stateName = _.chain(iso3166.country(values.country).sub)
                                                        .filter((v) => (v.regionCode === context.gatewayConfig.origin.originState))
                                                        .value()
                                                        .reduce((p, c) => (c.name), context.gatewayConfig.origin.originState);

                                                    _.set(errors, 'state', `Only orders from ${stateName} addresses may be accepted.`);
                                                }

                                                if (!values.zip) {
                                                    _.set(errors, 'zip', 'Please enter a valid billing zip.');
                                                }

                                                return errors;
                                            }}
                                        >
                                            {bag => {
                                                let {values, isValid, setFieldValue} = bag;
                                                let countries = _.get(context, 'gatewayConfig.countries', []) || [];

                                                return (
                                                    <Form>
                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput bag={bag} label={'Full Name'} name={'name'} type={'text'}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col-md')}>
                                                                <CartInput type={'email'} label={'E-Mail'} name={'email'} bag={bag}/>
                                                            </div>
                                                            <div className={cs('col-md')}>
                                                                <CartInput type={'text'} label={'Phone'} name={'phone'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Address'} name={'address_1'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Address'} name={'address_2'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput
                                                                    type={'select'}
                                                                    label={'Country'}
                                                                    name={'country'}
                                                                    bag={bag}
                                                                    disabled={context.gatewayConfig.disable_out_of_state_orders}
                                                                    options={context.gatewayConfig.disable_out_of_state_orders || _.isEmpty(countries)
                                                                        // Only the origin country may be used when configured to disable out-of-state orders
                                                                        ? [{
                                                                            name: iso3166.country(context.gatewayConfig.origin.originCountry).name,
                                                                            value: context.gatewayConfig.origin.originCountry
                                                                        }]
                                                                        // List all shippable countries (i.e. currently only allow billing from a country in the shipping list)
                                                                        : _.map(
                                                                            countries,
                                                                            v => ({name: iso3166.country(v).name, value: v})
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'City'} name={'city'} bag={bag}/>
                                                            </div>
                                                            <div className={cs('col')}>
                                                                <CartInput
                                                                    type={'select'}
                                                                    label={values.country === 'US' ? 'State' : 'State/Region/Province'}
                                                                    name={'state'}
                                                                    bag={bag}
                                                                    disabled={context.gatewayConfig.disable_out_of_state_orders}
                                                                    options={
                                                                        _.concat(
                                                                            [{name: '- Please Select -', value: ''}],
                                                                            _.chain(iso3166.country(values.country).sub)
                                                                                .map((v, k) => ({name: v.name, value: iso3166.subdivision(k).regionCode}))
                                                                                .filter(v => {
                                                                                    // Only include the store's state
                                                                                    if (context.gatewayConfig.disable_out_of_state_orders) {
                                                                                        return v.value === context.gatewayConfig.origin.originState;
                                                                                    }

                                                                                    return true;
                                                                                })
                                                                                .sortBy(o => o.name)
                                                                                .value()
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className={cs('col')}>
                                                                <CartInput type={'text'} label={'Zip'} name={'zip'} bag={bag}/>
                                                            </div>
                                                        </div>

                                                        <div className={cs('form-group', 'row')}>
                                                            {!inStorePickupOnly &&
                                                            <div className={cs('col-md')}>
                                                                <CartInput
                                                                    type={'checkbox'}
                                                                    label={'Ship To Billing'}
                                                                    name={'ship_to_billing'}
                                                                    bag={bag} checked={values.ship_to_billing}
                                                                    onChange={(v) => {
                                                                        // This value is tied to the pickup value; both may not be checked together
                                                                        if (v.target.checked) {
                                                                            bag.setFieldValue('pickup', false);
                                                                        }

                                                                        bag.handleChange(v);
                                                                    }}
                                                                />
                                                            </div>
                                                            }

                                                            {context.gatewayConfig.pickup.inStorePickup > 0 && context.gatewayConfig.pickup.locations.length > 0 &&
                                                            <div className={cs('col-md')}>
                                                                <CartInput
                                                                    type={'checkbox'}
                                                                    label={'Pickup In Store'}
                                                                    name={'pickup'}
                                                                    bag={bag}
                                                                    checked={values.pickup}
                                                                    disabled={inStorePickupOnly}
                                                                    onChange={(v) => {
                                                                        // This value is tied to the ship_to_billing value; both may not be checked together
                                                                        if (v.target.checked) {
                                                                            bag.setFieldValue('ship_to_billing', false);
                                                                        }

                                                                        bag.handleChange(v);
                                                                    }}
                                                                />
                                                            </div>
                                                            }
                                                        </div>

                                                        {values.pickup && context.gatewayConfig.pickup.inStorePickup > 0 &&
                                                        <div className={cs('row', 'my-5')}>
                                                            <div className={cs('col-12', 'mb-3')}>
                                                                <h2>Select A Pickup Location</h2>

                                                                <div className={cs('alert', 'alert-info')} role={'alert'}>
                                                                    After completing your order, you will receive an email when your items are ready to be picked up.
                                                                </div>
                                                            </div>

                                                            {context.gatewayConfig.pickup.locations.map(v => (
                                                                <div key={v.id} className={cs('col-md-6', 'mb-3')}>
                                                                    <div className={cs('border', 'p-3', {'border-primary': values.webstore_location_id === v.id})}>
                                                                        <h3>{v.name}</h3>
                                                                        <address>
                                                                            {v.address}<br/>
                                                                            {`${v.city}, ${v.state} ${v.zip}`}
                                                                        </address>

                                                                        <button
                                                                            type={'button'}
                                                                            className={cs('btn', 'w-100', {'btn-primary': values.webstore_location_id === v.id}, {'btn-secondary': values.webstore_location_id !== v.id})}
                                                                            onClick={() => setFieldValue('webstore_location_id', v.id)}
                                                                        >
                                                                            Select {v.name}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        }

                                                        <div className={cs('row', 'mt-5')}>

                                                            <div className={cs('col-md', 'mb-3', 'mb-md-0')}>
                                                                <Link
                                                                    to={'/cart'}
                                                                    className={cs('btn', 'btn-outline-secondary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    id={'pss-back-to-cart'}
                                                                >
                                                                    Back To Cart
                                                                </Link>
                                                            </div>

                                                            <div className={cs('col-md', 'text-right')}>
                                                                <button
                                                                    type={'submit'}
                                                                    className={cs('btn', 'btn-primary', 'btn-lg', 'w-100', 'text-uppercase')}
                                                                    disabled={!isValid || (values.pickup && values.webstore_location_id === null)}
                                                                    id={'pss-continue-from-billing'}
                                                                >
                                                                    {values.ship_to_billing && 'Continue To Shipping Methods'}
                                                                    {values.pickup && 'Continue To Payment'}
                                                                    {!values.ship_to_billing && !values.pickup && 'Continue To Shipping'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                                }
                            </React.Fragment>
                            )}}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}