import React from 'react';
import script from 'scriptjs';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {cart} from 'src/client.webstore/utils/cart';
import {gateway} from 'src/client.webstore/utils/gateway';
import {Redirect} from 'react-router-dom';
import analytics from 'src/client.webstore/utils/analytics';

const Analytics = analytics();

/**
 * This terrible bit of code is brought to you by the atrocious Authorize.NET Accept.js hosted
 * payment page.
 *
 * @link: https://developer.authorize.net/api/reference/features/acceptjs.html#Integrating_the_Hosted_Payment_Information_Form
 */
export default class Authorize extends React.Component {
    state = {
        loading: false,
        ready: false,
        error: false
    }

    constructor(props) {
        super(props);

        /**
         * Authorize.net AcceptUI.js does not play well with single page apps,
         * the script included in `Authorize::componentDidMount()` itself manipulates
         * the dom externally.
         *
         * Navigating away from the checkout page breaks the listener for the click event
         * bound to `button.AcceptUI`.
         *
         * We force a reload here to get around this as there is no exposed API available
         * to manipulate the SDK ourselves.
         */
        if (document.getElementById('AcceptUIContainer')) {
            window.location.reload();
        }
    }

    componentDidMount() {
        window.responseHandler = this.responseHandler;
        script(
            this.props.gatewayConfig.gateway.gateway_test_mode
                ? 'https://jstest.authorize.net/v3/AcceptUI.js'
                : 'https://js.authorize.net/v3/AcceptUI.js',
            () => this.setState({ready: true})
        );
    }

    responseHandler = response => {
        let {opaqueData} = response;

        this.setState({loading: true}, () => cart
            .setPaymentToken(response.opaqueData.dataValue)
            .then(() => gateway.purchase('Authorize', {opaqueDataDescriptor: opaqueData.dataDescriptor, opaqueDataValue: opaqueData.dataValue}))
            .then(res => cart.clear().then(() => this.setState({redirect: `/cart/summary/${res.data.orderId}`}, () => Analytics.convert(res.data.orderId))))
            .catch(err => this.setState({error: err.message, loading: false})));
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        let {authorize} = this.props.gatewayConfig;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div className={cs('row', 'my-5')}>
                        <div className={cs('col', 'border', 'p-5', 'shadow-sm')}>
                            <h3>Credit/Debit Card</h3>
                            <p className={cs('lead')}>Securely checkout using your credit/debit card online.</p>

                            {this.state.error &&
                            <div className={cs('alert', 'alert-warning', 'my-3')} role={'alert'}>
                                <p className={cs('lead')}>
                                    {this.state.error}
                                </p>
                            </div>
                            }

                            <div className={cs('text-right')}>
                                <form
                                    id='paymentForm'
                                    method='POST'
                                >
                                    <input type='hidden' name='dataValue' id='dataValue'/>
                                    <input type='hidden' name='dataDescriptor' id='dataDescriptor'/>
                                    <button
                                        type='button'
                                        className={cs('AcceptUI', 'btn', 'btn-primary', 'btn-lg')}
                                        data-billingaddressoptions={JSON.stringify({show: false, required: false})}
                                        data-apiloginid={authorize.authorize_login_id}
                                        data-clientkey={authorize.authorize_public_client_key}
                                        data-acceptuiformbtntxt='Pay Now'
                                        data-acceptuiformheadertxt='Card Information'
                                        data-responsehandler='responseHandler'
                                        disabled={this.state.loading || !this.state.ready}
                                    >
                                        {!this.state.loading && 'Secure Checkout'}
                                        {this.state.loading && 'Please Wait'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}