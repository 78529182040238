import React from 'react';
import PropTypes from 'prop-types';

export const ConfigContext = React.createContext();

/**
 * Provide configuration values from the webstore
 */
export class ConfigProvider extends React.Component {
    static propTypes = {
        cart: PropTypes.object.isRequired
    };

    render() {
        // Make the webstore cart config available in the context
        return (
            <ConfigContext.Provider
                value={{
                    cart: this.props.cart
                }}
            >
                {this.props.children}
            </ConfigContext.Provider>
        );
    }
}
