import React from 'react';
import currency from 'currency.js';

const CartContext = React.createContext({
    cartItems: [],
    currency: value => {
        return currency(value);
    },
    calculate: () => {
    }
});

export const CartProvider = CartContext.Provider;
export const CartConsumer = CartContext.Consumer;

export default CartContext;