import React from 'react';
import {hot} from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {cart} from 'src/client.webstore/utils/cart';
import _ from 'lodash';
import {Formik, Form} from 'formik';

/**
 * Creates a form to submit to estimate shipping costs in order totals on the webstore
 */
class EstimateShipping extends React.Component {
    static propTypes = {
        calculate: PropTypes.func.isRequired
    }

    state = {
        loading: true,
        estimatedShipping: {},
        error: ''
    }

    componentDidMount() {
        cart.getEstimatedShipping().then(v => this.setState({loading: false, estimatedShipping: v}));
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    render() {
        if (this.state.loading) {
            return <></>;
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <Formik
                        onSubmit={(values, {setSubmitting}) => {
                            cart.setEstimatedShipping(values)
                                .then(() => cart.estimateShipping())
                                // After fetching estimated shipping, use props.calculate to refresh the order totals with the estimated shipping
                                .then(cost => this.props.calculate())
                                .then(() => this.setState({error: ''}, () => setSubmitting(false)))
                                .catch(e => {
                                    this.setState(
                                        {error: 'An estimate could not be retrieved.'},
                                        () => setSubmitting(false)
                                    );
                                });
                        }}
                        initialValues={{
                            zip: _.get(this.state.estimatedShipping, 'zip', '')
                        }}
                    >
                        {({values, handleChange, isSubmitting}) => (
                            <Form>
                                <div className={cs('mt-5')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col-12')}>
                                            <label htmlFor={'pss-estimate-shipping-zip'}>Estimate Shipping to Zip Code</label>
                                        </div>
                                    </div>
                                    <div className={cs('d-flex', 'align-self-center', 'row')}>
                                        <div className={cs('col-6')}>
                                            <input
                                                id={'pss-estimate-shipping-zip'}
                                                placeholder={'Zip Code'}
                                                type={'text'}
                                                name={'zip'}
                                                value={values.zip || ''}
                                                onChange={handleChange}
                                                className={cs('form-control')}
                                                required={true}
                                            />
                                        </div>

                                        <div className={cs('col-6')}>
                                            <button
                                                type={'submit'}
                                                title={'Estimate Shipping'}
                                                className={cs('btn', 'btn-secondary', 'w-100', 'text-center')}
                                                disabled={isSubmitting}
                                            >
                                                Estimate
                                            </button>
                                        </div>
                                    </div>

                                    <div className={cs('row', 'mt-2')}>
                                        <div className={cs('col-12', 'text-danger')}>
                                            {this.state.error.message}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default hot(EstimateShipping);
