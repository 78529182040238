import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.div`
  position: absolute;
  ${(props) => props.side}: 1px;
  top: -5px;
`;

/**
 * Display a top-most absolutely-positioned label
 */
export default class FixedLabel extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        side: PropTypes.oneOf(['left', 'right'])
    };

    static defaultProps = {
        side: 'right',
    };

    render() {
        return (
            <Label {...this.props}>
                <small>{this.props.label}</small>
            </Label>
        );
    }
}
