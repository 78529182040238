import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import qs from 'query-string';
import productsClient from 'src/client.webstore/api/product';
import ShareBox from 'src/client.webstore/Views/Elements/ShareBox';
import FixedLabel from 'src/client.webstore/Views/Elements/FixedLabel';
import ProductCard from 'src/client.webstore/Views/Elements/ProductCard';
import baseClient from 'src/client.webstore/api/base';
import ProductFilter from './Elements/ProductFilter';
import CartActions from './Elements/CartActions';
import PricingAvailability from './Elements/PricingAvailability';
import SubImages from './Elements/SubImages';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import Helmet from 'react-helmet';
import EbayLogo from 'public/marketplace_logos/ebay_now_182x76.gif';
import analytics from 'src/client.webstore/utils/analytics';
import {Link} from 'react-router-dom';
import {Modal, ModalBody} from 'reactstrap';
import MODAL from 'src/client.webstore/static/bootstrap.modal.module.scss';
import handleViewport from 'react-in-viewport';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import {ToggleClassFlagProvider} from 'src/toggles/ToggleClassFlagProvider';

const Analytics = analytics();

class ProductImages extends React.Component {
    static propTypes = {
        catalogId: PropTypes.number.isRequired,
        webstoreProductId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
    }

    state = {
        isOpen: false
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {({cs, styles}) => (
                    <React.Fragment>
                        <div className={cs('row')}>
                            <div className={cs('col', 'text-center')}>
                                <button
                                    className={cs('btn', 'btn-link')}
                                    title={'Image Zoom Button'}
                                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                                >
                                    <img
                                        src={productsClient().imageUrl(this.props.catalogId, this.props.webstoreProductId, 400, 400)}
                                        className={cs('img-fluid')}
                                        alt={this.props.title}
                                    />
                                </button>

                                <Modal
                                    isOpen={this.state.isOpen}
                                    toggle={() => this.setState({isOpen: !this.state.isOpen})}
                                    cssModule={MODAL}
                                    fade={false}
                                    wrapClassName={cs('pss')}
                                    size={'lg'}
                                >
                                    <ModalBody cssModule={styles}>
                                        <div className={cs('container-fluid', 'p-3')}>
                                            <div className={cs('row')}>
                                                <div className={cs('col', 'text-center')}>
                                                    <img
                                                        src={productsClient().imageUrl(this.props.catalogId, this.props.webstoreProductId)}
                                                        className={cs('img-fluid')}
                                                        alt={this.props.title}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                        <SubImages catalogId={Number(this.props.catalogId)} foreignKeyId={Number(this.props.webstoreProductId)}/>
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}

class ProductRow extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        manufacturerPartNumber: PropTypes.string.isRequired,
        cs: PropTypes.func.isRequired,
        webstoreProduct: PropTypes.object.isRequired,
        productGroup: PropTypes.object.isRequired
    }

    state = {
        beenVisible: false,
        attributes: []
    }

    componentDidMount() {
        this.setState({attributes: this.getAttributes()});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.inViewport && !this.state.beenVisible) {
            this.setState({beenVisible: true});
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    getAttributes() {
        // Ignore Activity attributes, there can be multiple and they aren't useful for product display
        const ignoreAttributes = [
            'Activity',
            'Year',
            'Make',
            'Model'
        ];

        return _.filter(_.get(this.props.product, 'Attribute', []) || [], v => {
            return _.indexOf(ignoreAttributes, v.name) < 0;
        });
    }

    render() {
        const {cs, webstoreProduct} = this.props;
        const catalogId = Number(_.get(webstoreProduct, 'catalog_id', 0));
        const {attributes} = this.state;

        return (
            <div className={cs('row', 'my-3', 'border', 'bg-white', 'p-3')}>
                {!this.state.beenVisible &&
                <div className={cs('col')}>
                    <Spinner/>
                </div>
                }

                {(this.props.inViewport || this.state.beenVisible) &&
                <React.Fragment>
                    <div className={cs('col-md-3', 'align-self-center', 'text-center')}>
                        <img
                            src={productsClient().imageUrl(catalogId, this.props.product.id, 120, 120)} alt={`${this.props.manufacturerPartNumber} Variation`}
                            className={cs('img-fluid')}
                        />
                    </div>
                    <div className={cs('col-md-5', 'align-self-center')}>
                        <PricingAvailability
                            webstoreProduct={this.props.webstoreProduct}
                            foreignKeyId={Number(this.props.product.id)}
                            manufacturerPartNumber={this.props.product.manufacturer_part_number}
                            attributes={attributes}
                        />
                    </div>
                    <div className={cs('col-md-4', 'align-self-center')}>
                        <CartActions
                            filteredProducts={[this.props.product]}
                            foreignKeyId={Number(this.props.product.id)}
                            webstoreProduct={this.props.webstoreProduct}
                            productGroup={this.props.productGroup}
                        />
                    </div>
                </React.Fragment>
                }
            </div>
        )
    }
}

ProductRow = handleViewport(ProductRow);

export default class ViewProduct extends React.Component {
    state = {
        gallery: [],
        filterValues: {},
        loading: true,
        related: [],
        years: [],
        makes: [],
        models: [],
        error: false
    };

    static defaultState = {
        gallery: [],
        filterValues: {},
        loading: true,
        related: [],
        years: [],
        makes: [],
        models: [],
        error: false
    };

    componentDidMount() {
        this.loadProduct()
            .then(() => {
                let query = qs.parse(this.props.location.search);
                const q = _.get(query, 'q', '') || '';

                if (!_.isEmpty(query) && q !== '') {
                    query = JSON.parse(q);
                }

                // Pre-select a color/size/option if only one was selected
                const colors = _.get(query, 'color', []) || [];
                const sizes = _.get(query, 'size', []) || [];
                const options = _.get(query, 'option', []) || [];

                this.setValue('Color', colors.length === 1 ? _.head(colors) : '');
                this.setValue('Size', sizes.length === 1 ? _.head(sizes) : '');
                this.setValue('Option', options.length === 1 ? _.head(options) : '');
            })
            .then(() => this.setState({loading: false}))
            .catch(err => this.setState({error: true, loading: false}));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(ViewProduct.defaultState, this.loadProduct);
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    /**
     * @return {Promise<void>}
     */
    loadProduct = () => productsClient()
        .getProduct(this.props.match.params.id)
        .then(res => {
            this.setState({...res.data});
            return res;
        })
        .then(res => productsClient()
            .getYears(res.data.WebstoreProduct.id)
            .then(res => this.setState({years: res.data}))
            .then(() => res))
        .then(res => {
            productsClient()
                .get('/categories', {
                    length: 12,
                    manufacturer: res.data.ProductGroup.ManufacturerGroup.name,
                    ...qs.parse(this.props.location.search)
                })
                .then(res => this.setState({related: res.data.products}));

            return res;
        })
        .then(this.fireAnalytics);

    /**
     * @return {void}
     */
    fireAnalytics = webstoreProduct => {
        try {
            let {ProductGroup, WebstoreProduct} = webstoreProduct.data;

            Analytics.view({
                ecomm_part_detail_id: `${WebstoreProduct.id}`,
                ecomm_part_detail_name: ProductGroup.name,
                // ecomm_part_detail_original_price: '19.99',
                // ecomm_part_detail_sale_price: '14.99',
                // ecomm_part_detail_currency_code: 'USD',
                ecomm_part_detail_breadcrumbs: this.state.WebstoreCategory.map(v => v.name),
                ecomm_part_detail_brand: ProductGroup.ManufacturerGroup.name,
                // ecomm_part_detail_quantity: '12',
                // ecomm_part_detail_variant: JSON.stringify(this.state.filterValues),
                ecomm_part_detail_category: _.get(this.state, `WebstoreCategory[${this.state.WebstoreCategory.length - 1}].name`, ''),
                // ecomm_part_detail_model: filteredProducts[0].manufacturer_part_number,
                ecomm_part_detail_group_id: ProductGroup.id,
                // ecomm_part_detail_image_count: galleryColors.length,
                ecomm_part_detail_dscrptn_char_count: ProductGroup.description ? ProductGroup.description.length : 0,
                site_section: 'Ecommerce Part Detail Page',
                tealium_event: 'ecommerce_part_detail_view'
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * @param {string} key
     * @return {string}
     */
    getValue = key => this.state.filterValues[key] || '';

    /**
     * @param {string} key
     * @param {string} value
     * @param {Array} clears
     */
    setValue = (key, value, clears = []) => {
        let state = {...this.state.filterValues};

        _.forEach(clears, v => {
            delete state[v];
        });

        if (value !== '') {
            state[key] = value;
        } else {
            delete state[key];
        }

        this.setState({filterValues: state});
    };

    /**
     * Reduce `this.state.AttributeMap` to include only options that have > 1
     * values to show as filterable dropdown menus. Special exceptions can be made
     * here to hide certain attributes from being filtered.
     *
     * NOTE: _.chain() is not used here for readability.
     *
     * @return {Array<object>}
     */
    getAvailableFilters = () => {
        let filters = _.map(this.state.AttributeMap, (v, k) => ({name: k, values: v}));

        /**
         * Reduce the filters by eligibility
         */
        filters = _.filter(filters, attributeType => {
            /**
             * Filter exceptions go here:
             */
            let ignoredAttributes = [
                'Primary Color',
                'Distinct Name',
                'Market Color',
                'Product Type',
                'Item Name',
                'Activity'
            ];

            if (_.indexOf(ignoredAttributes, attributeType.name) >= 0) {
                return false;
            }

            /**
             * Filter whitelist go here:
             */
            let whitelist = [
                'Size',
                'Color',
                'Option'
            ];

            return attributeType.values.length > 1 || _.indexOf(whitelist, attributeType.name) >= 0;
        });

        return filters;
    }

    /**
     * Reduces the available products in `this.state.ProductGroup.Product` based on
     * `this.state.filterValues` with the hopes that we will reduce the selection down to 1
     * available variation given the attributes.
     *
     * @return {Array}
     */
    filterProducts = () => {
        if (_.isEmpty(this.state.filterValues)) {
            return this.state.ProductGroup.Product;
        }

        let filterValues = _.map(this.state.filterValues, (value, name) => ({name, value}));

        return _.filter(this.state.ProductGroup.Product, product => {
            let valid = false;
            _.forEach(filterValues, filterValue => {
                if (_.findIndex(product.Attribute, filterValue) < 0) {
                    valid = false;
                    return false;
                }

                valid = true;
            });

            return valid;
        });
    };

    /**
     * Given a reduced set of `this.filterProducts` is the given `value` available for `name
     *
     * @param {string} name
     * @param {string} value
     * @return {boolean}
     */
    isValueAvailable = (name, value) => {
        let availableAttributes = [];
        _.forEach(this.filterProducts(), product => {
            _.forEach(product.Attribute, attribute => availableAttributes.push(attribute))
        });

        return _.findIndex(availableAttributes, {name, value}) >= 0;
    }

    isFullyFiltered = () => {
        let filtered = true;

        if (this.state.years.length > 0 && (this.getValue('Year') === '' || this.getValue('Make') === '' || this.getValue('Model') === '')) {
            return false;
        }

        _.forEach(this.getAvailableFilters(), v => {
            if (v.values.length > 1 && this.getValue(v.name) === '') {
                filtered = false;
            }
        });

        return filtered;
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        if (this.state.error) {
            return (
                <ThemeContext.Consumer>
                    {({cs}) => (
                        <React.Fragment>
                            <Helmet>
                                <title>Uh oh!</title>
                            </Helmet>
                            <div className={cs('row', 'my-5', 'justify-content-center')}>
                                <div className={cs('col-6')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col-12', 'text-center')}>
                                            <h2>Not Found</h2>
                                        </div>
                                        <div className={cs('col-12', 'my-3')}>
                                            <p className={cs('lead')}>
                                                We couldn't find the product you were looking for. It may have been removed or is no longer available for sale.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ThemeContext.Consumer>
            )
        }

        const {WebstoreProduct, ProductGroup} = this.state;
        const {Product: availableProducts} = ProductGroup;
        const availableFilters = this.getAvailableFilters();

        const galleryColors = _.chain(availableProducts)
            .filter(({color}) => color)
            .map(({id, color}) => ({id: id, color: color}))
            .uniqBy(p => p.color)
            .value();

        const filteredProducts = this.filterProducts();

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <React.Fragment>
                        <Helmet>
                            <title>{ProductGroup.ManufacturerGroup.name} {ProductGroup.name}</title>
                        </Helmet>

                        <ToggleClassFlagProvider
                            flagName={'test'}
                            render={({isEnabled, getClient}) => {
                                // Render nothing, this is just a test to console that the toggle works
                                // Note that this does not play well with component children that use react-helmet
                                console.log('Test toggle ' + (isEnabled() ? 'enabled' : 'disabled'));
                                return <></>
                            }}
                        />

                        <div className={cs('row')}>
                            <div className={cs('col-12')}>
                                <nav aria-label={'breadcrumb'}>
                                    <ol className={cs('breadcrumb')}>
                                        <li className={cs('breadcrumb-item')}>
                                            <a href='/'>Home</a>
                                        </li>
                                        <li className={cs('breadcrumb-item')}>
                                            <Link to='/categories'>All Parts & Accessories</Link>
                                        </li>
                                        {this.state.WebstoreCategory && this.state.WebstoreCategory.map((v, k) => {
                                            if (!v.webstore_url) {
                                                return (
                                                    <li key={k} className={cs('breadcrumb-item', 'active')}>
                                                        {v.name}
                                                    </li>
                                                )
                                            }

                                            return (
                                                <li key={k} className={cs('breadcrumb-item')}>
                                                    {v.webstore_url &&
                                                    <Link to={`/categories${v.webstore_url}`}>
                                                        {v.name}
                                                    </Link>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-md-6', 'col-lg-4', 'mb-3')}>
                                {WebstoreProduct.on_sale &&
                                <FixedLabel label={'ON SALE'} side={'left'} className={cs('bg-danger', 'text-white', 'p-2', 'shadow')} />
                                }

                                <div className={cs('p-3', 'border', 'shadow-sm', 'bg-white')}>

                                    {filteredProducts.length > 0 &&
                                    <ProductImages
                                        catalogId={Number(WebstoreProduct.catalog_id)}
                                        webstoreProductId={Number(filteredProducts[0].id)}
                                        title={`${ProductGroup.ManufacturerGroup.name} ${ProductGroup.name}`}
                                    />
                                    }

                                    {galleryColors.length > 1 &&
                                    <div className={cs('row', 'mt-5', 'justify-content-center')}>
                                        {galleryColors.map(p => (
                                            <div key={p.color} className={cs('col-3')}>
                                                <a onClick={() => this.setValue('Color', p.color, _.map(availableFilters, v => v.name))} role={'button'}>
                                                    <img
                                                        src={productsClient().imageUrl(WebstoreProduct.catalog_id, p.id, 120, 120)} alt={`Color variation ${p.color}`}
                                                        className={cs('img-fluid')}
                                                    />
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className={cs('col-md-6', 'col-lg-8')}>

                                <div className={cs('row', 'mb-lg-5')}>
                                    <div className={cs('col')}>
                                        <h1 className={cs('my-0')}>{ProductGroup.name}</h1>
                                        <h2 className={cs('text-muted')}>{ProductGroup.ManufacturerGroup.name}</h2>
                                    </div>
                                </div>

                                <div className={cs('row')}>
                                    {WebstoreProduct.ebay_item_id === null &&
                                    <div className={cs('col-lg-6', 'order-1', 'order-lg-0')}>
                                        {this.state.years.length > 0 &&
                                        <React.Fragment>
                                            <ProductFilter
                                                label={'Year'}
                                                name={'Year'}
                                                options={this.state.years}
                                                value={this.getValue('Year')}
                                                setFieldValue={(k, v) => {
                                                    this.setValue('Year', v, ['Make', 'Model']);

                                                    if (v !== '') {
                                                        productsClient().getMakes(this.state.WebstoreProduct.id, v)
                                                            .then(res => this.setState({makes: res.data}));
                                                    }
                                                }}
                                            />

                                            <ProductFilter
                                                label={'Make'}
                                                name={'Make'}
                                                options={this.state.makes}
                                                value={this.getValue('Make')}
                                                disabled={this.getValue('Year') === '' || this.state.makes.length === 0}
                                                setFieldValue={(k, v) => {
                                                    this.setValue('Make', v, ['Model']);

                                                    if (v !== '') {
                                                        productsClient().getModels(this.state.WebstoreProduct.id, this.getValue('Year'), v)
                                                            .then(res => this.setState({models: res.data}))
                                                    }
                                                }}
                                            />

                                            <ProductFilter
                                                label={'Model'}
                                                name={'Model'}
                                                options={this.state.models}
                                                value={this.getValue('Model')}
                                                disabled={this.getValue('Make') === '' || this.state.models.length === 0}
                                                setFieldValue={(k, v) => {
                                                    this.setValue('Model', v);
                                                }}
                                            />
                                        </React.Fragment>
                                        }

                                        {_.map(availableFilters, v => {
                                            let position = _.findIndex(availableFilters, v);

                                            let clears = _.slice(availableFilters, position + 1);
                                            clears = _.map(clears, v => v.name);

                                            let availableValues = _.filter(v.values, value => this.isValueAvailable(v.name, value));

                                            return (
                                                <ProductFilter
                                                    key={v.name}
                                                    name={v.name}
                                                    label={v.name}
                                                    options={availableValues}
                                                    setFieldValue={(k, v) => this.setValue(k, v, clears)}
                                                    value={this.getValue(v.name)}
                                                    disabled={this.state.years.length > 0 && availableValues.length > 0 && (this.getValue('Year') === '' || this.getValue('Make') === '' || this.getValue('Model') === '')}
                                                />
                                            )
                                        })}

                                        {(!this.isFullyFiltered() || (this.isFullyFiltered() && filteredProducts.length === 1)) &&
                                        <CartActions
                                            foreignKeyId={filteredProducts.length > 0 ? Number(filteredProducts[0].id) : null}
                                            filteredProducts={filteredProducts}
                                            webstoreProduct={WebstoreProduct}
                                            productGroup={ProductGroup}
                                        />
                                        }

                                        <div className={cs('my-5')}>
                                            <ShareBox/>
                                        </div>
                                    </div>
                                    }

                                    {WebstoreProduct.ebay_item_id !== null &&
                                    <div className={cs('col-lg-6', 'order-1', 'order-lg-0', 'text-center')}>
                                        <a href={`https://ebay.com/itm/${WebstoreProduct.ebay_item_id}`}>
                                            <img src={EbayLogo} alt={'Buy It On Ebay'} className={cs('img-fluid')}/>
                                        </a>
                                    </div>
                                    }

                                    {filteredProducts.length === 1 &&
                                    <div className={cs('col-lg-6', 'order-0', 'order-lg-1')}>
                                        <PricingAvailability
                                            webstoreProduct={WebstoreProduct}
                                            foreignKeyId={Number(filteredProducts[0].id)}
                                            manufacturerPartNumber={filteredProducts[0].manufacturer_part_number}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {this.isFullyFiltered() && filteredProducts.length > 1 &&
                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col')}>
                                <h3>Part Numbers</h3>
                                {filteredProducts.map((v, k) => (
                                    <ProductRow
                                        key={k}
                                        product={v}
                                        manufacturerPartNumber={v.manufacturer_part_number}
                                        cs={cs}
                                        webstoreProduct={WebstoreProduct}
                                        productGroup={ProductGroup}
                                    />
                                ))}
                            </div>
                        </div>
                        }

                        {WebstoreProduct.ebay_item_id === null && ProductGroup.description &&
                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-12')}>
                                <h2>{ProductGroup.name} Details</h2>
                            </div>
                            <div className={cs('col-12', 'px-4')}>
                                <div className={cs('border', 'bg-white', 'p-3')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col')}>
                                            {ProductGroup.description && <div dangerouslySetInnerHTML={{__html: ProductGroup.description}}/>}
                                        </div>
                                    </div>

                                    {typeof this.state.AttributeMap === 'object' &&
                                    <div className={cs('row', 'my-5')}>
                                        <div className={cs('col')}>
                                            {_.map(this.state.AttributeMap, (v, k) => {
                                                if (v.length === 1) {
                                                    return (
                                                        <div className={cs('row')} key={k}>
                                                            <div className={cs('col')}>
                                                                <span className={cs('font-weight-bolder')}>{k}:</span>
                                                                {' '}
                                                                {v[0]}
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {this.state.related.length > 0 &&
                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-12')}>
                                <h2>Related Products</h2>
                            </div>
                            {this.state.related.map((v, k) => (
                                <ProductCard
                                    key={v.WebstoreProduct.id}
                                    webstoreProductId={v.WebstoreProduct.id}
                                    manufacturerGroupName={v.ProductGroup.ManufacturerGroup.name}
                                    productGroupName={v.ProductGroup.name}
                                    imageUrl={() => {
                                        if (v.WebstoreProduct.image_url) {
                                            return v.WebstoreProduct.image_url;
                                        }

                                        return baseClient().resolveUrl(`/webstore/webstore_images/resize_hero/${v.WebstoreProduct.id}/500/500`);
                                    }}
                                    minPrice={v.WebstoreProduct.min_price}
                                    onSale={v.WebstoreProduct.on_sale}
                                />
                            ))}
                        </div>
                        }
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
